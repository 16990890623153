// We need explicit types for all codes ademe, it's a duplicate to the prisma enum but it works this way
import { ECodeAdemeType, EProjectPhase } from './generated/prisma-zod';

export const ALL_OPERATIONS_CODES = [
  'BAR_TH_171',
  'BAR_TH_172',
  'BAR_TH_129',
  'BAR_TH_112',
  'BAR_TH_113',
  'BAR_TH_148',
  'BAR_TH_143',
  'BAR_EN_101',
  'BAR_EN_102',
  'BAR_EN_103',
  'BAR_TH_159',
] as const satisfies ECodeAdemeType[];

export const MPR_CHAUFFAGE_OPERATIONS = [
  'BAR_TH_112',
  'BAR_TH_113',
  'BAR_TH_143',
  'BAR_TH_148',
  'BAR_TH_159',
  'BAR_TH_171',
  'BAR_TH_172',
] as const satisfies ECodeAdemeType[];

export const MPR_BAREN_OPERATIONS = ['BAR_EN_101', 'BAR_EN_102'] as const;

export const MPR_ALL_OPERATIONS = [
  ...MPR_BAREN_OPERATIONS,
  ...MPR_CHAUFFAGE_OPERATIONS,
] as const satisfies ECodeAdemeType[];

export const OPS_WITH_NOTE_DIM = [
  'BAR_TH_113',
  'BAR_TH_159',
  'BAR_TH_171',
  'BAR_TH_172',
] as const satisfies ECodeAdemeType[];

export const OPS_WITH_FICHE_PRODUIT = [
  'BAR_TH_112',
  'BAR_TH_113',
  'BAR_TH_129',
  'BAR_TH_143',
  'BAR_TH_148',
  'BAR_TH_159',
  'BAR_TH_171',
  'BAR_TH_172',
] as const satisfies ECodeAdemeType[];

export const OPS_WITH_CERTIF_ACERMI = [
  'BAR_EN_101',
  'BAR_EN_102',
  'BAR_EN_103',
] as const satisfies ECodeAdemeType[];

export const OPS_WITH_ATTEST_RGE = [
  'BAR_EN_101',
  'BAR_EN_102',
  'BAR_EN_103',
  'BAR_TH_112',
  'BAR_TH_113',
  'BAR_TH_129',
  'BAR_TH_143',
  'BAR_TH_148',
  'BAR_TH_159',
  'BAR_TH_171',
  'BAR_TH_172',
] as const satisfies ECodeAdemeType[];

export const ETAS_SIMU_OPS = [
  'BAR_TH_112',
  'BAR_TH_113',
  'BAR_TH_159',
  'BAR_TH_171',
  'BAR_TH_172',
  'BAR_TH_148',
] as const satisfies ECodeAdemeType[];

export const PROJECT_PHASES_ORDERED = [
  EProjectPhase.COMMERCIAL_INIT,
  EProjectPhase.INIT_PROJECT_PRE_WORK_DOSSIER,
  EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
  EProjectPhase.SIGN_BEFORE_WORK_START,
  EProjectPhase.PREWORK_SUBMISSION_AND_PROJECT_IN_PROGRESS_ALL_IN_ONE,
  EProjectPhase.END_OF_PROJECT_DOSSIER_CREATION,
  EProjectPhase.END_OF_PROJECT_DOSSIER_REVIEW,
  EProjectPhase.SIGN_AFTER_WORK_END,
  EProjectPhase.END_OF_PROJECT_ALL_IN_ONE,
  EProjectPhase.PROJECT_COMPLETELY_DONE,
] as const satisfies [EProjectPhase, ...EProjectPhase[]];
// @ts-expect-error bad type inference
export const PROJECT_PHASE_ORDERED_IDX_BY_PROJECT_PHASE: Record<
  EProjectPhase,
  number
> = Object.fromEntries(
  PROJECT_PHASES_ORDERED.map((phase, idx) => [phase, idx])
);

export const EMAIL_REGEX = `^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`;
