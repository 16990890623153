import { debounce, cloneDeep, groupBy } from 'lodash';

import { arraysAreEqual } from '../array';

import { TZodFieldInfo } from '@/abrico-lib-shared/data-exchange/helpers.ts';
import {
  META_CONFIG,
  META_CONFIG_TYPES_INFO,
} from '@/abrico-lib-shared/data-exchange/schemas';
import { TTable } from '@/abrico-lib-shared/data-exchange/types.ts';
import { validateAll } from '@/abrico-lib-shared/data-exchange/validate/validateAll.ts';
import { useInteractionsState } from '@/stores/interactionsStore';

type TObj = { _table: string; id: string } & Record<string, any>;
function _updateMetaDossierErrors() {
  console.time('updating errors');
  const metaDossierWipElByMetaEntryId =
    useInteractionsState.getState().metaDossierWipElByMetaEntryId!;

  const metaDossierData = cloneDeep(
    useInteractionsState.getState().metaDossierInfo!.rawMetaDossier!
  );

  // helper to rebuild the object with the new values
  // Also handling the casting of dates
  function updateObj(obj: TObj | TObj[]) {
    if (Array.isArray(obj)) {
      obj.forEach((o) => updateObj(o));
    } else {
      const config = META_CONFIG[obj._table as TTable]!;
      const typeConfig = META_CONFIG_TYPES_INFO[obj._table as TTable]!;
      for (const field of Object.keys(config.fields ?? {})) {
        const metaEntryId = `${obj._table}|${obj.id}|${field}`;
        const metaEntry = metaDossierWipElByMetaEntryId.get(metaEntryId);
        if (metaEntry) {
          obj[field] = metaEntry.value;
        }

        if (obj[field] !== null) {
          // @ts-expect-error inference fails
          const typeInfo: TZodFieldInfo = typeConfig[field]!;
          if (typeInfo.type === 'text' && typeInfo.format === 'date') {
            // Not sure new Date here is 100% robust with timezone stuff
            obj[field] = new Date(obj[field]);
          }
        }
      }

      for (const relatedField of Object.keys(config.relatedFields ?? {})) {
        updateObj(obj[relatedField]);
      }
    }
  }

  const validationContext = {
    project: metaDossierData.project,
    benef: metaDossierData.benef,
    company: metaDossierData.company,
    operations: metaDossierData.operations,
  };

  Object.values(validationContext).forEach((obj) => updateObj(obj));

  const validationRes = validateAll(validationContext);
  if (validationRes.errors.length > 0) {
    const errorsByMetaEntryId = groupBy(
      validationRes.errors.flatMap((e) =>
        (e.metaEntryIds ?? []).map((metaEntryId) => ({
          metaEntryId,
          error: e.error,
        }))
      ),
      (el) => el.metaEntryId
    );

    const next = new Map(metaDossierWipElByMetaEntryId);
    for (const [metaEntryId, info] of metaDossierWipElByMetaEntryId.entries()) {
      const errors = (errorsByMetaEntryId[metaEntryId] ?? []).map(
        (el) => el.error
      );
      errors.sort((a, b) => a.localeCompare(b));
      // We update only if there is a change to reduce react re-renders
      if (!arraysAreEqual(errors, info.errors)) {
        // We update the errors
        next.set(metaEntryId, { ...next.get(metaEntryId)!, errors });
      }
    }

    useInteractionsState.setState({ metaDossierWipElByMetaEntryId: next });
  }

  console.timeEnd('updating errors');
}

export const updateMetaDossierErrors = debounce(_updateMetaDossierErrors, 300);
