import { Modal, ModalDialog, Typography } from '@mui/joy';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import ModalClose from '@mui/joy/ModalClose';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { ABRICO_SHARED_ENDPOINTS } from '@/abrico-lib-shared/data-exchange/endpoints.ts';
import {
  EDossierReviewByOpsProgress,
  EStakeholder,
} from '@/abrico-lib-shared/generated/prisma-zod';
import {
  sendDocsReviewEmails,
  updateAbricoInfo,
} from '@/cloudFunctions/functions.ts';
import i18n from '@/i18n.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useChantierState } from '@/stores/chantierStore.ts';

export default function EndReviewDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation(['common', 'shared']);

  const reviewKind = useChantierState((state) => state.currentReviewKind);
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const [isEndingReview, setIsEndingReview] = useState(false);
  const [sendToBeneficiary, setSendToBeneficiary] = useState(true);
  const [sendToArtisan, setSendToArtisan] = useState(true);

  const sendEmails = useCallback(
    async (preview: boolean) => {
      const user = await useAppState.getState().getUser();
      const chantierState = useChantierState.getState();
      if (!chantierState.metaDossierForChantier) {
        throw new Error('Not possible at this point');
      }
      const notification = enqueueSnackbar(
        i18n.t('sync.notifications.sendEmails.inProgress'),
        {
          variant: 'info',
          autoHideDuration: null,
        }
      );
      setIsSendingEmails(true);
      try {
        const sendTo: (EStakeholder.ARTISAN | EStakeholder.BENEFICIARY)[] = [];
        if (sendToArtisan) {
          sendTo.push(EStakeholder.ARTISAN);
        }
        if (sendToBeneficiary) {
          sendTo.push(EStakeholder.BENEFICIARY);
        }
        const options: z.infer<
          typeof ABRICO_SHARED_ENDPOINTS.sendDocsReviewEmails.inputSchema
        >['options'] = {
          sendTo,
        };
        if (preview) {
          options.forcedEmail = user.email!;
        }

        await sendDocsReviewEmails({
          projectId: chantierState.metaDossierForChantier.projectId,
          options,
        });
        enqueueSnackbar(i18n.t('sync.notifications.sendEmails.success'), {
          variant: 'success',
        });
      } catch (e) {
        console.error(e);
        enqueueSnackbar(i18n.t('sync.notifications.sendEmails.failed'), {
          variant: 'error',
        });
      } finally {
        closeSnackbar(notification);
        setIsSendingEmails(false);
      }
    },
    [sendToArtisan, sendToBeneficiary]
  );

  const endReview = useCallback(async () => {
    const chantierState = useChantierState.getState();
    if (!chantierState.metaDossierForChantier) {
      throw new Error('Not possible at this point');
    }
    const notification = enqueueSnackbar(
      i18n.t('sync.notifications.endReview.inProgress'),
      {
        variant: 'info',
        autoHideDuration: null,
      }
    );
    setIsEndingReview(true);

    try {
      // If we are not outside of the process
      if (chantierState.currentReviewKind) {
        await updateAbricoInfo({
          formId: null,
          isEndOfFormSave: true,
          mainObject: {
            kind: 'project',
            id: chantierState.metaDossierForChantier.projectId,
            onSave: [],
          },
          objects: [
            {
              id: chantierState.metaDossierForChantier.projectId,
              table: 'project',
              data: {
                [`dossierReviewByOpsProgress_${chantierState.currentReviewKind}`]:
                  EDossierReviewByOpsProgress.DONE,
              },
            },
          ],
        });
      }
      await sendEmails(false);
      enqueueSnackbar(i18n.t('sync.notifications.endReview.success'), {
        variant: 'success',
      });
      setOpen(false);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(i18n.t('sync.notifications.endReview.failed'), {
        variant: 'error',
      });
    } finally {
      closeSnackbar(notification);
      setIsEndingReview(false);
    }
  }, [sendEmails, setOpen]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog>
        <ModalClose />

        <Typography marginRight={4}>
          Revue en cours :{' '}
          {reviewKind ? t(`review.reviewKind.${reviewKind}`) : <i>Aucune</i>}
        </Typography>
        <Checkbox
          label={'Artisan'}
          checked={sendToArtisan}
          onChange={(e) => setSendToArtisan(e.target.checked)}
        />
        <Checkbox
          label={'Bénéficiaire'}
          checked={sendToBeneficiary}
          onChange={(e) => setSendToBeneficiary(e.target.checked)}
        />
        <Button
          loading={isSendingEmails && !isEndingReview}
          onClick={() => sendEmails(true)}
          disabled={(!sendToArtisan && !sendToBeneficiary) || isEndingReview}
        >
          Recevoir aperçus emails
        </Button>
        <Button
          disabled={isSendingEmails}
          loading={isEndingReview}
          onClick={() => endReview()}
        >
          {reviewKind === null
            ? 'Envoyer les emails définitifs'
            : 'Terminer la revue et envoyer les emails définitifs'}
        </Button>
      </ModalDialog>
    </Modal>
  );
}
