import { TDBSaveObj } from '@/abrico-lib-shared/data-exchange/saveSchemas.ts';
import {
  DocumentVersion,
  EDossierReviewByOpsProgress,
} from '@/abrico-lib-shared/generated/prisma-zod';
import { useChantierState } from '@/stores/chantierStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';

export function buildAbricoDataForSave(): TDBSaveObj[] {
  const chantierState = useChantierState.getState();
  if (!chantierState.metaDossierForChantier) {
    throw new Error('Not possible at this point');
  }

  const interactionsState = useInteractionsState.getState();
  if (!interactionsState.metaDossierInfo) {
    throw new Error('Not possible !');
  }

  const projectId = chantierState.metaDossierForChantier.projectId;

  const changesGroupedByObjKey: Record<string, TDBSaveObj['data']> = {};
  const objsToSave = [
    ...Object.entries(interactionsState.metaDossierChanges),
    [
      `project|${projectId}|internalNote`,
      chantierState.chantierInternalReviewNotesMarkdown ?? '',
    ],
  ];
  if (chantierState.currentReviewKind !== null) {
    objsToSave.push([
      `project|${projectId}|dossierReviewByOpsProgress_${chantierState.currentReviewKind}`,
      EDossierReviewByOpsProgress.IN_PROGRESS,
    ]);
  }

  objsToSave.forEach(([key, value]) => {
    const [table, id, col] = key.split('|');
    const objKey = `${table}|${id}`;
    if (!(objKey in changesGroupedByObjKey)) {
      changesGroupedByObjKey[objKey] = {};
    }
    changesGroupedByObjKey[objKey]![col] = value;
  });

  const docVersionUpdate: TDBSaveObj = {
    table: 'documentVersion',
    id: interactionsState.metaDossierInfo?.review.docVersionId,
    data: {
      isValid:
        interactionsState.reviewStatus === 'pending'
          ? null
          : interactionsState.reviewStatus === 'valid',
      reviewComment: interactionsState.reviewFeedbackMarkdown,
      rejectedByKE: interactionsState.rejectedByKE ?? false,
    } satisfies Pick<
      DocumentVersion,
      'isValid' | 'reviewComment' | 'rejectedByKE'
    >,
  };

  return [
    docVersionUpdate,
    ...Object.entries(changesGroupedByObjKey).map(
      ([objKey, data]) =>
        ({
          table: objKey.split('|')[0]! as TDBSaveObj['table'],
          id: objKey.split('|')[1]!,
          data,
        }) satisfies TDBSaveObj
    ),
  ];
}
