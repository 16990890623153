import { queryOptions } from '@tanstack/react-query';

import { getChantierMetaDossier } from '@/cloudFunctions/functions.ts';
import { TChantierMetaDossierInfo } from '@/schemas/metaDossier.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useChantierState } from '@/stores/chantierStore.ts';

export const getChantierInfo = () => {
  const queryFunction = async (): Promise<TChantierMetaDossierInfo | null> => {
    const dossierId = useAppState.getState().urlParams.dossierId;
    const chantierId = useAppState.getState().urlParams.chantierId;
    if (!dossierId && !chantierId) {
      throw new Error('dossierId && chantierId not defined');
    }

    try {
      useChantierState.getState().setMetaDossierForChantierIsLoading(true);
      const { data } = await getChantierMetaDossier(
        chantierId
          ? ({ kind: 'projectId', id: chantierId! } as const)
          : ({ kind: 'stationId', id: dossierId! } as const)
      );
      if (data) {
        useChantierState.getState().setMetaDossierForChantier(data);
      }
      return data;
    } catch (error) {
      console.error('Error reloading metaDossier', error);
      return null;
    } finally {
      useChantierState.getState().setMetaDossierForChantierIsLoading(false);
    }
  };

  return queryOptions({
    queryKey: ['chantier', 'meta'],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};
