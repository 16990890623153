import { httpsCallable } from 'firebase/functions';
import { z } from 'zod';

import { ABRICO_SHARED_ENDPOINTS } from '@/abrico-lib-shared/data-exchange/endpoints.ts';
import { functions } from '@/config/firebaseInit';
import { TAnalysisResponse } from '@/schemas/analysis.ts';
import {
  TChantierMetaDossierInfo,
  TDocMetaDossier,
} from '@/schemas/metaDossier.ts';
import { TAttestationResponse, ZGenerateReviewFeedbackBody } from '@/types';

export const invokeSplitterAndExtractorWorkflow = httpsCallable(
  functions,
  'invokeWorkflow-docSplitterAndExtractor',
  { timeout: 300000 }
);

export const getAnalysis = httpsCallable<
  { dossierId: string },
  TAnalysisResponse
>(functions, 'documentAI-getAnalysis', {
  timeout: 100000,
});

export const getDocMetaDossier = httpsCallable<
  { dossierId: string },
  TDocMetaDossier | null
>(functions, 'documentAI-getMetaDossierInfo');

export const getChantierMetaDossier = httpsCallable<
  z.infer<typeof ABRICO_SHARED_ENDPOINTS.getMetaInfo.inputSchema>,
  TChantierMetaDossierInfo
>(functions, 'stationHelpers-getChantierMetaDossier');

export const generateReviewFeedback = httpsCallable<
  z.infer<typeof ZGenerateReviewFeedbackBody>,
  { succeeded: boolean; reviewFeedbackMarkdown: string }
>(functions, 'stationHelpers-generateReviewFeedback');

export const updateAbricoInfo = httpsCallable<
  z.infer<typeof ABRICO_SHARED_ENDPOINTS.saveForm.inputSchema>,
  void
>(functions, 'stationHelpers-updateAbricoInfo');

export const sendDocsReviewEmails = httpsCallable<
  z.infer<typeof ABRICO_SHARED_ENDPOINTS.sendDocsReviewEmails.inputSchema>,
  void
>(functions, 'stationHelpers-sendDocsReviewEmails');

export const getAttestationsInfo = httpsCallable<
  { siret: string; codes_ademe: string[] },
  TAttestationResponse
>(functions, 'artisans_rge__get_attestations');
