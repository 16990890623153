import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/joy';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/joy/CircularProgress';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { Fzf } from 'fzf';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { META_CONFIG } from '@/abrico-lib-shared/data-exchange/schemas.ts';
import { TTable } from '@/abrico-lib-shared/data-exchange/types.ts';
import { getDocMetaDossier } from '@/cloudFunctions/functions.ts';
import { EditSyncElement } from '@/components/Sync/SyncEntityEl.tsx';
import i18n from '@/i18n.ts';
import { TDocMetaDossier } from '@/schemas/metaDossier.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { useUiState } from '@/stores/uiStore.ts';
import {
  TMetaDossierWipElByMetaEntryId,
  TSyncValue,
  TWipMetaEl,
} from '@/types';
import { isoDateStrToShortFrDateStr } from '@/utils/dates.ts';

const dossierIdWithDataQuery = () => {
  const queryFunction = async (): Promise<TDocMetaDossier | null> => {
    const dossierId = useAppState.getState().getDossierId();

    try {
      useInteractionsState.getState().setMetaDossierInfoIsLoading(true);
      const { data } = await getDocMetaDossier({ dossierId });
      if (data) {
        useInteractionsState.getState().setMetaDossierInfo(data);
        // Hack to redirect old single file URL to their proper ones
        const urlParams = useAppState.getState().urlParams;
        const chantierId = data.chantierMetaDossierInfo.projectId;
        if (chantierId && urlParams.chantierId !== chantierId) {
          useAppState
            .getState()
            .navigate(
              `/company/${urlParams.companyId}/chantier/${chantierId}/doc/${dossierId}`
            );
        }
      }
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      useInteractionsState.getState().setMetaDossierInfoIsLoading(false);
    }
    return null;
  };

  return queryOptions({
    queryKey: ['dossier', 'meta'],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};

type TComputeInfo = {
  id: string;
  label: string;
  entries: (TComputeInfo | TWipMetaEl)[];
};

const formatValue = (
  value: TSyncValue,
  typeInfo: TWipMetaEl['typeInfo'],
  color?: 'success' | 'error'
) => {
  if (value === null) {
    return (
      <Stack direction={'row'} gap={0.5} alignItems={'center'}>
        <Typography
          level={'body-xs'}
          // @ts-ignore
          color={color ?? 'neutral'}
        >
          (Vide)
        </Typography>
      </Stack>
    );
  }

  if (typeInfo.type === 'text' && typeInfo.format === 'date') {
    return (
      <Typography level={'body-xs'} alignItems={'center'}>
        {isoDateStrToShortFrDateStr(value as string)}
      </Typography>
    );
  } else if (typeInfo.type === 'boolean') {
    return value ? (
      <CheckIcon color={'success'} fontSize={'small'}></CheckIcon>
    ) : (
      <CloseIcon color={'warning'} fontSize={'small'}></CloseIcon>
    );
  } else {
    return (
      <Typography level={'body-xs'} alignItems={'center'}>
        {value}
      </Typography>
    );
  }
};

export function SyncPanelEntry(wipInfo: TWipMetaEl) {
  const { t } = useTranslation();
  const color = wipInfo.errors.length > 0 ? 'error' : 'success';
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const setMetaFieldBeingEdited = useUiState(
    (state) => state.setMetaFieldBeingEdited
  );
  const metaFieldBeingEdited = useUiState(
    (state) => state.metaFieldBeingEdited
  );

  const isEditing = metaFieldBeingEdited === wipInfo.metaEntryId;

  return (
    <Stack
      className={'sync-panel-entry'}
      flexDirection={'row'}
      alignItems={'center'}
      //gap={4}
      justifyContent={'space-between'}
      useFlexGap
      sx={{
        cursor: isInReadOnlyMode ? 'default' : 'pointer',
        flexWrap: 'wrap',
      }}
      onClick={() =>
        wipInfo.metaEntryId === metaFieldBeingEdited
          ? setMetaFieldBeingEdited(null)
          : setMetaFieldBeingEdited(wipInfo.metaEntryId)
      }
    >
      {isEditing && (
        <Box onClick={(e) => e.stopPropagation()}>
          <EditSyncElement el={wipInfo}></EditSyncElement>
        </Box>
      )}

      {!isEditing && (
        <Typography level={'body-sm'}>
          <b>{wipInfo.label}</b>
        </Typography>
      )}

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={1}
      >
        {wipInfo.remoteValue === wipInfo.value ? (
          !isEditing && formatValue(wipInfo.remoteValue, wipInfo.typeInfo)
        ) : (
          <Stack flexDirection={'row'} gap={2} sx={{ flexGrow: 1 }}>
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <AutoDeleteIcon fontSize={'small'}></AutoDeleteIcon>
              <div>{formatValue(wipInfo.remoteValue, wipInfo.typeInfo)}</div>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <FiberNewOutlinedIcon
                color={'primary'}
                // @ts-ignore
              ></FiberNewOutlinedIcon>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={0.5}
                // @ts-ignore
                color={color}
              >
                {formatValue(wipInfo.value, wipInfo.typeInfo, color)}{' '}
                {wipInfo.errors.length > 0 ? t('sync.invalidExtra') : ''}
              </Stack>
            </Stack>
          </Stack>
        )}

        {!isEditing && (
          <IconButton
            size="sm"
            onClick={() => setMetaFieldBeingEdited(wipInfo.metaEntryId)}
            disabled={isInReadOnlyMode}
            sx={{
              visibility: 'hidden',
              '.sync-panel-entry:hover &': {
                visibility: 'visible',
              },
            }}
          >
            <EditIcon> </EditIcon>
          </IconButton>
        )}
      </Stack>
      {wipInfo.errors.length > 0 &&
        wipInfo.errors.map((error) => (
          <Typography key={error} level={'body-xs'} color={'danger'}>
            {error}
          </Typography>
        ))}
    </Stack>
  );
}

type TObj = { _table: string; id: string } & Record<string, any>;

const computeDisplayInfo = (
  metaDossierRes: TDocMetaDossier | null | undefined,
  metaDossierWipElByMetaEntryId: TMetaDossierWipElByMetaEntryId,
  editsOnly: boolean = true,
  searchValue: string = ''
): TComputeInfo[] => {
  if (!metaDossierRes) {
    return [];
  }

  let dataByEntryId = metaDossierWipElByMetaEntryId;
  if (searchValue) {
    const searchableValues = [...dataByEntryId.entries()].map(
      ([metaPath, info]) => ({ metaPath, label: info.label })
    );
    const fzf = new Fzf(searchableValues, {
      selector: (item) => item.label,
    });
    const finds = fzf.find(searchValue);
    const foundMetaPaths = new Set(finds.map((f) => f.item.metaPath));
    dataByEntryId = new Map(
      [...dataByEntryId.entries()].filter(([metaPath]) =>
        foundMetaPaths.has(metaPath)
      )
    );
  }

  const codeAdemes = new Set(metaDossierRes.chantierMetaDossierInfo.codesAdeme);

  const getEntries = (obj: TObj | TObj[]): TComputeInfo['entries'] => {
    if (Array.isArray(obj)) {
      return obj.map((o) => ({
        id: o.id,
        label: o._table,
        entries: getEntries(o),
      }));
    }
    const out: TComputeInfo['entries'] = [];
    const config = META_CONFIG[obj._table as TTable];
    if (!config) {
      throw new Error('Not possible!');
    }
    for (const [fieldName, fieldInfo] of Object.entries(config.fields ?? {})) {
      if (fieldInfo?.readOnly) {
        continue;
      }
      if (
        fieldInfo?.restrictToCodeAdemes &&
        !fieldInfo?.restrictToCodeAdemes.some((code) => codeAdemes.has(code))
      ) {
        continue;
      }

      const metaEntryId = `${obj._table}|${obj.id}|${fieldName}`;
      if (!dataByEntryId.has(metaEntryId)) {
        continue;
      }
      const entryData = dataByEntryId.get(metaEntryId)!;
      if (!editsOnly || entryData.hasBeenEdited) {
        out.push(entryData);
      }
    }

    for (const [fieldName, fieldInfo] of Object.entries(
      config.relatedFields ?? {}
    )) {
      if (fieldInfo.readOnly) {
        continue;
      }

      const relatedObj = obj[fieldName];
      if (relatedObj) {
        const subEntries = getEntries(relatedObj);
        if (subEntries.length > 0) {
          out.push({
            id: relatedObj.id,
            label: fieldInfo.label,
            entries: subEntries,
          });
        }
      }
    }

    return out;
  };

  return [
    {
      id: 'benef',
      label: 'Bénéficiaire',
      entries: getEntries(metaDossierRes.rawMetaDossier.benef),
    },
    {
      id: 'artisan',
      label: 'Artisan',
      entries: getEntries(metaDossierRes.rawMetaDossier.company),
    },
    {
      id: 'chantier',
      label: 'Chantier',
      entries: getEntries(metaDossierRes.rawMetaDossier.project),
    },
    {
      id: 'Opérations',
      label: 'Opérations',
      entries: metaDossierRes.rawMetaDossier.operations.map((op) => ({
        id: op.id,
        label:
          i18n.t(`shared:enums.ECodeAdeme.${op.codeAdeme}`) +
          ` (${op.codeAdeme})`,
        entries: getEntries(op),
      })),
    },
  ];
};

const titleLevel = {
  '0': 'title-sm',
  '1': 'body-md',
  '2': 'body-sm',
};
function SyncPanelGroup({
  label,
  entries,
  level,
}: TComputeInfo & { level: number }) {
  return (
    <Box
      sx={{
        mb: 1,
      }}
    >
      <Typography
        // @ts-ignore
        level={titleLevel[level.toString()] ?? 'body-sm'}
        sx={{ mb: 1 }}
      >
        {label}
      </Typography>
      <Card>
        {entries.map((entry) => {
          if ('metaEntryId' in entry) {
            return <SyncPanelEntry {...entry} key={entry.metaEntryId} />;
          } else {
            return (
              <SyncPanelGroup {...entry} level={level + 1} key={entry.id} />
            );
          }
        })}
      </Card>
    </Box>
  );
}

export function SyncPanel() {
  const { t } = useTranslation();
  const [editsOnly, setEditsOnly] = useState(true);

  const metaDossierInfoIsLoading = useInteractionsState(
    (state) => state.metaDossierInfoIsLoading
  );
  const metaDossierWipElByMetaEntryId = useInteractionsState(
    (state) => state.metaDossierWipElByMetaEntryId
  );

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );

  useEffect(() => {
    if (isInReadOnlyMode) {
      useUiState.getState().setMetaFieldBeingEdited(null);
    }
  }, [isInReadOnlyMode]);

  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    setSearchValue('');
    setEditsOnly(!showSearch);
  }, [showSearch]);

  const { data: metaDossierRes, isLoading } = useQuery(
    dossierIdWithDataQuery()
  );

  const infos = useMemo<TComputeInfo[]>(
    () =>
      computeDisplayInfo(
        metaDossierRes,
        metaDossierWipElByMetaEntryId,
        searchValue ? false : editsOnly,
        searchValue
      ),
    [metaDossierRes, metaDossierWipElByMetaEntryId, editsOnly, searchValue]
  );

  const [expanded, setExpanded] = useState(false);

  if (!metaDossierRes) {
    if (isLoading) {
      return <CircularProgress />;
    } else {
      return <Typography>{t('sync.noMetaDossier')}</Typography>;
    }
  }

  return (
    <Card
      sx={{
        borderRadius: 12,
        borderWidth: 4,
        p: 2,
        pt: 0,
        pb: 1,
        width: '100%',
        overflowY: expanded ? 'auto' : 'unset',
        flexGrow: expanded ? 1 : 0,
        flexBasis: 1,
        maxHeight: 'fit-content',
      }}
    >
      <Accordion onChange={(_, newExpanded) => setExpanded(newExpanded)}>
        <AccordionSummary
          sx={{
            paddingTop: 2,
            paddingBottom: 1,
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#0b0d0e',
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ width: '100%' }}
          >
            <Typography
              level={'title-md'}
              sx={{ fontWeight: 'var(--joy-fontWeight-lg) !important' }}
              textAlign={'center'}
            >
              {t('ui.tabs.sync')}
            </Typography>
            {metaDossierInfoIsLoading ? (
              <Stack gap={1} direction={'row'} alignItems={'center'}>
                <Typography level={'body-sm'}>
                  {t('sync.dataUpdateInProgress')}
                </Typography>
                <CircularProgress size={'sm'} />
              </Stack>
            ) : (
              <Typography level={'body-xs'}>
                {t('sync.dataIsUpToData')}
              </Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          style={{
            // We set the visibility to 0 to make checkElementIsVisible work properly
            opacity: expanded ? 'unset' : 0,
          }}
        >
          <Stack gap={1}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
              <IconButton onClick={() => setShowSearch((v) => !v)}>
                <SearchIcon />
              </IconButton>
              {showSearch && (
                <Input
                  sx={{ flexGrow: 1 }}
                  autoFocus={true}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              )}
            </Stack>
            {infos.map((info) => (
              <SyncPanelGroup {...info} key={info.id} level={0} />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
