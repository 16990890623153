import { WebViewerInstance } from '@pdftron/webviewer';

import { checkAnnotationShouldBeSaved } from '@/utils/annotations.ts';

/**
 * Get file data with annotations from the webviewer instance
 * @param instance
 */
export const getFileData = async (
  instance: WebViewerInstance
): Promise<Uint8Array> => {
  const { documentViewer, annotationManager } = instance.Core;
  const doc = documentViewer.getDocument();

  const annotationsToKeep = annotationManager
    .getAnnotationsList()
    .filter((el) => checkAnnotationShouldBeSaved(el));

  // We get the annotations string for the annotations to keep
  const xfdfString = await annotationManager.exportAnnotations({
    annotationList: annotationsToKeep,
  });

  const fileData = await doc.getFileData({
    xfdfString,
    includeAnnotations: true,
    // We linearize the PDF to optimize it a tiny bit and remove dead objects
    flags: instance.Core.SaveOptions.LINEARIZED,
  });

  // We avoid returning a buffer not to complexify the result of this function
  // Yes, we might use a bit more memory then.
  return new Uint8Array(fileData);
};
