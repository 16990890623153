import i18n from 'i18next';
import { enqueueSnackbar } from 'notistack';

import { generateReviewFeedback } from '@/cloudFunctions/functions.ts';
import { useAppState } from '@/stores/appStore.ts';
import { TReviewInfo } from '@/types';

export async function generateAiReviewFeedback(
  reviewInfo: TReviewInfo
): Promise<string> {
  let res = '';
  try {
    const { data } = await generateReviewFeedback({
      dossierId: useAppState.getState().getDossierId()!,
      rejections: reviewInfo.rejectedEntities.map((el) => ({
        label: el.label,
        rejectionReason: el.rejectionReason,
        isMissing: el.kind === 'missing',
      })),
    });
    if (!data.succeeded) {
      throw new Error('Failed to generate feedback');
    }

    res = data.reviewFeedbackMarkdown;
    enqueueSnackbar(i18n.t('review.ai.notification.success'), {
      variant: 'success',
    });
  } catch (e) {
    console.error(e);
    enqueueSnackbar(i18n.t('review.ai.notification.error'), {
      variant: 'error',
    });
  }

  return res;
}
