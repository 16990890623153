import { isEqual } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { EDocType } from '@/abrico-lib-shared/generated/prisma-zod';
import i18n from '@/i18n';
import { queryClient } from '@/queries';
import { TChantierMetaDossierInfo } from '@/schemas/metaDossier.ts';
import { useAppState } from '@/stores/appStore.ts';
import { EReviewKind } from '@/types/review.ts';
import { getReviewStatus } from '@/utils/review/helpers.ts';

type TChantierDocs = {
  phaseDocs: {
    id: string;
    label: string;
    isValid: boolean | null;
    reviewStatus: 'pending' | 'valid' | 'invalid';
    dossierId: string;
    url: string;
    docType: EDocType;
    reviewFeedbackMarkdown: string;
  }[];
  otherPhaseDocs: {
    id: string;
    label: string;
    dossierId: string;
    reviewStatus: 'pending' | 'valid' | 'invalid';
    isValid: boolean | null;
    url: string;
    glideDocTypeId: string;
    reviewFeedbackMarkdown: string;
  }[];
};

interface ChantierStateValues {
  metaDossierForChantier: TChantierMetaDossierInfo | null;
  metaDossierForChantierIsLoading: boolean | null;
  computedChantierDocs: TChantierDocs;
  chantierInternalReviewNotesMarkdown: string | null;
  remoteChantierInternalReviewNotesMarkdown: string | null;
  currentPhaseIsValid: boolean | null;
  remoteCurrentPhaseMissingDocTypes: string[] | null;
  remoteMarkdownFeedbackByDocId: Record<string, string | null> | null;
  markdownFeedbackByDocId: Record<string, string | null> | null;
  currentReviewKind: EReviewKind | null;
}

interface ChantierState extends ChantierStateValues {
  setMetaDossierForChantier: (
    metaDossierForChantier: TChantierMetaDossierInfo
  ) => void;
  setMetaDossierForChantierIsLoading: (isLoading: boolean) => void;
  setChantierInternalReviewNotesMarkdown: (markdown: string | null) => void;
  setCurrentPhaseExtraDocTypes: (extraDocTypes: string[]) => void;
  setMarkdownFeedbackReview: (id: string, markdown: string | null) => void;
  resetState: () => void;
  setCurrentReviewKind: (reviewKind: EReviewKind | null) => void;
}

const getDefaultStateValues = (): ChantierStateValues => ({
  currentReviewKind: null as EReviewKind | null,
  metaDossierForChantier: null as TChantierMetaDossierInfo | null,
  metaDossierForChantierIsLoading: false as boolean,
  computedChantierDocs: { phaseDocs: [], otherPhaseDocs: [] },
  chantierInternalReviewNotesMarkdown: null as string | null,
  remoteChantierInternalReviewNotesMarkdown: null as string | null,
  remoteCurrentPhaseMissingDocTypes: [] as EDocType[],
  currentPhaseIsValid: null as boolean | null,
  remoteMarkdownFeedbackByDocId: null as Record<string, string | null> | null,
  markdownFeedbackByDocId: null as Record<string, string | null> | null,
});

// TODO move meta elements here
export const useChantierState = create<ChantierState>()(
  devtools(
    (set, get) => ({
      ...getDefaultStateValues(),
      setMetaDossierForChantier: (metaDossierForChantier) => {
        const computedChantierDocs: TChantierDocs = {
          phaseDocs: [],
          otherPhaseDocs: [],
        };

        const urlParams = useAppState.getState().urlParams;
        const remoteMarkdownFeedbackByDocId: Record<string, string> = {};
        metaDossierForChantier.documents.forEach((docInfo) => {
          if (docInfo.dossierId) {
            computedChantierDocs.phaseDocs.push({
              id: docInfo.docVersionId,
              label: i18n.t(`shared:enums.EDocType.${docInfo.docType}`),
              isValid: docInfo.isValid,
              dossierId: docInfo.dossierId,
              url: `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}/doc/${docInfo.dossierId}`,
              docType: docInfo.docType as EDocType,
              reviewStatus: getReviewStatus(docInfo),
              reviewFeedbackMarkdown: docInfo.reviewFeedbackMarkdown ?? '',
            });

            remoteMarkdownFeedbackByDocId[docInfo.docVersionId] =
              docInfo.reviewFeedbackMarkdown ?? '';
          }
        });

        set({
          metaDossierForChantier,
          computedChantierDocs,
          remoteChantierInternalReviewNotesMarkdown:
            metaDossierForChantier.internalReviewNotesMarkdown ?? '',
          chantierInternalReviewNotesMarkdown:
            get().chantierInternalReviewNotesMarkdown ??
            metaDossierForChantier.internalReviewNotesMarkdown ??
            '',
          remoteCurrentPhaseMissingDocTypes: metaDossierForChantier.documents
            .filter((el) => el.dossierId === null)
            .map((el) => el.docType as EDocType),
          remoteMarkdownFeedbackByDocId: remoteMarkdownFeedbackByDocId,
          markdownFeedbackByDocId:
            get().markdownFeedbackByDocId ?? remoteMarkdownFeedbackByDocId,
        });
      },
      setMetaDossierForChantierIsLoading: (isLoading) => {
        set({ metaDossierForChantierIsLoading: isLoading });
      },
      setChantierInternalReviewNotesMarkdown: (markdown: string | null) => {
        set(() => ({ chantierInternalReviewNotesMarkdown: markdown }));
      },
      setMarkdownFeedbackReview(id: string, markdown: string | null) {
        set({
          markdownFeedbackByDocId: {
            ...get().markdownFeedbackByDocId,
            [id]: markdown,
          },
        });
      },
      setCurrentReviewKind(reviewKind: EReviewKind | null) {
        set({
          currentReviewKind: reviewKind,
        });
      },
      resetState: () => {
        set(getDefaultStateValues());
        queryClient.invalidateQueries({
          queryKey: ['chantier', 'meta'],
        });
      },
    }),
    {
      name: 'chantier-store',
    }
  )
);

useChantierState.subscribe((state) => {
  const hasChanges =
    state.remoteChantierInternalReviewNotesMarkdown !==
      state.chantierInternalReviewNotesMarkdown ||
    !isEqual(
      state.markdownFeedbackByDocId,
      state.remoteMarkdownFeedbackByDocId
    );
  if (
    hasChanges !== useAppState.getState().hasCRMChantierRelatedChangesToSave
  ) {
    useAppState.getState().setHasCRMChantierRelatedChangesToSave(hasChanges);
  }

  const currentPhaseIsValid =
    (state.remoteCurrentPhaseMissingDocTypes ?? []).length === 0 &&
    state.computedChantierDocs.phaseDocs.every((doc) => doc.isValid);

  if (currentPhaseIsValid !== state.currentPhaseIsValid) {
    useChantierState.setState({ currentPhaseIsValid });
  }
});

useAppState.subscribe(async (state, prevState) => {
  const prevChantierId = prevState.urlParams?.chantierId;
  const newChantierId = state.urlParams?.chantierId;

  const prevDossierId = prevState.urlParams?.dossierId;
  const newDossierId = state.urlParams?.dossierId;
  if (newDossierId !== prevDossierId) {
    const prevReviewState = useChantierState.getState().currentReviewKind;
    useChantierState.getState().resetState();
    // We restore the review state if we navigate to the same chantier
    if (prevChantierId === newChantierId) {
      useChantierState.getState().setCurrentReviewKind(prevReviewState);
    }
  }

  if (newChantierId !== prevChantierId) {
    if (newChantierId) {
      useChantierState.getState().setMetaDossierForChantierIsLoading(true);
      queryClient.invalidateQueries({
        queryKey: ['chantier', 'meta'],
      });
      queryClient.invalidateQueries({
        queryKey: ['dossier', 'meta'],
      });
    }
  }
});
