import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WebViewer from '@pdftron/webviewer';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { ABRICO_ORANGE_MAIN, ELEMENTS_TO_DISABLE } from '@/utils/constants';
import { getFileData } from '@/utils/document.ts';
import { downloadFile } from '@/utils/file';
import { initInstance } from '@/utils/interactions.ts';
import { removeApryseWatermark } from '@/utils/pdf.ts';
import { saveDocumentToCloud } from '@/utils/save.ts';

export default function WebViewerWrapper() {
  const ref = useRef();
  const beenInitialised = useRef<boolean>(false);
  const { instance, setInstance } = useAppState(
    useShallow(({ instance, setInstance }) => ({ instance, setInstance }))
  );
  const company = useAppState((state) => state.company);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDownloadPDF = useCallback(
    async (compress: boolean) => {
      if (instance && instance.Core) {
        let wipNotification;
        try {
          setIsDownloading(true);
          wipNotification = enqueueSnackbar(
            t('file.download.prepareDownloadInProgress'),
            {
              autoHideDuration: null,
              variant: 'info',
            }
          );
          const fileData = await getFileData(instance);
          const pdfDoc = await instance.Core.PDFNet.PDFDoc.createFromBuffer(
            fileData.slice()
          );

          let dataToUse = fileData;
          if (compress) {
            try {
              await instance.Core.PDFNet.Optimizer.optimize(pdfDoc);
              dataToUse = Uint8Array.from(
                await pdfDoc.saveMemoryBuffer(
                  instance.Core.PDFNet.SDFDoc.SaveOptions.e_linearized
                )
              );
            } catch (e) {
              // Sometime the compression fails with some cryptic error (cannot get pdf root)
              console.error('Error while compressing PDF:', e);
            }
          }

          const withoutWatermark = await removeApryseWatermark(dataToUse);
          downloadFile(
            withoutWatermark,
            (await useInteractionsState.getState().getDossier()).fileName
          );
          enqueueSnackbar(t('file.download.downloadReady'), {
            variant: 'success',
          });
          handleClose();
        } finally {
          if (wipNotification) {
            closeSnackbar(wipNotification);
          }
          setIsDownloading(false);
        }
      }
    },
    [handleClose, instance, t]
  );

  useEffect(() => {
    (async () => {
      if (!beenInitialised.current && company?.id) {
        beenInitialised.current = true;

        // FIXME: iframe is legacy!
        const instance = await WebViewer.Iframe(
          {
            path: '/webviewer/public',
            licenseKey: import.meta.env.VITE_APRYSE_KEY,
            fullAPI: true,
            disableLogs: true,
            enableOfficeEditing: false,
            enableMeasurement: false,
            config: '/monkeyPatchFetch.js',
            css: '/custom-webviewer-style.css',
            disableMultiViewerComparison: true,
            // FIXME: update to the latest UI
            ui: 'legacy',
          },
          // @ts-ignore
          ref.current
        );
        const promisesToWaitFor: Promise<void>[] = [
          instance.Core.PDFNet.initialize(),
        ];

        instance.UI.OutlinesPanel.setDefaultOptions({
          autoExpandOutlines: true,
        });

        const {
          setHeaderItems,
          Theme,
          setTheme,
          enableFeatures,
          disableElements,
          enableElements,
          setLanguage,
          setFitMode,
          iframeWindow,
          FitMode,
          disableFeatures,
          Feature,
        } = instance.UI;

        const { documentViewer } = instance.Core;
        setTheme(Theme.DARK);

        // We need to do this to be able to copy to clipboard from the viewer
        // for the dossier name in particular
        document
          .getElementsByTagName('iframe')[0]
          .setAttribute('allow', 'clipboard-write');

        const style = iframeWindow.document.documentElement.style;
        style.setProperty(`--primary-button`, 'ABRICO_ORANGE_MAIN');
        style.setProperty(`--primary-button-hover`, '#ffe4db');
        style.setProperty(`--popup-button-active`, 'rgba(255,228,219,0.2)');
        style.setProperty(`--outline-selected`, 'rgba(255,228,219,0.1)');
        style.setProperty(`--popup-button-hover`, 'rgba(255,228,219,0.1)');

        style.setProperty(`--blue-6`, ABRICO_ORANGE_MAIN);
        style.setProperty(`--blue-5`, '#ffe4db');

        promisesToWaitFor.push(setLanguage('fr'));

        // see https://docs.apryse.com/documentation/web/guides/basics/open/url/#the-customheaders-option
        // if unauthenticated

        setFitMode(FitMode.FitWidth);

        disableFeatures([
          Feature.FilePicker,
          Feature.NotesPanel,
          Feature.InlineComment,
          Feature.Print,
          Feature.ComparePages,
          Feature.MultipleViewerMerging,
        ]);
        enableFeatures(['ThumbnailMultiselect']);
        enableElements([
          'documentControl',
          'bookmarksPanel',
          'bookmarksPanelButton',
        ]);
        disableElements(ELEMENTS_TO_DISABLE);

        // Add header buttons
        setHeaderItems((header) => {
          const abricoButton = {
            type: 'actionButton',
            img: '/abrico_letter.svg',
            title: t('navigation.dossier_to_dossiers_table'),
            dataElement: 'abricoButton',
            onClick: async () => {
              const dossier = await useInteractionsState
                .getState()
                .getDossier();
              useAppState
                .getState()
                .navigate(
                  `/company/${company?.id}/dossiers/tag/${dossier.tagId}`
                );
            },
          };

          const dossierNameButton = {
            type: 'customElement',
            render: () => {
              const button = document.createElement('button');
              button.innerText =
                useInteractionsState.getState().dossier?.name ?? '';

              useInteractionsState.subscribe((state, prevState) => {
                if (state.dossier?.name !== prevState.dossier?.name) {
                  button.innerText = state.dossier?.name ?? '';
                }
              });

              button.style.backgroundColor = 'transparent';
              button.style.color = 'white';
              button.style.fontWeight = 'bold';
              button.style.cursor = 'pointer';
              button.style.fontSize = '1em';
              button.style.border = 'none';
              button.style.margin = 'none';
              button.style.maxWidth = '200px';
              button.style.textOverflow = 'ellipsis';
              button.style.overflow = 'hidden';
              button.style.whiteSpace = 'nowrap';

              button.onclick = async () => {
                await navigator.clipboard.writeText(
                  (await useInteractionsState.getState().getDossier()).name
                );
              };
              return button;
            },
          };
          const saveHeaderItem = {
            type: 'actionButton',
            img: '/icon-save.svg',
            title: t('file.save.title'),
            dataElement: 'saveUpdatedDocumentButton',
            onClick: async () => {
              await saveDocumentToCloud({
                notify: true,
              });
            },
          };

          const downloadHeaderItem = {
            type: 'actionButton',
            img: '/icon-download.svg',
            title: t('file.download.title'),
            dataElement: 'downloadDocumentButton',
            onClick: async () => {
              handleOpen();
            },
          };

          const fitButton = {
            type: 'statefulButton',
            initialState: 'FitWidth',

            states: {
              FitWidth: {
                img: '/icon-header-zoom-fit-to-width.svg',
                onClick: () => {
                  setFitMode(FitMode.FitWidth);
                },
                title: 'Ajuster à la largeur',
              },
              FitPage: {
                img: '/icon-header-zoom-fit-to-page.svg',
                onClick: () => {
                  setFitMode(FitMode.FitPage);
                },
                title: 'Ajuster à la page',
              },
            },
            mount: (update: any) => {
              const fitModeToState = (fitMode: any) => {
                if (fitMode === documentViewer.FitMode.FitPage) {
                  return 'FitWidth';
                } else if (fitMode === documentViewer.FitMode.FitWidth) {
                  return 'FitPage';
                }
              };

              documentViewer.addEventListener(
                'fitModeUpdated.fitbutton',
                (fitMode) => {
                  update(fitModeToState(fitMode));
                }
              );
            },
            unmount: () => {
              documentViewer.removeEventListener('fitModeUpdated.fitbutton');
            },
            dataElement: 'fitButton',
            hidden: ['mobile'],
          };

          const roButton = {
            type: 'customElement',
            render: () => {
              const button = document.createElement('button');
              button.innerText = t('rwLock.roBtnTitle');
              button.style.backgroundColor = 'transparent';
              button.style.color = ABRICO_ORANGE_MAIN;
              button.style.fontWeight = 'bold';
              button.style.cursor = 'pointer';
              button.style.fontSize = '1em';
              button.style.border = 'none';
              button.style.margin = 'none';
              button.onclick = () => {
                useInteractionsState.getState().setRoModalOpen(true);
              };

              // We keep track of the read-only mode and display or hide the btn accordingly
              // A bit hacky, but it works.
              useInteractionsState.subscribe((state) => {
                if (state.isInReadOnlyMode) {
                  button.style.display = 'block';
                } else {
                  button.style.display = 'none';
                }
              });
              return button;
            },
          };

          // @ts-ignore
          header.headers.default.splice(0, 0, abricoButton);
          if (company?.featureFlags?.handleBackofficeSync !== true) {
            // @ts-ignore
            header.headers.default.splice(1, 0, dossierNameButton);
            // @ts-ignore
            header.headers.default.splice(2, 0, saveHeaderItem);
            // @ts-ignore
            header.headers.default.splice(3, 0, downloadHeaderItem);
          }

          // @ts-ignore
          header.headers.default.splice(7, 0, fitButton);
          // @ts-ignore
          //header.headers.default.splice(12, 0, verifySignatureButton);
          // @ts-ignore
          header.headers.default.splice(12, 0, roButton);
        });

        await Promise.all(promisesToWaitFor);
        initInstance(instance);
        setInstance(instance);
      }
    })();
    // Do not add anything here, we want to run this only once for sure
  }, []);

  return (
    <Box ref={ref} sx={{ width: '100%', height: '100%' }}>
      <Modal
        open={open}
        onClose={() => {
          if (!isDownloading) handleClose();
        }}
      >
        <ModalDialog>
          <Button
            onClick={() => handleDownloadPDF(false)}
            disabled={isDownloading}
          >
            {t('file.download.not_compressed')}
          </Button>
          <Button
            onClick={() => handleDownloadPDF(true)}
            disabled={isDownloading}
          >
            {t('file.download.compressed')}
          </Button>
        </ModalDialog>
      </Modal>
    </Box>
  );
}
