import { MoreVert } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { Box, Tooltip } from '@mui/joy';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ReviewKindInfo from '@/components/Chantier/ReviewKindInfo.tsx';
import DossierStatusChip from '@/components/Review/DossierStatusChip.tsx';
import { getChantierInfo } from '@/queries/chantier.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useChantierState } from '@/stores/chantierStore.ts';
import { openOtherDocument } from '@/utils/multiViewer.ts';

export default function ChantierDocsBreadcrumbs() {
  const { t } = useTranslation();
  useQuery(getChantierInfo());

  const urlParams = useAppState((state) => state.urlParams);

  const { phaseDocs, otherPhaseDocs } = useChantierState(
    (state) => state.computedChantierDocs
  );

  const currentDocIsInPhase = useMemo(() => {
    return phaseDocs.some((doc) => doc.dossierId === urlParams.dossierId);
  }, [phaseDocs, urlParams]);

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      paddingLeft={2}
      paddingRight={2}
      gap={1}
      sx={{ backgroundColor: '0A0E0F' }}
    >
      <IconButton
        onClick={() =>
          useAppState
            .getState()
            .navigate(
              `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}`
            )
        }
      >
        <HomeIcon />
      </IconButton>
      <DossierStatusChip />
      <ReviewKindInfo />
      <Box
        display={'inline-flex'}
        padding={1}
        gap={2}
        width={'fit-content'}
        sx={{ overflowX: 'auto', margin: '0 auto' }}
      >
        {phaseDocs.map((doc, idx) => {
          const isOpenedDoc = doc.dossierId === urlParams?.dossierId;
          return (
            <React.Fragment key={doc.id}>
              <Card
                key={doc.id}
                sx={{
                  padding: 0.2,
                  paddingLeft: 0.5,
                  cursor: !isOpenedDoc ? 'pointer' : 'default',
                  backgroundColor: isOpenedDoc ? '#22252b' : 'neutral',
                }}
                variant={isOpenedDoc ? 'outlined' : 'plain'}
                onClick={() => {
                  if (!isOpenedDoc) {
                    useAppState.getState().navigate(doc.url);
                  }
                }}
              >
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <Chip
                    color={
                      doc.isValid === true
                        ? 'success'
                        : doc.isValid === false
                          ? 'danger'
                          : 'neutral'
                    }
                    variant={
                      doc.isValid === true || doc.isValid === false
                        ? 'solid'
                        : 'outlined'
                    }
                  >
                    {idx + 1}
                  </Chip>
                  <Typography whiteSpace={'nowrap'}>{doc.label}</Typography>

                  <Tooltip title={t('ui.docType.compare')}>
                    <IconButton
                      // @ts-ignore
                      color={'common.white'}
                      onClick={(e) => {
                        openOtherDocument(doc.dossierId);
                        e.stopPropagation();
                      }}
                    >
                      <VerticalSplitIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Card>
              {idx < phaseDocs.length - 1 && (
                <NavigateNextIcon sx={{ margin: 'auto 0' }} />
              )}
            </React.Fragment>
          );
        })}
      </Box>
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { variant: 'outlined', color: 'neutral', size: 'sm' },
          }}
          sx={{ height: 4, paddingRight: 1 }}
          startDecorator={<MoreVert />}
        >
          <Typography level={'body-xs'}>
            {!currentDocIsInPhase && '⚠️'} Tous
          </Typography>
        </MenuButton>
        <Menu>
          <Typography sx={{ p: 1 }}>Dans la phase</Typography>
          {([...phaseDocs, 'spacer', ...otherPhaseDocs] as const).map((doc) => {
            const isOpenedDoc =
              doc !== 'spacer' && doc.dossierId === urlParams?.dossierId;
            return doc === 'spacer' ? (
              <>
                <Divider sx={{ m: 1 }} />
                <Typography sx={{ p: 1 }}>Hors phase</Typography>
              </>
            ) : (
              <MenuItem
                key={doc.id}
                selected={isOpenedDoc}
                onClick={() => {
                  if (!isOpenedDoc) useAppState.getState().navigate(doc.url);
                }}
              >
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      openOtherDocument(doc.dossierId);
                    }}
                  >
                    <VerticalSplitIcon />
                  </IconButton>
                  <Typography>{doc.label}</Typography>
                </Stack>
              </MenuItem>
            );
          })}
        </Menu>
      </Dropdown>
    </Stack>
  );
}
