import { PROJECT_PHASE_ORDERED_IDX_BY_PROJECT_PHASE } from '@/abrico-lib-shared/constants';
import {
  META_CONFIG,
  META_KEY_MAPPER,
  TSharedAddress,
  TSharedBeneficiary,
  TSharedCompanyInstance,
  TSharedOperation,
  TSharedProject,
  TSharedTaxAssessment,
  TValidateError,
  TValidateRes,
  schemaByTable,
} from '@/abrico-lib-shared/data-exchange/schemas';
import {
  addressIsNotEmpty,
  addressesAreEqual,
} from '@/abrico-lib-shared/data-exchange/validate/utils/address';
import { evaluateExpression } from '@/abrico-lib-shared/dyn-expressions/evaluate';

import { RemoveTableKeys } from '../types';

function validateObj(
  obj:
    | {
        key: 'op';
        data: RemoveTableKeys<TSharedOperation>;
      }
    | {
        key: 'project';
        data: RemoveTableKeys<TSharedProject>;
      }
    | { key: 'benef'; data: RemoveTableKeys<TSharedBeneficiary> }
    | {
        key: 'company';
        data: RemoveTableKeys<TSharedCompanyInstance>;
        path: string;
      }
    | { key: 'address'; data: RemoveTableKeys<TSharedAddress>; path: string }
    | {
        key: 'taxAssessment';
        data: RemoveTableKeys<TSharedTaxAssessment>;
        path: string;
      },
  baseCtx: RemoveTableKeys<{
    project: TSharedProject;
    benef: TSharedBeneficiary;
    company: TSharedCompanyInstance;
    operations: TSharedOperation[];
  }>
): TValidateRes {
  const path = 'path' in obj ? obj.path : obj.key;
  let errors: TValidateError[] = [];
  let codesAdeme = new Set(baseCtx.operations.map((op) => op.codeAdeme));
  if (obj.key === 'op') {
    codesAdeme = new Set([obj.data.codeAdeme]);
  }
  const { key } = obj;
  const keyMapped = META_KEY_MAPPER[key];

  const config = META_CONFIG[keyMapped];

  const context = { ...baseCtx, self: obj.data };
  for (const [relatedField, fieldInfo] of Object.entries(
    config.relatedFields ?? {}
  )) {
    if (
      fieldInfo?.showOnlyIf &&
      evaluateExpression(fieldInfo.showOnlyIf, context) === false
    ) {
      continue;
    }

    // @ts-expect-error Hard to infer
    const rawValue = obj.data[relatedField];

    const val = Array.isArray(rawValue) ? rawValue : [rawValue];
    for (const relEntry of val) {
      errors = errors.concat(
        validateObj(
          {
            // @ts-expect-error not all options are here not worries
            key: config.relatedFields![relatedField]!.kind,
            data: relEntry,
            path: path + '.' + relatedField,
          },
          baseCtx
        ).errors
      );
    }
  }

  for (const [field, fieldInfo] of [...Object.entries(config.fields ?? {})]) {
    if (
      fieldInfo?.showOnlyIf &&
      evaluateExpression(fieldInfo.showOnlyIf, context) === false
    ) {
      continue;
    }

    let zodValidator = undefined;
    if ('validator' in fieldInfo! && fieldInfo.validator) {
      zodValidator = fieldInfo.validator;
    } else {
      // @ts-expect-error Hard to infer
      zodValidator = schemaByTable[keyMapped].shape[field];
    }
    if (typeof zodValidator === 'undefined') {
      throw new Error(`No validator found for ${path}$.${field}`);
    }

    // @ts-expect-error Hard to infer
    const rawFieldData = obj.data[field];

    const parsed = zodValidator.safeParse(rawFieldData);
    if (!parsed.success) {
      errors.push({
        ctx: `${path}.${field} (${rawFieldData})`,
        error: `Invalid value: ` + parsed.error.message,
        metaEntryIds: [`${keyMapped}|${obj.data.id}|${field}`],
      });
    }

    if (
      fieldInfo?.restrictToCodeAdemes &&
      !fieldInfo.restrictToCodeAdemes.some((code) => codesAdeme.has(code))
    ) {
      continue;
    }

    if (fieldInfo?.rules) {
      for (const rule of fieldInfo?.rules) {
        if (rule.minPhaseCondition) {
          if (
            PROJECT_PHASE_ORDERED_IDX_BY_PROJECT_PHASE[baseCtx.project.phase]! <
            PROJECT_PHASE_ORDERED_IDX_BY_PROJECT_PHASE[rule.minPhaseCondition]!
          ) {
            continue;
          }
        }
        if (rule.precondition) {
          if (evaluateExpression(rule.precondition, context) !== true) {
            continue;
          }
        }

        if (evaluateExpression(rule.check, context) !== true) {
          errors.push({
            ctx: `${path}.${field} (${rawFieldData})`,
            error: rule.label,
            metaEntryIds: [`${keyMapped}|${obj.data.id}|${field}`],
          });
        }
      }
    }
  }

  return {
    isValid: errors.length === 0,
    errors: errors,
  };
}

export function validateAll({
  project,
  benef,
  company,
  operations,
}: RemoveTableKeys<{
  project: TSharedProject;
  benef: TSharedBeneficiary;
  company: TSharedCompanyInstance;
  operations: TSharedOperation[];
}>): TValidateRes {
  if (operations.length === 0) {
    return {
      isValid: false,
      errors: [{ ctx: 'Project', error: 'Project has no operations' }],
    };
  }
  const baseCtx = { project, benef, company, operations };
  let errors: TValidateError[] = [];
  errors = errors.concat(
    validateObj({ key: 'project', data: baseCtx.project }, baseCtx).errors
  );
  errors = errors.concat(
    validateObj({ key: 'benef', data: baseCtx.benef }, baseCtx).errors
  );
  errors = errors.concat(
    validateObj(
      { key: 'company', data: baseCtx.company, path: 'company' },
      baseCtx
    ).errors
  );
  for (const op of operations) {
    errors = errors.concat(
      validateObj({ key: 'op', data: op }, baseCtx).errors
    );
  }

  if (!addressIsNotEmpty(project.siteAddress)) {
    errors.push({
      ctx: 'project.siteAddress',
      error: 'Addresse pas remplie',
      metaEntryIds: [
        `address|${project.siteAddress.id}|postcode`,
        `address|${project.siteAddress.id}|city`,
      ],
    });
  }
  if (!addressIsNotEmpty(benef.billingAddress)) {
    errors.push({
      ctx: 'benef.billingAddress',
      error: 'Addresse pas remplie',
      metaEntryIds: [
        `address|${benef.billingAddress.id}|postcode`,
        `address|${benef.billingAddress.id}|city`,
      ],
    });
  }
  if (project.housingOwnerStatus === 'OWNER_PRIMARY_RESIDENCE') {
    if (
      !addressesAreEqual(benef.billingAddress, project.siteAddress) &&
      project.glideChantierRowId === null
    ) {
      errors.push({
        ctx: 'project.siteAddress & benef.billingAddress',
        error:
          'Billing address must be the same as the site address if résidence principale',
      });
    }
  } else {
    if (addressesAreEqual(benef.billingAddress, project.siteAddress)) {
      errors.push({
        ctx: 'project.siteAddress & benef.billingAddress',
        error:
          'Billing address cannot be the same as the site address if not résidence principale',
      });
    }
  }

  return {
    isValid: errors.length === 0,
    errors: errors,
  };
}
