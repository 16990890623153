import BlurOn from '@mui/icons-material/BlurOn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Badge } from '@mui/joy';
import Stack from '@mui/joy/Stack';

import { AnnotStatus, TReviewInfo } from '@/types';

const ReviewedEntitiesSummary = ({
  reviewInfo,
}: {
  reviewInfo: TReviewInfo;
}) => (
  <Stack direction="row" gap={2}>
    <Badge
      badgeContent={reviewInfo?.stats[AnnotStatus.REJECTED]}
      color={'danger'}
    >
      <CancelIcon sx={{ fontSize: '1.5rem' }} />
    </Badge>
    <Badge
      badgeContent={reviewInfo?.stats[AnnotStatus.PENDING]}
      color={'warning'}
    >
      <BlurOn sx={{ fontSize: '1.5rem' }} />
    </Badge>
    <Badge
      badgeContent={reviewInfo?.stats[AnnotStatus.VALIDATED]}
      color={'success'}
    >
      <CheckRoundedIcon sx={{ fontSize: '1.5rem' }} />
    </Badge>
  </Stack>
);
export default ReviewedEntitiesSummary;
