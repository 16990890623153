import Card from '@mui/joy/Card';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';

import ReviewedEntitiesSummary from '@/components/Review/ReviewedEntitiesSummary.tsx';
import { TReviewInfo } from '@/types';

export function ReviewSummary({ reviewInfo }: { reviewInfo: TReviewInfo }) {
  const { t } = useTranslation();
  return (
    <Stack direction={'column'} gap={1} marginTop={1.5}>
      <Stack direction={'row'} gap={1}>
        <Typography level={'body-sm'}>
          {' '}
          {t('review.summary.header')}{' '}
        </Typography>
        {reviewInfo && <ReviewedEntitiesSummary reviewInfo={reviewInfo} />}
      </Stack>

      {reviewInfo?.rejectedEntities?.length > 0 && (
        <Card color={'danger'} variant={'soft'}>
          <List marker={'disc'}>
            {reviewInfo?.rejectedEntities?.map((el) => (
              <ListItem key={el.id}>
                <Typography>
                  {el.label}
                  {el.kind === 'missing'
                    ? t('review.summary.missingExtra')
                    : ''}
                  {el.rejectionReason !== '' ? ` : ${el.rejectionReason}` : ''}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Card>
      )}
    </Stack>
  );
}
