import { z } from 'zod';

import {
  TBoolExpression,
  TDynRule,
} from '@/abrico-lib-shared/dyn-expressions/schemas';

import { ECodeAdemeType, EnumByEnumName } from '../generated/prisma-zod';
import LABELS from './labels-fr.json';

export enum ENumberUnit {
  INT_PERC = 'INT_PERC',
  CENTS_EUR = 'CENTS_EUR',
  EUROS = 'EUROS',
  MILIMITIERS = 'MILIMITIERS',
  METER_SQUARED = 'METER_SQUARED',
  METER_CUBED = 'METER_CUBED',
  WATT = 'WATT',
  LITTER = 'LITTER',
  MILLIGRAMS_PER_NORMAL_CUBIC_METER = 'MILLIGRAMS_PER_NORMAL_CUBIC_METER',
  KILOWATT = 'KILOWATT',
  WATT_PER_METER_SQUARED = 'WATT_PER_METER_SQUARED',
  SQUARE_METER_KELVIN_PER_WATT = 'SQUARE_METER_KELVIN_PER_WATT',
  CELSIUS = 'CELSIUS',
}

export type TFieldInfoEnum = { enumName: keyof typeof EnumByEnumName };
export type TFieldInfoNumber = {
  typeInfo?: {
    unit?: ENumberUnit;
    min?: number;
    max?: number;
    precision?: number;
  };
};
export type TFieldInfoString = {
  typeInfo?: {
    format?: 'text' | 'phone' | 'mobile-phone' | 'email' | 'siret';
  };
};
export type TFieldTypeInfo =
  | TFieldInfoEnum
  | TFieldInfoNumber
  | TFieldInfoString;

export type TTable =
  | 'address'
  | 'project'
  | 'operation'
  | 'companyInstance'
  | 'beneficiary'
  | 'documentVersion'
  | 'document'
  | 'taxAssessment'
  | 'formTracker'
  | 'mprSpecialWorks';

export type TModelInfo<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  O extends z.ZodObject<any>,
  T extends TTable | null = null,
> = (T extends null
  ? { fields?: undefined; _table?: undefined }
  : {
      _table: T;
      fields: {
        // @ts-expect-error bad inference null not possible
        [K in keyof O['shape']]?: K extends keyof (typeof LABELS)['fields'][T]
          ? {} & (O['shape'][K] extends  // eslint-disable-next-line @typescript-eslint/no-explicit-any
              | z.ZodEnum<any>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              | z.ZodNullable<z.ZodEnum<any>>
              ? TFieldInfoEnum
              : O['shape'][K] extends z.ZodNumber | z.ZodNullable<z.ZodNumber> // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ? TFieldInfoNumber
                : O['shape'][K] extends z.ZodString | z.ZodNullable<z.ZodString> // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ? TFieldInfoString
                  : {}) &
              ((
                | {
                    readOnly: true;
                  }
                | {
                    readOnly?: false;
                  }
              ) & {
                restrictToCodeAdemes?: readonly [
                  ECodeAdemeType,
                  ...ECodeAdemeType[],
                ];
                showOnlyIf?: TBoolExpression;
                // Rules applied to the field
                rules?: TDynRule[];
              })
          : {
              // Hacky way to make compilation fail if not translation is set
              missingLabel: 'Do not write this, but please provide label in translation file.';
            };
      };
    }) & {
  relatedFields?: Record<
    string,
    {
      label: string;
      kind:
        | 'address'
        | 'company'
        | 'taxAssessment'
        | 'documentVersion'
        | 'document'
        | 'project'
        | 'operation'
        | 'form'
        | 'benef'
        | 'mprSpecialWorks';
      validator: // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | z.ZodObject<any>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | z.ZodNullable<z.ZodObject<any>>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | z.ZodRecord<z.ZodString, z.ZodObject<any>>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        | z.ZodArray<any>;
      restrictToCodeAdemes?: undefined;
      showOnlyIf?: TBoolExpression;
      rules?: TDynRule[];
    } & (
      | { readOnly: true; sourceDocs?: undefined; outDocs?: undefined }
      | {
          readOnly?: false;
        }
    )
  >;
};

export type InfoToZodObject<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  U extends z.ZodObject<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TM extends TModelInfo<any, any>,
> = z.ZodObject<
  (TM['fields'] extends undefined
    ? {}
    : {
        [k in keyof TM['fields']]: U['shape'][k];
      }) &
    (TM['_table'] extends string
      ? { id: z.ZodString; _table: z.ZodLiteral<TM['_table']> }
      : {}) &
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (TM['relatedFields'] extends Record<string, any>
      ? {
          [k in keyof TM['relatedFields']]: TM['relatedFields'][k]['validator'];
        }
      : {})
>;

// Generates the possible paths inside the data exchange format
type _DeepKeys<T> = T extends (infer U)[]
  ? `[idx]${_DeepKeys<U>}`
  : T extends { id: string }
    ? {
        [K in string & keyof T]: K extends '_table' | 'id'
          ? never
          : T[K] extends Date
            ? `.${K}`
            : T[K] extends object
              ? `${`.${K}`}${_DeepKeys<T[K]>}`
              : `.${K}`;
      }[string & keyof T]
    : T extends Record<string, infer U>
      ? `[id]${_DeepKeys<U>}`
      : never;

type DropInitDot<T> = T extends `.${infer U}` ? U : T;
export type DeepKeys<T> = DropInitDot<_DeepKeys<T>>;

export type RemoveTableKeys<T> = {
  // eslint-disable-next-line
  [K in keyof T as K extends '_table' ? never : K]: T[K] extends any[]
    ? RemoveTableKeys<T[K][0]>[]
    : T[K] extends object
      ? RemoveTableKeys<T[K]>
      : T[K];
};
