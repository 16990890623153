import Card from '@mui/joy/Card';
import CircularProgress from '@mui/joy/CircularProgress';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAttestationsInfo } from '@/cloudFunctions/functions.ts';
import AttestationsTable from '@/components/AttestationRGE/AttestationsTable.tsx';
import SelectCodeAdeme, {
  ICodeAdeme,
} from '@/components/UI/SelectCodeAdeme.tsx';
import { useDeepEffect } from '@/hooks/useDeepEffect.ts';
import { queryClient } from '@/queries';
import { CODE_ADEME } from '@/utils/constants.ts';

const queryAttestations = (siret: string, codes_ademe: string[]) => {
  const queryFunction = async () => {
    return (
      await getAttestationsInfo({
        siret,
        codes_ademe,
      })
    ).data;
  };

  return queryOptions({
    queryKey: ['attestations'],
    queryFn: queryFunction,
  });
};

const invalidateAttestation = debounce(() => {
  queryClient.invalidateQueries({ queryKey: ['attestations'] });
}, 500);

export default function AttestationsFinder({
  initialSiret,
  initialCodesAdeme,
  onClickAddToPDF,
}: {
  initialSiret: string;
  initialCodesAdeme: string[];
  onClickAddToPDF?: (pdfBytes: Uint8Array) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [siret, setSiret] = useState(initialSiret);
  const [codesAdeme, setCodesAdeme] = useState<ICodeAdeme[]>(() =>
    CODE_ADEME.filter((code) => initialCodesAdeme.includes(code.code))
  );

  useDeepEffect(
    () => {
      invalidateAttestation();
    },
    [siret, codesAdeme],
    true
  );

  const { error, data, isFetching } = useQuery(
    queryAttestations(
      siret,
      codesAdeme.map((el) => el.code)
    )
  );

  return (
    <Stack alignItems={'center'} width={'100%'}>
      <Stack padding={2} gap={2} sx={{ maxWidth: 600 }}>
        <FormControl>
          <FormLabel>{t('attestationRge.finder.siret')}</FormLabel>
          <Input
            placeholder={t('attestationRge.finder.siret_placeholder')}
            value={siret}
            onChange={(e) => {
              setSiret(e.target.value);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t('attestationRge.finder.codes_ademe')}</FormLabel>
          <SelectCodeAdeme
            selectedCodes={codesAdeme}
            setSelectedCodes={setCodesAdeme}
          />
        </FormControl>

        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography level={'title-lg'}>
            {t('attestationRge.finder.results')}
          </Typography>
          {isFetching ? (
            <CircularProgress size={'sm'} />
          ) : (
            <Typography>
              {data?.found && data.company_name ? data!.company_name : ''}
            </Typography>
          )}
        </Stack>
        {error ? (
          <Typography color={'danger'}>
            {t('attestationRge.finder.error_occurred')}
          </Typography>
        ) : (
          <>
            {!isFetching && data && (
              <>
                {data.found ? (
                  <>
                    <Card>
                      <Typography level={'title-md'}>
                        {t('attestationRge.finder.attestations_linked')}
                      </Typography>
                      <AttestationsTable
                        attestations={data.matched}
                        onClickAddToPDF={onClickAddToPDF}
                      />
                    </Card>
                    <Card>
                      <Typography level={'title-md'}>
                        {t('attestationRge.finder.other_attestations')}
                      </Typography>
                      <AttestationsTable
                        attestations={data.others}
                        onClickAddToPDF={onClickAddToPDF}
                      />
                    </Card>
                  </>
                ) : (
                  <>
                    <Typography>
                      {t('attestationRge.finder.no_attestation_found')}
                    </Typography>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}
