import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PostAddIcon from '@mui/icons-material/PostAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonGroup, Tooltip, Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import { getBytes, getDownloadURL, getStorage, ref } from 'firebase/storage';
import { uniq } from 'lodash';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'underscore';

import app from '@/config/firebaseInit.ts';
import { TAttestation } from '@/types';
import { getPDFNet } from '@/utils/apryze.ts';

const isPdf = (attestation: { content_type: string | null }) => {
  return (
    attestation.content_type === 'application/pdf' ||
    attestation.content_type === 'application/octet-stream'
  );
};
export default function AttestationsTable({
  attestations,
  onClickAddToPDF,
}: {
  attestations: TAttestation[];
  onClickAddToPDF?: (pdfBytes: Uint8Array) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [actionInProgress, setActionInProgress] = useState(false);

  const handleOpenInTab = useCallback(
    async (attestation: { gcs_bucket: string; gcs_uri: string }) => {
      setActionInProgress(true);
      try {
        const url = await getDownloadURL(
          ref(
            getStorage(app, `gs://${attestation.gcs_bucket}`),
            attestation.gcs_uri!
          )
        );
        window.open(url, '_blank');
      } catch (e) {
        console.error(e);
        enqueueSnackbar(t('attestationRge.table.error_occured'), {
          variant: 'error',
        });
      } finally {
        setActionInProgress(false);
      }
    },
    [t]
  );

  const handleDownloadAsPdf = useCallback(
    async (attestation: { gcs_bucket: string; gcs_uri: string }) => {
      setActionInProgress(true);
      const notification = enqueueSnackbar(
        t('attestationRge.table.downloading'),
        {
          variant: 'info',
        }
      );
      const PDFNet = await getPDFNet();

      try {
        const res = await getBytes(
          ref(
            getStorage(app, `gs://${attestation.gcs_bucket}`),
            attestation.gcs_uri!
          )
        );
        const data = new Uint8Array(res);
        await PDFNet.PDFDoc.createFromBuffer(data.slice());
        if (onClickAddToPDF) {
          await onClickAddToPDF(data);
        }
      } catch (e) {
        // @ts-ignore
        if ('type' in e && e.type === 'PDFWorkerError') {
          enqueueSnackbar(t('attestationRge.table.document_processing_error'), {
            variant: 'error',
          });
        } else {
          console.error(e);
          enqueueSnackbar(t('attestationRge.table.error_occured'), {
            variant: 'error',
          });
        }
      } finally {
        closeSnackbar(notification);
        setActionInProgress(false);
      }
    },
    [onClickAddToPDF, t]
  );

  const attestationdCleaned = useMemo(
    () =>
      Object.entries(groupBy(attestations, 'url_qualification')).map(
        ([url, attestations]) => ({
          domaines: uniq(
            attestations.map((attestation) => attestation.domaine)
          ),
          url_qualification: url,
          id: attestations[0].id,
          content_type: attestations[0].content_type,
          gcs_uri: attestations[0].gcs_uri,
          gcs_bucket: attestations[0].gcs_bucket,
        })
      ),
    [attestations]
  );

  if (attestations.length === 0) {
    return (
      <Typography level={'body-sm'}>
        {t('attestationRge.table.no_attestations')}
      </Typography>
    );
  }

  return (
    <Table aria-label="Attestations RGE">
      <thead>
        <tr>
          <th>{t('attestationRge.table.header.domain')}</th>
          <th style={{ width: 44 * 3 }}>
            {t('attestationRge.table.header.actions')}
          </th>
        </tr>
      </thead>
      <tbody>
        {attestationdCleaned.map((attestation) => (
          <tr key={attestation.id}>
            <td
              dangerouslySetInnerHTML={{
                __html: attestation.domaines.join('<br>'),
              }}
            />
            <td>
              <ButtonGroup>
                <Tooltip
                  title={t('attestationRge.table.view_original_attestation')}
                >
                  <IconButton
                    disabled={
                      actionInProgress || !attestation.url_qualification
                    }
                    onClick={() => {
                      window.open(attestation.url_qualification, '_blank');
                    }}
                  >
                    <InsertLinkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('attestationRge.table.view_stored_version')}>
                  <IconButton
                    variant={'solid'}
                    disabled={
                      actionInProgress ||
                      !attestation.gcs_uri ||
                      !isPdf(attestation)
                    }
                    // @ts-ignore
                    onClick={() => handleOpenInTab(attestation)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                {onClickAddToPDF && (
                  <Tooltip
                    title={t('attestationRge.table.add_to_current_document')}
                  >
                    <IconButton
                      variant={'solid'}
                      disabled={
                        actionInProgress ||
                        !attestation.gcs_uri ||
                        !isPdf(attestation)
                      }
                      // @ts-ignore
                      onClick={() => handleDownloadAsPdf(attestation)}
                    >
                      <PostAddIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
