import { Edit, Info, Warning } from '@mui/icons-material';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import ReviewKindSelect from '@/components/Chantier/ReviewKindSelect.tsx';
import { useChantierState } from '@/stores/chantierStore.ts';
import { availableReviewKindsByProjectPhase } from '@/types/review.ts';

export default function ReviewKindInfo() {
  const { t } = useTranslation();

  const phase = useChantierState(
    useShallow((state) => state.metaDossierForChantier?.phase)
  );
  const reviewKind = useChantierState((state) => state.currentReviewKind);
  const availableReviewKinds =
    availableReviewKindsByProjectPhase[phase ?? 'COMMERCIAL_INIT'];
  const [selectReviewKindOpen, setSelectReviewKindOpen] = useState(false);

  useEffect(() => {
    if (reviewKind && !availableReviewKinds.includes(reviewKind)) {
      useChantierState.getState().setCurrentReviewKind(null);
    }
    if (reviewKind === null && availableReviewKinds.length === 1) {
      useChantierState.getState().setCurrentReviewKind(availableReviewKinds[0]);
    }
  }, [availableReviewKinds, reviewKind]);

  if (!phase) {
    return <></>;
  }
  if (availableReviewKinds.length === 0) {
    return (
      <Chip variant="solid" startDecorator={<Warning />} color={'warning'}>
        Revue hors process !
      </Chip>
    );
  }

  return (
    <Stack direction={'row'} gap={1}>
      {reviewKind ? (
        <Chip variant="solid" startDecorator={<Info />} color={'success'}>
          {t(`review.reviewKind.${reviewKind}`)}
        </Chip>
      ) : (
        <Chip variant="solid" startDecorator={<Warning />} color={'danger'}>
          Revue non sélectionnée
        </Chip>
      )}

      <Tooltip title={'Sélectionner le type de revue'}>
        {/* @ts-ignore */}
        <IconButton size={'xs'} onClick={() => setSelectReviewKindOpen(true)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <ReviewKindSelect
        open={selectReviewKindOpen}
        setOpen={setSelectReviewKindOpen}
      />
    </Stack>
  );
}
