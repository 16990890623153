import { Warning } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Tooltip } from '@mui/joy';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { updateAbricoInfo } from '@/cloudFunctions/functions.ts';
import EndReviewDialog from '@/components/Chantier/EndReviewDialog.tsx';
import ReviewKindSelect from '@/components/Chantier/ReviewKindSelect.tsx';
import i18n from '@/i18n.ts';
import { queryClient } from '@/queries';
import { useAppState } from '@/stores/appStore.ts';
import { useChantierState } from '@/stores/chantierStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { availableReviewKindsByProjectPhase } from '@/types/review.ts';
import { buildAbricoDataForSave } from '@/utils/buildAbricoDataForSave.ts';
import { saveDocumentToCloud } from '@/utils/save.ts';

const goNextDoc = () => {
  const { phaseDocs } = useChantierState.getState().computedChantierDocs;
  const urlParams = useAppState.getState().urlParams;

  const navigate = useAppState.getState().navigate;

  const nextDoc = phaseDocs.find(
    (_, idx, arr) => arr[idx - 1]?.dossierId === urlParams.dossierId
  );
  if (nextDoc) {
    navigate(nextDoc.url);
  } else {
    // We do not handle emails from station anymore
    // navigate(
    //   `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}/email`
    // );
  }
};

export function MasterNavigation() {
  const { t } = useTranslation();
  const hasChangesToSave = useAppState((state) => state.hasChangesToSave);
  const urlParams = useAppState((state) => state.urlParams);
  const hasNextDoc = useChantierState(
    useShallow(
      (state) =>
        !!state.computedChantierDocs.phaseDocs.find(
          (_, idx, arr) => arr[idx - 1]?.dossierId === urlParams.dossierId
        )
    )
  );
  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const metaDossierForChantier = useChantierState(
    useShallow((state) => state.metaDossierForChantier)
  );
  const phase = metaDossierForChantier?.phase;
  const hasPendingDocVersionReviews = metaDossierForChantier?.documents.some(
    (doc) => doc.dossierId && doc.isValid === null
  );

  const availableReviewKinds =
    availableReviewKindsByProjectPhase[phase ?? 'COMMERCIAL_INIT'];

  const reviewKind = useChantierState((state) => state.currentReviewKind);
  const needToSelectReviewKind =
    reviewKind === null && availableReviewKinds.length > 0;
  const [selectReviewKindOpen, setSelectReviewKindOpen] = useState(false);

  const [openEndReview, setOpenEndReview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const saveEverything = useCallback(async () => {
    const interactionsState = useInteractionsState.getState();
    const chantierState = useChantierState.getState();
    if (!chantierState.metaDossierForChantier) {
      throw new Error('Not possible at this point');
    }
    const notification = enqueueSnackbar(
      i18n.t('sync.notifications.save.inProgress'),
      {
        variant: 'info',
        autoHideDuration: null,
      }
    );

    interactionsState.enableReadOnlyMode(false);
    setIsSaving(true);
    try {
      if (useAppState.getState().hasEntitiesRelatedChangesToSave) {
        await saveDocumentToCloud({ notify: false, handleReadonlyMode: false });
      }

      if (
        useAppState.getState().hasCRMDossierRelatedChangesToSave ||
        useAppState.getState().hasCRMChantierRelatedChangesToSave
      ) {
        await updateAbricoInfo({
          formId: null,
          isEndOfFormSave: true,
          mainObject: {
            kind: 'project',
            id: chantierState.metaDossierForChantier.projectId,
            // Will be set by backend
            onSave: [],
          },
          objects: buildAbricoDataForSave(),
        });
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['dossier', 'meta'],
          }),
          queryClient.invalidateQueries({
            queryKey: ['chantier', 'meta'],
          }),
        ]);
        useAppState.getState().setHasCRMDossierRelatedChangesToSave(false);
        useAppState.getState().setHasCRMChantierRelatedChangesToSave(false);
        enqueueSnackbar(i18n.t('sync.notifications.save.success'), {
          variant: 'success',
        });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(i18n.t('sync.notifications.save.failed'), {
        variant: 'error',
      });
    } finally {
      closeSnackbar(notification);
      interactionsState.disableReadOnlyMode();
      setIsSaving(false);
    }
  }, [urlParams.chantierId, urlParams.dossierId]);

  return (
    <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'center'}>
      {needToSelectReviewKind ? (
        <Button
          disabled={isInReadOnlyMode}
          onClick={() => setSelectReviewKindOpen(true)}
        >
          <Warning /> Sélectionner le type de revue
        </Button>
      ) : (
        <></>
      )}
      <Button
        disabled={
          isInReadOnlyMode || !hasChangesToSave || needToSelectReviewKind
        }
        onClick={saveEverything}
        loading={isSaving}
      >
        {t('ui.save')}
        {reviewKind === null && availableReviewKinds.length > 0 ? (
          <Warning />
        ) : (
          <></>
        )}
      </Button>
      <Tooltip
        title={
          needToSelectReviewKind
            ? "Veuillez d'abord sélectionner le type de revue en cours"
            : hasChangesToSave
              ? 'Il y a des modifications à sauvegarder'
              : hasPendingDocVersionReviews
                ? "Certains documents n'ont pas encore été revus"
                : ''
        }
      >
        <div>
          <Button
            disabled={
              isInReadOnlyMode ||
              hasChangesToSave ||
              hasPendingDocVersionReviews ||
              needToSelectReviewKind
            }
            onClick={() => setOpenEndReview(true)}
          >
            Terminer la revue
          </Button>
          <EndReviewDialog open={openEndReview} setOpen={setOpenEndReview} />
        </div>
      </Tooltip>

      <IconButton
        variant={'solid'}
        color={'primary'}
        disabled={hasChangesToSave || !hasNextDoc}
        onClick={goNextDoc}
      >
        <NavigateNextIcon />
      </IconButton>
      <ReviewKindSelect
        isBeforeSave={true}
        open={selectReviewKindOpen}
        setOpen={setSelectReviewKindOpen}
      />
    </Stack>
  );
}
