import FiberNewIcon from '@mui/icons-material/FiberNew';
import RestoreIcon from '@mui/icons-material/Restore';
import Checkbox from '@mui/joy/Checkbox';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import IconByStatus from '@/components/Review/IconStatus';
import { useInteractionsState } from '@/stores/interactionsStore.ts';

export default function ReviewStatusSelect() {
  const { t } = useTranslation();

  const {
    reviewStatus,
    setReviewStatus,
    remoteReviewStatus,
    isInReadOnlyMode,
    setrejectedByKE,
    rejectedByKE,
  } = useInteractionsState(
    useShallow((state) => ({
      reviewStatus: state.reviewStatus,
      setReviewStatus: state.setReviewStatus,
      remoteReviewStatus: state.remoteReviewStatus,
      isInReadOnlyMode: state.isInReadOnlyMode,
      rejectedByKE: state.rejectedByKE,
      setrejectedByKE: state.setrejectedByKE,
    }))
  );

  return (
    <Stack direction={'row'} alignItems={'center'} gap={1}>
      <Typography level={'body-sm'}>{t('review.statusPrefix')}</Typography>
      <Select
        size={'sm'}
        disabled={isInReadOnlyMode}
        value={reviewStatus}
        onChange={(_e, value) => setReviewStatus(value!)}
        startDecorator={IconByStatus[reviewStatus!]!}
      >
        {(['pending', 'invalid', 'valid'] as const).map((status) => (
          <Option key={status} value={status}>
            {IconByStatus[status]} {t(`review.status.${status}`)}{' '}
            {status === remoteReviewStatus && <RestoreIcon />}
          </Option>
        ))}
      </Select>
      {reviewStatus === 'invalid' && (
        <Checkbox
          size="sm"
          label={'Rejeté par KE ?'}
          checked={rejectedByKE ?? false}
          onChange={(event) => {
            setrejectedByKE(event.target.checked);
          }}
        />
      )}
      {remoteReviewStatus &&
        reviewStatus &&
        remoteReviewStatus !== reviewStatus && <FiberNewIcon />}
    </Stack>
  );
}
