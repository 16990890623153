import { Core, WebViewerInstance } from '@pdftron/webviewer';

import { TEntityForDisplay } from '@/schemas/analysis.ts';
import { EStationDocType } from '@/schemas/types.ts';
import { EStationDocTypesByFileByPage } from '@/types';

/**
 * Function to remap the page numbers based on the desired order docTypes.
 */
export const getPageNumbersMapping = (
  docTypesByFileByPage: EStationDocTypesByFileByPage,
  desiredOrderByType: Record<EStationDocType, number>
): Map<number, number> => {
  const remappedPageNumbers: Map<number, number> = new Map();
  let currPage = 1;

  // we check that we haven't forgotten to configure a type
  const unConfiguredDocTypes = Object.keys(docTypesByFileByPage).filter(
    (docType) => !(docType in desiredOrderByType)
  );
  if (unConfiguredDocTypes.length > 0) {
    console.error(
      `The following docTypes are not configured in desiredOrderByType: ${unConfiguredDocTypes.join(
        ', '
      )}`
    );
  }

  Object.entries(desiredOrderByType)
    // we keep only the types that exists in the dossier
    .filter(([type]) => type in docTypesByFileByPage)
    // we sort the types on the desired order
    .sort(([, targetIdxA], [, targetIdxB]) => targetIdxA - targetIdxB)
    // For each type we add the relevant pages
    .forEach(([type]) => {
      const pagesByDocument = docTypesByFileByPage[type as EStationDocType]!;
      // For each (document, pages) associated with that we add the relevant pages,
      // by changing the order as little as possible
      Object.entries(pagesByDocument)
        // we sort the (documents, pages) by the minimum page number
        .sort(
          ([, pagesA], [, pagesB]) => Math.min(...pagesA) - Math.min(...pagesB)
        )
        .forEach(([, pages]) => {
          pages.forEach((page) => {
            remappedPageNumbers.set(page, currPage);
            currPage++;
          });
        });
    });
  return remappedPageNumbers;
};

export const reorderDocumentPages = async (
  doc: Core.PDFNet.PDFDoc,
  remappedPageNumbers: Map<number, number>,
  instance: WebViewerInstance
): Promise<Core.PDFNet.PDFDoc> => {
  // we reorder the pages only if the order has changed
  if (
    [...remappedPageNumbers.entries()].some(
      ([oldPage, newPage]) => oldPage !== newPage
    )
  ) {
    const newPageOrder = [...remappedPageNumbers.entries()]
      .sort(
        ([, newPageNumberA], [, newPageNumberB]) =>
          newPageNumberA - newPageNumberB
      )
      .map(([oldPageNumber]) => oldPageNumber);

    // Note that this function is made with Core.PDFNet.PDFDoc, there are other documents API
    // We create a new empty document where we will put the pages in the desired order
    const outDoc = await instance.Core.PDFNet.PDFDoc.create();

    // We create a page set (note that is note "set" in the mathematical sense),
    // it's more like list actually.
    const pageSet = await instance.Core.PDFNet.PageSet.create();
    // We add the pages to the page set in the desired order
    newPageOrder.forEach((page) => pageSet.addPage(page));

    // Finally, we insert in the new document the pages from "doc" in the desired order
    await outDoc.insertPageSet(
      0,
      doc,
      pageSet,
      // We don't care of bookmarks
      instance.Core.PDFNet.PDFDoc.InsertFlag.e_none
    );
    return outDoc;
  } else {
    return doc;
  }
};

export const remapAnnotationsPages = (
  annotations: TEntityForDisplay[],
  remappedPageNumbers: Map<number, number>
): TEntityForDisplay[] =>
  annotations
    .filter((el) => remappedPageNumbers.has(el.pageNumber))
    .map(
      (annotation) =>
        ({
          ...annotation,
          pageNumber: remappedPageNumbers.get(annotation.pageNumber),
        }) as TEntityForDisplay
    );

export const remapDocTypesByFileByPage = (
  docTypesByFileByPage: EStationDocTypesByFileByPage,
  remappedPageNumbers: Map<number, number>
): EStationDocTypesByFileByPage => {
  const out: EStationDocTypesByFileByPage = {};
  for (const [docType, pagesByDocKey] of Object.entries(docTypesByFileByPage)) {
    const pagesByDocKeyOut: Record<string, number[]> = {};
    for (const [docKey, pages] of Object.entries(pagesByDocKey)) {
      const newPages = pages
        .filter((page) => remappedPageNumbers.has(page))
        .map((page) => remappedPageNumbers.get(page)!);
      if (newPages.length > 0) {
        pagesByDocKeyOut[docKey] = newPages;
      }
    }

    if (Object.keys(pagesByDocKeyOut).length > 0) {
      out[docType as EStationDocType] = pagesByDocKeyOut;
    }
  }

  return out;
};

export const reOrderDocTypes = (
  docTypes: EStationDocType[],
  docTypeOrderInOutline: Record<EStationDocType, number>
) => {
  return docTypes.sort(
    (a: EStationDocType, b: EStationDocType) =>
      docTypeOrderInOutline[a] - docTypeOrderInOutline[b]
  );
};
