import { Modal, ModalDialog, Select, Typography, Option } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useChantierState } from '@/stores/chantierStore.ts';
import {
  availableReviewKindsByProjectPhase,
  EReviewKind,
} from '@/types/review.ts';

export default function ReviewKindSelect({
  open,
  setOpen,
  isBeforeSave,
}: {
  isBeforeSave?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation(['common', 'shared']);
  const phase = useChantierState(
    useShallow((state) => state.metaDossierForChantier?.phase)
  );
  const availableReviewKinds =
    availableReviewKindsByProjectPhase[phase ?? 'COMMERCIAL_INIT'];
  const reviewKind = useChantierState((state) => state.currentReviewKind);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog>
        <ModalClose />
        {isBeforeSave ? (
          <Typography>
            Avant de sauvagerder, sélection le type de revue que tu es en train
            d&apos;effectuer.
          </Typography>
        ) : (
          <></>
        )}
        <Typography marginRight={4}>
          Revue en cours :{' '}
          {reviewKind ? t(`review.reviewKind.${reviewKind}`) : <i>Aucune</i>}
        </Typography>
        {availableReviewKinds.length === 0 ? (
          <>
            <Typography>Revue hors process !</Typography>
            <Typography level={'body-sm'}>
              {' '}
              Normalement aucune revue n&apos;est possible pendant la phase{' '}
              {t(`shared:enums.EProjectPhase.${phase}`)}.
            </Typography>
          </>
        ) : (
          <>
            <Typography>Sélectionner le type de revue en cours:</Typography>
            <Select
              defaultValue={reviewKind}
              onChange={(_, newValue) =>
                useChantierState
                  .getState()
                  .setCurrentReviewKind(newValue as EReviewKind | null)
              }
            >
              {availableReviewKinds.map((kind) => (
                <Option value={kind} key={kind}>
                  {t(`common:review.reviewKind.${kind}`)}
                </Option>
              ))}
            </Select>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
}
