import { LibraryAddCheckRounded } from '@mui/icons-material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Textarea, Tooltip } from '@mui/joy';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import copy from 'copy-to-clipboard';
import * as React from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { infoByAnnotStatus } from '@/components/Entity/annotStatusConstants.tsx';
import EntityHistory from '@/components/Entity/EntityHistory.tsx';
import EntityStatusButtons from '@/components/Entity/EntityStatusButtons.tsx';
import { SyncEntityEl } from '@/components/Sync/SyncEntityEl.tsx';
import { TEntityForDisplay, TPostProcessedEntity } from '@/schemas/analysis.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { usePersistedStore } from '@/stores/persistedStore.ts';
import { useUiState } from '@/stores/uiStore.ts';
import { AnnotStatus, IMissingEntity } from '@/types';
import { drawHighlightAnnotation } from '@/utils/annotations.ts';
import { checkElementIsVisible } from '@/utils/interactions.ts';

const EntityCard = ({
  entity,
  scrollOffset,
  entityGlobalAutoSelectRef,
  isForcedVisible,
}: {
  entity: TEntityForDisplay | TPostProcessedEntity | IMissingEntity;
  scrollOffset: number;
  entityGlobalAutoSelectRef: React.MutableRefObject<() => void>;
  isForcedVisible: boolean;
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const transformedTextRef = useRef<HTMLDivElement>(null);
  const company = useAppState((state) => state.company);

  const hideReviewedEntitiesSettings = usePersistedStore(
    (state) => state.hideReviewedEntitiesSettings
  );
  const status = useInteractionsState(
    useShallow((state) => state.getEntityStatus(entity.id))
  );
  const isPartOfSearchResult = useInteractionsState(
    useShallow(
      (state) =>
        state.entitiesMatched === null || state.entitiesMatched.has(entity.id)
    )
  );

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const isFocused = useUiState(
    useShallow((state) => state.focusedEntityId === entity.id)
  );

  const { setEntityRejectionReason, rejectionReason } = useInteractionsState(
    useShallow(({ getEntityRejectionReason, setEntityRejectionReason }) => ({
      setEntityRejectionReason,
      rejectionReason: getEntityRejectionReason(entity.id),
    }))
  );

  const [copied, setCopied] = useState<boolean>(false);

  const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleClickOnEntity = useCallback(() => {
    if (entity.kind !== 'extracted') return;

    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      deleteAnnotation(`${entity.id}-highlight`);
    }

    createHighlightAnnotation(
      entity.id,
      AnnotStatus.PENDING,
      entity.transformedText
    ).then(() => {
      clickTimeout.current = setTimeout(() => {
        deleteAnnotation(`${entity.id}-highlight`);
      }, 2000);
    });
    return () => {
      if (clickTimeout.current) clearTimeout(clickTimeout.current);
    };
  }, [entity]);

  const handleClickCopy = useCallback(async () => {
    if (entity.kind === 'missing') return;
    copy(entity.transformedText);
    setCopied(true);
  }, [setCopied, entity]);

  const copyTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (copied) {
      if (copyTimeout.current) clearTimeout(copyTimeout.current);
      copyTimeout.current = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => {
        if (copyTimeout.current) clearTimeout(copyTimeout.current);
      };
    }
  }, [copied]);

  const autoSelect = useCallback(() => {
    if (isFocused) {
      // We don't want to autofocus when clicking on sync sub item
      if (!useUiState.getState().autoFocus) return;
      if (transformedTextRef.current) {
        const input =
          transformedTextRef.current.getElementsByTagName('textarea')[0];
        input.focus();
        input.select();
      } else {
        // we focus the root to avoid copying a previous textarea (this happens when
        // there are missing entities)
        rootRef.current?.focus();
      }
    }
  }, [isFocused]);

  useEffect(() => {
    // We focus the input to enable for user copy as
    // copy doesn't play nice with keyboard events in Firefox
    autoSelect();
    if (isFocused) {
      // We also forward the autoFocus function to Station
      entityGlobalAutoSelectRef.current = autoSelect;
    }
  }, [entityGlobalAutoSelectRef, isFocused, autoSelect]);

  useEffect(() => {
    if (!rootRef.current) return;

    if (isFocused && !checkElementIsVisible(rootRef.current, scrollOffset)) {
      if (useUiState.getState().autoFocus) {
        rootRef.current?.focus();
      }
      rootRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      if (transformedTextRef.current && useUiState.getState().autoFocus) {
        transformedTextRef.current.getElementsByTagName('textarea')[0].focus();
      }
    }
    if (!isFocused) {
      deleteAnnotation(`${entity.id}-highlight`);
    } else {
      handleClickOnEntity();
    }
  }, [isFocused, handleClickOnEntity, entity.id, scrollOffset]);

  const handleSetStatus = useCallback(
    (newStatus: AnnotStatus) => {
      useInteractionsState
        .getState()
        .setEntityStatus(entity.id, newStatus, true);
    },
    [entity.id]
  );

  const updateAnnotation = useCallback(
    async (newStatus: AnnotStatus) => {
      const instance = await useAppState.getState().getInstance();
      if (!instance || entity.kind !== 'extracted') return;

      const { annotationManager } = instance.Core;
      const create = () => {
        createHighlightAnnotation(entity.id, newStatus, entity.transformedText);
      };
      if (newStatus === AnnotStatus.VALIDATED) {
        deleteAnnotation(`${entity.id}-rejection`);
        if (!annotationManager.getAnnotationById(`${entity.id}-validation`)) {
          create();
        }
      } else if (newStatus === AnnotStatus.REJECTED) {
        deleteAnnotation(`${entity.id}-validation`);
        if (!annotationManager.getAnnotationById(`${entity.id}-rejection`)) {
          create();
        }
      } else if (newStatus === AnnotStatus.PENDING) {
        deleteAnnotation(`${entity.id}-rejection`);
        deleteAnnotation(`${entity.id}-validation`);
      }
    },
    [entity]
  );

  useEffect(() => {
    updateAnnotation(status);
    // DO NOT ADD anything here
  }, [status]);

  let backgroundColor = '#22252b';
  if (entity.kind === 'missing') {
    backgroundColor =
      status === AnnotStatus.VALIDATED || status === AnnotStatus.REJECTED
        ? '#22252b'
        : 'var(--joy-palette-warning-softBg)';
  }

  if (
    !isPartOfSearchResult ||
    (!isFocused &&
      hideReviewedEntitiesSettings &&
      status !== AnnotStatus.PENDING &&
      !isForcedVisible)
  ) {
    return <></>;
  }

  return (
    <Box
      ref={rootRef}
      tabIndex={0}
      className="entity-card"
      // hacky way to make scrollIntoView work nicely with the sticky header
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@container (max-width: 500px)': {
          flexDirection: 'column',
        },
        scrollMarginTop: `${scrollOffset}px`,
        scrollMarginBottom: `${scrollOffset}px`,
        borderStyle: isFocused ? 'solid' : 'dashed',
        borderWidth: '2px',
        borderRadius: '6px',
        padding: 1,
        marginTop: 0.2,
        marginBottom: 0.2,
        width: '100%',
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
        borderColor: isFocused ? '#FF784F' : 'transparent',

        '&:hover': {
          borderColor: isFocused ? '#FF784F' : '#9c4c31',
        },
        backgroundColor,
      }}
      data-target-page={'pageNumber' in entity ? entity.pageNumber : ''}
      data-entity-id={entity.id}
      onClick={(e) => {
        useUiState.getState().setFocusedEntityId(entity.id);
        handleClickOnEntity();
        // Debug helper
        if (e.detail === 2) console.debug(entity);
      }}
    >
      <Stack
        flexDirection={'row'}
        justifyContent="space-between"
        alignItems="start"
        sx={{
          '@container (max-width: 550px)': {
            flexDirection: 'column',
            alignItems: 'center',
          },
          width: '100%',
        }}
        gap={1}
        padding={0.5}
      >
        <Stack flexDirection={'row'}>
          {entity.kind === 'missing' && status !== 'VALIDATED' && (
            <WarningAmberIcon
              sx={{ mr: 1 }}
              // @ts-ignore (danger doesn't exist, but it works)
              color={infoByAnnotStatus[status].muiColor}
            />
          )}
          <Typography
            level="body-sm"
            style={{
              color: 'var(--joy-palette-text-primary)',
            }}
            sx={{ wordBreak: 'break-word' }}
          >
            {entity.label}
          </Typography>
        </Stack>

        <Stack
          flexDirection={'column'}
          alignItems={'end'}
          sx={{ flexGrow: 1 }}
          gap={1}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              '@container (max-width: 500px)': {
                flexDirection: 'column',
              },
            }}
          >
            {entity.kind !== 'missing' ? (
              <Box
                sx={{
                  width: '100%',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: 'var(--joy-palette-background-level2)',
                }}
              >
                <Textarea
                  ref={transformedTextRef}
                  readOnly
                  value={entity.transformedText}
                  color={infoByAnnotStatus[status].muiColor}
                  variant={'soft'}
                  size={'sm'}
                  sx={{
                    '.MuiTextarea-startDecorator': {
                      position: 'absolute',
                      top: '-12px',
                      right: '-16px',
                    },
                    '.MuiTextarea-endDecorator': {
                      position: 'absolute',
                      top: '-6px',
                      right: '-3px',
                    },
                    '--Textarea-focusedThickness': '0px',
                    '& ::selection': {
                      background: 'transparent',
                    },
                    '.MuiTextarea-textarea': {
                      cursor: 'pointer!important',
                      paddingRight: '30px',
                    },
                    width: '200px',
                  }}
                  onCopy={() => setCopied(true)}
                  endDecorator={
                    <IconButton
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        visibility: isFocused || copied ? 'visible' : 'hidden',
                      }}
                      color="primary"
                      size={'sm'}
                      onClick={handleClickCopy}
                    >
                      {copied ? (
                        <LibraryAddCheckRounded />
                      ) : (
                        <ContentCopyRoundedIcon />
                      )}
                    </IconButton>
                  }
                />
              </Box>
            ) : (
              <Stack
                flexDirection={'row'}
                alignItems={'center'}
                sx={{ ml: 1, mr: 1 }}
              >
                {status !== AnnotStatus.PENDING && (
                  <Chip
                    sx={{ mt: 0.5, mb: 0.5 }}
                    color={infoByAnnotStatus[status].muiColor}
                  >
                    {t(`validation.missingField.chipLabel.${status}`)}
                  </Chip>
                )}

                <Tooltip
                  title={t(`validation.missingField.tooltipLabel.${status}`)}
                  variant="soft"
                  placement={'top'}
                  color={'neutral'}
                  sx={{ p: 1, pr: 2, pl: 2 }}
                >
                  <InfoIcon sx={{ mt: 0.5, mb: 0.5 }}></InfoIcon>
                </Tooltip>
              </Stack>
            )}
          </Box>
          {status === AnnotStatus.REJECTED && (
            <Textarea
              readOnly={isInReadOnlyMode}
              onKeyDown={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                useUiState.getState().setFocusedEntityId(entity.id, false);
              }}
              onMouseMove={(e) => e.stopPropagation()}
              variant={'plain'}
              color={'danger'}
              sx={{ width: 200 }}
              value={rejectionReason}
              onChange={(e) => {
                setEntityRejectionReason(entity.id, e.target.value);
              }}
              placeholder={t('validation.rejection.placeholder')}
            ></Textarea>
          )}
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          <EntityStatusButtons
            status={status}
            setStatus={handleSetStatus}
            kind={entity.kind}
          ></EntityStatusButtons>
          {company?.featureFlags?.handleBackofficeSync && (
            <EntityHistory entity={entity} />
          )}
        </Stack>
      </Stack>
      {company?.featureFlags?.handleBackofficeSync && (
        <SyncEntityEl
          entityId={entity.id}
          // @ts-ignore
          docText={entity.transformedText}
        ></SyncEntityEl>
      )}
    </Box>
  );
};
const createHighlightAnnotation = async (
  selectedAnnotId: string,
  status: AnnotStatus,
  transformedText: string
) => {
  const instance = await useAppState.getState().getInstance();
  if (!useUiState.getState().documentFullyLoaded) return;
  const { annotationManager } = instance.Core;
  const annot = drawHighlightAnnotation(
    instance,
    selectedAnnotId,
    status,
    transformedText
  );

  if (annot) {
    annotationManager.jumpToAnnotation(annot, {
      isSmoothScroll: true,
    });
  }

  // const { setFitMode, FitMode } = instance.UI;
  // setFitMode(FitMode.FitWidth);
};

const deleteAnnotation = async (selectedAnnotId: string) => {
  const instance = await useAppState.getState().instance;
  if (!instance) return;
  const { annotationManager } = instance.Core;
  const annot = annotationManager.getAnnotationById(selectedAnnotId);
  if (annot) {
    await annotationManager.deleteAnnotation(annot, { force: true });
  }
};

const EntityCardMemo = memo(EntityCard);
export default EntityCardMemo;
