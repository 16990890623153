import { EStationDocType } from '@/schemas/types.ts';
import { EStationDocTypesByFileByPage } from '@/types';

/**
 * The splitter output starts at page 0, but apryse pdf displays starts at page 1.
 */
export const startPageNumberAtOne = (
  docTypesByFileByPage: EStationDocTypesByFileByPage
): EStationDocTypesByFileByPage => {
  const docTypes = Object.keys(docTypesByFileByPage) as EStationDocType[];
  return docTypes.reduce((acc, docType) => {
    acc[docType] = Object.entries(docTypesByFileByPage[docType]!).reduce(
      (accDoc, [docKey, pageNumbers]) => {
        accDoc[docKey] = pageNumbers.map((pageNumber) => pageNumber + 1);
        return accDoc;
      },
      {} as { [docKey: string]: number[] }
    );
    return acc;
  }, {} as EStationDocTypesByFileByPage);
};
