import FilesTable from '@/components/UI/FilesTable.tsx';
import SideBarLayout from '@/components/UI/SideBarLayout.tsx';

export default function HomePage() {
  return (
    <SideBarLayout>
      <FilesTable />
    </SideBarLayout>
  );
}
