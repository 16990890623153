import { queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import {
  TDossiersListQueryParams,
  getDossiersList,
  onDossiersUpdated,
} from '@/queries/dossiers.ts';
import { IDossier, Order, TranslationT, WorkflowStatus } from '@/types';

export const LIST_DOSSIERS_QUERY_KEY = 'list-dossiers';

const folderListQuery = (params: TDossiersListQueryParams) => {
  return queryOptions({
    queryKey: [LIST_DOSSIERS_QUERY_KEY],
    queryFn: async () => getDossiersList(params),
  });
};

const dummyUnscubscribe = () => {};

export const useDossiers = (
  companyId: string,
  tagId: string,
  archived: boolean
): {
  order: Order;
  setOrder: (a: Order) => void;
  orderBy: keyof IDossier;
  setOrderBy: (a: keyof IDossier) => void;
  search: string;
  setSearch: (a: string) => void;
  dossiers: IDossier[];
  isFetching: boolean;
  canGoNextPage: boolean;
  canGoPreviousPage: boolean;
  goNextPage: () => void;
  goPreviousPage: () => void;
} => {
  const queryClient = useQueryClient();

  const [, setUncompletedInfo] = useState({
    unsubscribe: dummyUnscubscribe,
    uncompletedDossiersIds: new Set<string>(),
  });

  const [page, setPage] = useState<number>(1);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof IDossier>('createdAt');
  const [search, setSearch] = useState<string>('');

  const goNextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, [setPage]);

  const goPreviousPage = useCallback(() => {
    setPage((prev) => prev - 1);
  }, [setPage]);

  const {
    data = { canGoNextPage: false, canGoPreviousPage: false, dossiers: [] },
    isFetching,
  } = useQuery(
    folderListQuery({
      companyId,
      tagId,
      archived,
      search,
      order,
      orderBy,
      page,
      pageSize: 15,
    })
  );

  const { dossiers, canGoPreviousPage, canGoNextPage } = data;

  useEffect(() => {
    setOrder('desc');
    setOrderBy('createdAt');
  }, [tagId, companyId]);

  useEffect(() => {
    setPage(1);
  }, [order, orderBy, search, tagId, companyId]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [LIST_DOSSIERS_QUERY_KEY],
    });
  }, [companyId, tagId, archived, order, orderBy, search, page, queryClient]);

  useEffect(() => {
    const newUncompletedIds = dossiers!
      .filter(
        (dossier) =>
          dossier.workflowStatus === WorkflowStatus.STARTED ||
          !dossier.workflowStatus
      )
      .map((dossier) => dossier.id);

    setUncompletedInfo(({ unsubscribe, uncompletedDossiersIds }) => {
      const newUncompletedDossiers = new Set(newUncompletedIds);
      let newUnsubscribe = dummyUnscubscribe;

      // If there is a change and we need to subscribe to dossiers changes
      if (!isEqual(newUncompletedDossiers, uncompletedDossiersIds)) {
        // In all cases we unsubscribe
        unsubscribe();
        if (newUncompletedDossiers.size > 0) {
          // We subscribe to firestore
          newUnsubscribe = onDossiersUpdated(
            [...newUncompletedDossiers],
            (dossiers) => {
              // and check for relevant changes to the dossiers that where not completed before
              for (const dossier of dossiers) {
                if (
                  dossier.workflowStatus === WorkflowStatus.COMPLETED ||
                  dossier.workflowStatus === WorkflowStatus.FAILED
                ) {
                  // we unsubscribe and invalidate the queries to refresh the cache
                  newUnsubscribe();
                  queryClient.invalidateQueries({
                    queryKey: [LIST_DOSSIERS_QUERY_KEY],
                  });
                  break;
                }
              }
            }
          );
        }
      }

      return {
        unsubscribe: newUnsubscribe,
        uncompletedDossiersIds: newUncompletedDossiers,
      };
    });
  }, [dossiers, queryClient, companyId, tagId, archived]);

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    search,
    setSearch,
    dossiers: isFetching ? [] : dossiers!,
    isFetching,
    canGoNextPage,
    canGoPreviousPage,
    goNextPage,
    goPreviousPage,
  };
};

export const checkNewName = (
  dossiers: IDossier[],
  newName: string,
  t: TranslationT
) => {
  if (newName === '') {
    alert(t('dossier.actions.error.empty'));
    return false;
  }
  if (
    dossiers.find((dossier) => dossier.name === newName && !dossier.archived)
  ) {
    alert(t('dossier.actions.error.duplicate'));
    return false;
  }
  return true;
};
