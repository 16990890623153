import { z } from 'zod';

import { ETAS_SIMU_OPS } from '../constants';
import {
  AddressSchema,
  BeneficiarySchema,
  CompanyInstanceSchema,
  DocumentSchema,
  DocumentVersionSchema,
  EAlimentationType,
  EApplicationType,
  EAtticInsulationType,
  ECodeAdeme,
  ECombustible,
  EProjectPhase,
  ERegulatorClass,
  ESolarCertification,
  EWaterHeatingOption,
  FormTrackerSchema,
  MprSpecialWorksSchema,
  OperationSchema,
  ProjectSchema,
  TaxAssessmentSchema,
} from '../generated/prisma-zod';
import { API_POSSIBLE_TABLES } from './constants';
import {
  TZodFieldInfo,
  getTypeInfoFromConfig,
  getZodObjectFromInfo,
} from './helpers';
import { DeepKeys, ENumberUnit, TModelInfo, TTable } from './types';

export type TApiPossibleTables = z.infer<ReturnType<typeof zApiPossibleTables>>;
export const zApiPossibleTables = () => z.enum(API_POSSIBLE_TABLES);

export const addressInfo = {
  _table: 'address',
  fields: {
    housenumber: {},
    street: {},
    addressAddition: {},
    postcode: {},
    city: {},
  },
} as const satisfies TModelInfo<typeof AddressSchema, 'address'>;

export type TSharedAddress = z.infer<ReturnType<typeof zSharedAddress>>;
export const zSharedAddress = () =>
  getZodObjectFromInfo(AddressSchema, addressInfo);

export const mprSpecialWorksInfo = {
  _table: 'mprSpecialWorks',
  fields: {
    hasDeposeCuveFioul: {},
    montantTtcCentsDeposeCuveFioul: {
      typeInfo: { unit: ENumberUnit.CENTS_EUR, min: 1, precision: 0 },
    },
    hasAuditEnergetique: {},
    montantTtcCentsAuditEnergetique: {
      typeInfo: { unit: ENumberUnit.CENTS_EUR, min: 1, precision: 0 },
    },
    hasVentilationDoubleFlux: {},
    montantTtcCentsVentilationDoubleFlux: {
      typeInfo: { unit: ENumberUnit.CENTS_EUR, min: 1, precision: 0 },
    },
  },
} as const satisfies TModelInfo<
  typeof MprSpecialWorksSchema,
  'mprSpecialWorks'
>;

export type TSharedMprSpecialWorks = z.infer<
  ReturnType<typeof zSharedMprSpecialWorks>
>;
export const zSharedMprSpecialWorks = () =>
  getZodObjectFromInfo(MprSpecialWorksSchema, mprSpecialWorksInfo);

export const documentVersionInfo = {
  _table: 'documentVersion',
  fields: {
    isValid: {},
    rejectedByKE: {},
    reviewComment: {},
    createdAt: { readOnly: true },
    stationDossierId: { readOnly: true },
  },
} as const satisfies TModelInfo<
  typeof DocumentVersionSchema,
  'documentVersion'
>;

export type TSharedDocumentVersion = z.infer<
  ReturnType<typeof zSharedDocumentVersion>
>;
export const zSharedDocumentVersion = () =>
  getZodObjectFromInfo(DocumentVersionSchema, documentVersionInfo);

export const documentInfo = {
  _table: 'document',
  fields: {
    docType: { readOnly: true, enumName: 'EDocType' },
    codeAdeme: { readOnly: true, enumName: 'ECodeAdeme' },
    projectId: { readOnly: true },
    operationId: { readOnly: true },
    companyInstanceId: { readOnly: true },
  },
  relatedFields: {
    latestVersion: {
      label: 'Dernière version',
      kind: 'documentVersion',
      validator: zSharedDocumentVersion().nullable(),
    },
    otherVersions: {
      label: 'Autres versions',
      kind: 'documentVersion',
      validator: z.array(zSharedDocumentVersion()),
      readOnly: true,
    },
  },
} as const satisfies TModelInfo<typeof DocumentSchema, 'document'>;

export type TSharedDocument = z.infer<ReturnType<typeof zSharedDocument>>;
export const zSharedDocument = () =>
  getZodObjectFromInfo(DocumentSchema, documentInfo);

export const formInfo = {
  _table: 'formTracker',
  fields: {
    form: { readOnly: true, enumName: 'EForm' },
    isCompleted: { readOnly: true },
  },
} as const satisfies TModelInfo<typeof FormTrackerSchema, 'formTracker'>;

export type TSharedFormInfo = z.infer<ReturnType<typeof zSharedFormInfo>>;
export const zSharedFormInfo = () =>
  getZodObjectFromInfo(FormTrackerSchema, formInfo);

export const companyInstanceInfo = {
  _table: 'companyInstance',
  fields: {
    name: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.name']],
          label: 'Doit être renseigné',
        },
      ],
    },
    siret: {
      rules: [
        {
          check: ['regexValid', ['get', 'self.siret'], '^[0-9]{14}$'],
          label: 'Siret doit être un numéro de 14 chiffres',
        },
      ],
      typeInfo: {
        format: 'siret',
      },
    },

    vatNumber: {
      rules: [
        {
          // only ops can change that field
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.vatNumber']],
          label: 'N° TVA Requise - Mettre N/A si pas de TVA',
        },
      ],
    },
    phone: {
      typeInfo: {
        format: 'phone',
      },
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.phone']],
          label: 'Tel Requis',
        },
      ],
    },
    email: {
      typeInfo: {
        format: 'email',
      },
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.email']],
          label: 'Email Requis',
        },
      ],
    },
    firstNameRep: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.firstNameRep']],
          label: 'Prénom Requis',
        },
      ],
    },
    lastNameRep: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.lastNameRep']],
          label: 'Nom Requis',
        },
      ],
    },
    functionRep: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.functionRep']],
          label: 'Fonction Requis',
        },
      ],
    },
  },
  relatedFields: {
    address: {
      label: 'Adresse',
      kind: 'address',
      validator: zSharedAddress(),
    },
  },
} as const satisfies TModelInfo<
  typeof CompanyInstanceSchema,
  'companyInstance'
>;

export type TSharedCompanyInstance = z.infer<
  ReturnType<typeof zSharedCompanyInstance>
>;
export const zSharedCompanyInstance = () =>
  getZodObjectFromInfo(CompanyInstanceSchema, companyInstanceInfo);

export const taxAssessmentInfo = {
  _table: 'taxAssessment',
  fields: {
    documentId: { readOnly: true },
    firstDeclarantName: {},
    incomeYear: {
      readOnly: true,
      typeInfo: {
        min: 2000,
        precision: 0,
      },
    },
    income: {
      typeInfo: {
        min: 0,
        precision: 0,
        unit: ENumberUnit.EUROS,
      },
      rules: [
        {
          check: ['>=', ['get', 'self.income'], 0],
          label: 'Revenu >= 0',
        },
      ],
    },
    nbPersonInHouseHold: {
      typeInfo: {
        min: 1,
        precision: 0,
      },
      rules: [
        {
          check: ['>=', ['get', 'self.nbPersonInHouseHold'], 0],
          label: 'nbPersonInHouseHold >= 0',
        },
      ],
    },
  },
} as const satisfies TModelInfo<typeof TaxAssessmentSchema, 'taxAssessment'>;

export type TSharedTaxAssessment = z.infer<
  ReturnType<typeof zSharedTaxAssessment>
>;
export const zSharedTaxAssessment = () =>
  getZodObjectFromInfo(TaxAssessmentSchema, taxAssessmentInfo);

export const beneficiaryInfo = {
  _table: 'beneficiary',
  fields: {
    civility: {
      enumName: 'ECivility',
    },
    isLegalEntity: {},
    legalEntitySiret: {
      showOnlyIf: ['==', ['get', 'self.isLegalEntity'], true],
      typeInfo: {
        format: 'siret',
      },
      rules: [
        {
          check: [
            'regexValid',
            ['get', 'self.legalEntitySiret'],
            '^[0-9]{14}$',
          ],
          label: 'Siret doit être un numéro de 14 chiffres',
        },
      ],
    },
    legalEntityRepFunction: {
      showOnlyIf: ['==', ['get', 'self.isLegalEntity'], true],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.legalEntityRepFunction']],
          label: "Fonction du représentant de l'entité légale Requis",
        },
      ],
    },
    legalEntityName: {
      showOnlyIf: ['==', ['get', 'self.isLegalEntity'], true],
    },
    phone: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.phone']],
          label: 'Tel Requis',
        },
      ],
      typeInfo: { format: 'phone' },
    },
    email: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.email']],

          label: 'Email Requis',
        },
      ],
      typeInfo: {
        format: 'email',
      },
    },
    firstName: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.firstName']],
          label: 'Prénom Requis',
        },
      ],
    },
    lastName: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.lastName']],
          label: 'Nom Requis',
        },
      ],
    },
    invitedToAbrico: { readOnly: true },
    connectedAtLeastOnce: { readOnly: true },
    cguAccepted: { readOnly: true },
  },
  relatedFields: {
    billingAddress: {
      label: 'Adresse de facturation',
      validator: zSharedAddress(),
      kind: 'address',
    },
    taxAssessments: {
      label: "Avis d'imposition",
      kind: 'taxAssessment',
      validator: z.array(zSharedTaxAssessment()),
    },
  },
} as const satisfies TModelInfo<typeof BeneficiarySchema, 'beneficiary'>;

export type TSharedBeneficiary = z.infer<ReturnType<typeof zSharedBeneficiary>>;
export const zSharedBeneficiary = () =>
  getZodObjectFromInfo(BeneficiarySchema, beneficiaryInfo);

export const operationInfo = {
  _table: 'operation',
  fields: {
    codeAdeme: {
      enumName: 'ECodeAdeme',
      readOnly: true,
    },
    hasChangedEquipmentSinceDevis: {
      readOnly: true,
    },
    mainEquipmentBrand: {
      restrictToCodeAdemes: [
        'BAR_EN_101',
        'BAR_EN_102',
        'BAR_EN_103',
        'BAR_TH_112',
        'BAR_TH_113',
        'BAR_TH_129',
        'BAR_TH_143',
        'BAR_TH_148',
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
      ],
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.mainEquipmentBrand']],
          label: 'Marque du matériel principal Requise',
        },
      ],
    },
    mainEquipmentReference: {
      restrictToCodeAdemes: [
        'BAR_EN_101',
        'BAR_EN_102',
        'BAR_EN_103',
        'BAR_TH_112',
        'BAR_TH_113',
        'BAR_TH_129',
        'BAR_TH_143',
        'BAR_TH_148',
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
      ],
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.mainEquipmentReference']],
          label: 'Ref du matériel principal Requise',
        },
      ],
    },
    otherEquipmentsBrands: {
      restrictToCodeAdemes: [
        'BAR_TH_129',
        'BAR_EN_101',
        'BAR_EN_102',
        'BAR_EN_103',
      ],
      rules: [
        // We don't put rules on isolation
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_129'],
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.otherEquipmentsBrands']],
          label: "Marque de l'unité intérieure requise",
        },
      ],
    },
    otherEquipmentsReferences: {
      restrictToCodeAdemes: [
        'BAR_TH_129',
        'BAR_EN_101',
        'BAR_EN_102',
        'BAR_EN_103',
      ],
      rules: [
        // We don't put rules on isolation
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_129'],
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.otherEquipmentsBrands']],
          label: "Ref de l'unité intérieure requise",
        },
      ],
    },
    previousEquipment: {
      enumName: 'EPreviousEquipment',
      restrictToCodeAdemes: [
        'BAR_TH_112',
        'BAR_TH_113',
        'BAR_TH_143',
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
      ],
    },
    applicationType: {
      enumName: 'EApplicationType',
      restrictToCodeAdemes: ['BAR_TH_159', 'BAR_TH_171', 'BAR_TH_172'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.applicationType']],
          label: 'Type d application requis',
        },
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_159'],
          check: [
            '!=',
            ['get', 'self.applicationType'],
            EApplicationType.LOW_TEMPERATURE,
          ],
          label:
            'BAR TH 159 : Application type must be different from low temperature',
        },
      ],
    },
    productionOption: {
      enumName: 'EProductionOption',
      restrictToCodeAdemes: ['BAR_TH_171', 'BAR_TH_172', 'BAR_TH_159'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.productionOption']],
          label:
            'Option de production requise (eau chaude seule pas possible!)',
        },
      ],
    },
    waterHeatingOption: {
      enumName: 'EWaterHeatingOption',
      restrictToCodeAdemes: ['BAR_TH_148'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.waterHeatingOption']],
          label: 'Type de chauffe eau requis',
        },
      ],
    },
    combustible: {
      enumName: 'ECombustible',
      restrictToCodeAdemes: ['BAR_TH_112'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.combustible']],
          label: 'Combustible requis',
        },
      ],
    },
    woodHeatingType: {
      enumName: 'EWoodHeatingType',
      restrictToCodeAdemes: ['BAR_TH_112'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.woodHeatingType']],
          label: 'Type de chauffage bois requis',
        },
      ],
    },
    alimentationType: {
      enumName: 'EAlimentationType',
      restrictToCodeAdemes: ['BAR_TH_113'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.alimentationType']],
          label: 'Type d alimentation requis',
        },
      ],
    },
    insulationLocation: {
      enumName: 'EInsulationLocation',
      restrictToCodeAdemes: ['BAR_EN_101'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.insulationLocation']],
          label: 'Emplacement d isolation requis',
        },
      ],
    },
    insulationType: {
      enumName: 'EInsulationType',
      restrictToCodeAdemes: ['BAR_EN_102'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.insulationType']],
          label: 'Type d isolation requis',
        },
      ],
    },
    productivity: {
      restrictToCodeAdemes: ['BAR_TH_143'],
      typeInfo: {
        unit: ENumberUnit.WATT_PER_METER_SQUARED,
      },
      rules: [
        {
          check: ['>=', ['get', 'self.productivity'], 600],
          label: 'Productivité >= 600',
        },
      ],
    },
    solarCertification: {
      restrictToCodeAdemes: ['BAR_TH_143'],
      enumName: 'ESolarCertification',
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.solarCertification']],
          label: 'Certification solaire requise',
        },
        {
          check: [
            '!=',
            ['get', 'self.solarCertification'],
            ESolarCertification.OTHER,
          ],
          label: 'Certification  CSTBat ou Solarkeymark ou équivalente requise',
        },
      ],
    },
    isCoupledWithLowTemperatureCentralHeating: {
      restrictToCodeAdemes: ['BAR_TH_143'],
      rules: [
        {
          check: [
            '==',
            ['get', 'self.isCoupledWithLowTemperatureCentralHeating'],
            true,
          ],
          label: 'Obligatoire',
        },
      ],
    },
    name: {
      readOnly: true,
    },
    hasSubcontractor: {},
    montantTtcCents: {
      typeInfo: {
        unit: ENumberUnit.CENTS_EUR,
        min: 1,
        precision: 0,
      },
    },
    nominalPower: {
      restrictToCodeAdemes: ['BAR_TH_113', 'BAR_TH_129', 'BAR_TH_159'],
      typeInfo: {
        unit: ENumberUnit.KILOWATT,
      },
      rules: [
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_113'],
          check: ['<', ['get', 'self.nominalPower'], 70],
          label: 'Puissance nominale <= 70',
        },
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_129'],
          check: ['<', ['get', 'self.nominalPower'], 12],
          label: 'Puissance nominale +7°/+35° <= 12',
        },
      ],
    },
    profile: {
      enumName: 'EProfile',
      restrictToCodeAdemes: ['BAR_TH_148'],
    },
    hasAuxiliaryLiquidOrGasHeating: {
      restrictToCodeAdemes: ['BAR_TH_159'],
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['==', ['get', 'self.hasAuxiliaryLiquidOrGasHeating'], true],
          label: 'Auxiliary heating required',
        },
      ],
    },
    etas: {
      restrictToCodeAdemes: ETAS_SIMU_OPS,
      typeInfo: {
        unit: ENumberUnit.INT_PERC,
        min: 1,
        precision: 0,
      },
      rules: [
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_112'],
          check: [
            'case',
            [
              ['==', ['get', 'self.combustible'], ECombustible.GRANULES],
              ['>=', ['get', 'self.etas'], 80],
            ],
            ['>=', ['get', 'self.etas'], 66],
          ],
          label: 'AICB > 80% pour granulé, 66% pour autre',
        },
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_113'],
          check: [
            'case',
            [
              ['<', ['get', 'self.nominalPower'], 20],
              ['>', ['get', 'self.etas'], 77],
            ],
            ['>=', ['get', 'self.etas'], 79],
          ],
          label:
            'Chaudière biomass ETAS > 77% si puisssance nominale < 20kw et > 79% si puissance nominale > 20 kw',
        },
        {
          precondition: [
            'in',
            ['get', 'self.codeAdeme'],
            ['literal', ['BAR_TH_171', 'BAR_TH_172']],
          ],
          check: [
            'case',
            [
              [
                '==',
                ['get', 'self.applicationType'],
                EApplicationType.MEDIUM_OR_HIGH_TEMPERATURE,
              ],
              ['>=', ['get', 'self.etas'], 111],
            ],
            ['>=', ['get', 'self.etas'], 126],
          ],
          label:
            'Le rendement doit être ≥ 126% pour une PAC basse température et ≥ 111% pour une PAC moyenne ou haute température',
        },
      ],
    },
    scop: {
      restrictToCodeAdemes: ['BAR_TH_129'],
      typeInfo: {
        min: 1,
        precision: 1,
      },
      rules: [
        {
          check: ['>=', ['get', 'self.scop'], 3.9],
          label: 'SCOP doit être supérieur à 3.9',
        },
      ],
    },
    cop: {
      restrictToCodeAdemes: ['BAR_TH_148'],
      typeInfo: {
        min: 1,
        precision: 1,
      },
      rules: [
        {
          check: [
            'case',
            [
              [
                '==',
                ['get', 'self.waterHeatingOption'],
                EWaterHeatingOption.EXTRACTED_AIR,
              ],
              ['>', ['get', 'self.cop'], 2.5],
            ],
            ['>', ['get', 'self.cop'], 2.4],
          ],
          label: 'COP doit être > 2.5 pour si sur air extrait, sinon > 2.4',
        },
      ],
    },
    siloVolume: {
      restrictToCodeAdemes: ['BAR_TH_113', 'BAR_TH_143'],
      typeInfo: {
        unit: ENumberUnit.LITTER,
      },
      rules: [
        {
          precondition: [
            '==',
            ['get', 'self.codeAdeme'],
            ECodeAdeme.BAR_TH_143,
          ],
          check: ['>', ['get', 'self.siloVolume'], 400],
          label: 'Le silo doit au moins être de 400 litres',
        },
        {
          precondition: [
            'and',
            ['==', ['get', 'self.codeAdeme'], ECodeAdeme.BAR_TH_113],
            [
              '==',
              ['get', 'self.alimentationType'],
              EAlimentationType.AUTOMATED,
            ],
          ],
          check: ['>', ['get', 'self.siloVolume'], 225],
          label:
            'Si alimentation automatique, le silo doit au moins être de 225 litres',
        },
      ],
    },
    hasBufferTank: {
      restrictToCodeAdemes: ['BAR_TH_113'],
      rules: [
        {
          precondition: [
            '==',
            ['get', 'self.alimentationType'],
            EAlimentationType.MANUAL,
          ],
          check: ['==', ['get', 'self.hasBufferTank'], true],
          label: 'Obligatoire si manuelle',
        },
      ],
    },
    regulatorClass: {
      enumName: 'ERegulatorClass',
      restrictToCodeAdemes: [
        'BAR_TH_113',
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
      ],
      rules: [
        {
          check: [
            'in',
            ['get', 'self.regulatorClass'],
            [
              'literal',
              [
                ERegulatorClass.IV,
                ERegulatorClass.V,
                ERegulatorClass.VI,
                ERegulatorClass.VII,
                ERegulatorClass.VIII,
              ] as const satisfies ERegulatorClass[],
            ],
          ],
          label: 'La classe du régulateur doit être >= IV',
        },
      ],
    },
    regulatorBrand: {
      restrictToCodeAdemes: [
        'BAR_TH_113',
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
      ],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.regulatorBrand']],
          label: 'Marque du régulateur Requise',
        },
      ],
    },
    regulatorReference: {
      restrictToCodeAdemes: [
        'BAR_TH_113',
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
      ],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.regulatorBrand']],
          label: 'Référence du régulateur Requise',
        },
      ],
    },
    is7GreenStar: {
      restrictToCodeAdemes: ['BAR_TH_113', 'BAR_TH_112'],
      rules: [
        {
          check: ['==', ['get', 'self.is7GreenStar'], true],
          label: 'Installation a le label 7* pour 113 ou flamme verte pour 112',
        },
      ],
    },
    fineParticles: {
      restrictToCodeAdemes: ['BAR_TH_112', 'BAR_TH_113'],
      rules: [
        {
          precondition: [
            'and',
            ['==', ['get', 'self.codeAdeme'], ECodeAdeme.BAR_TH_112],
            ['==', ['get', 'self.is7GreenStar'], false],
          ],
          check: [
            'case',
            [
              ['==', ['get', 'self.combustible'], ECombustible.GRANULES],
              ['<', ['get', 'self.emissionCo'], 20],
            ],

            ['<', ['get', 'self.emissionCo'], 40],
          ],
          label: 'Si AICB Granulés, particules doit être < 20, sinon < 40',
        },
        {
          precondition: [
            'and',
            ['==', ['get', 'self.codeAdeme'], ECodeAdeme.BAR_TH_113],
            ['==', ['get', 'self.is7GreenStar'], false],
          ],
          check: [
            'case',
            [
              [
                '==',
                ['get', 'self.alimentationType'],
                EAlimentationType.AUTOMATED,
              ],
              ['<', ['get', 'self.emissionCo'], 30],
            ],
            ['<', ['get', 'self.emissionCo'], 40],
          ],
          label:
            'Si alimentation automatique, particules doit être < 30, sinon < 40',
        },
      ],
      typeInfo: {
        unit: ENumberUnit.MILLIGRAMS_PER_NORMAL_CUBIC_METER,
      },
    },
    emissionNox: {
      restrictToCodeAdemes: ['BAR_TH_113', 'BAR_TH_112'],
      rules: [
        {
          precondition: [
            'and',
            [
              'in',
              ['get', 'self.codeAdeme'],
              ['literal', ['BAR_TH_112', 'BAR_TH_113']],
            ],
            ['==', ['get', 'self.is7GreenStar'], false],
          ],
          check: ['<', ['get', 'self.emissionNox'], 200],
          label: 'Doit être < 200',
        },
      ],
      typeInfo: {
        unit: ENumberUnit.MILLIGRAMS_PER_NORMAL_CUBIC_METER,
      },
    },
    emissionCo: {
      restrictToCodeAdemes: ['BAR_TH_113', 'BAR_TH_112'],
      rules: [
        {
          precondition: [
            'and',
            ['==', ['get', 'self.codeAdeme'], ECodeAdeme.BAR_TH_112],
            ['==', ['get', 'self.is7GreenStar'], false],
          ],
          check: [
            'case',
            [
              ['==', ['get', 'self.combustible'], ECombustible.GRANULES],
              ['<', ['get', 'self.emissionCo'], 300],
            ],

            ['<', ['get', 'self.emissionCo'], 1500],
          ],
          label: 'Si AICB Granulés, CO doit être < 300, sinon < 1500',
        },
        {
          precondition: [
            'and',
            ['==', ['get', 'self.codeAdeme'], ECodeAdeme.BAR_TH_113],
            ['==', ['get', 'self.is7GreenStar'], false],
          ],
          check: [
            'case',
            [
              [
                '==',
                ['get', 'self.alimentationType'],
                EAlimentationType.AUTOMATED,
              ],
              ['<', ['get', 'self.emissionCo'], 400],
            ],
            ['<', ['get', 'self.emissionCo'], 600],
          ],
          label: 'Si alimentation automatique, CO doit être < 400, sinon < 600',
        },
      ],
      typeInfo: {
        unit: ENumberUnit.MILLIGRAMS_PER_NORMAL_CUBIC_METER,
      },
    },
    emissionCog: {
      restrictToCodeAdemes: ['BAR_TH_113', 'BAR_TH_112'],
      rules: [
        {
          precondition: [
            'and',
            ['==', ['get', 'self.codeAdeme'], ECodeAdeme.BAR_TH_112],
            ['==', ['get', 'self.is7GreenStar'], false],
          ],
          check: [
            'case',
            [
              ['==', ['get', 'self.combustible'], ECombustible.GRANULES],
              ['<', ['get', 'self.emissionCog'], 60],
            ],

            ['<', ['get', 'self.emissionCog'], 120],
          ],
          label: 'Si AICB Granulés, CO doit être < 60, sinon < 120',
        },
        {
          precondition: [
            'and',
            ['==', ['get', 'self.codeAdeme'], ECodeAdeme.BAR_TH_113],
            ['==', ['get', 'self.is7GreenStar'], false],
          ],
          check: [
            'case',
            [
              [
                '==',
                ['get', 'self.alimentationType'],
                EAlimentationType.AUTOMATED,
              ],
              ['<', ['get', 'self.emissionCog'], 16],
            ],
            ['<', ['get', 'self.emissionCog'], 20],
          ],
          label: 'Si alimentation automatique, CO doit être < 16, sinon < 20',
        },
      ],
      typeInfo: {
        unit: ENumberUnit.MILLIGRAMS_PER_NORMAL_CUBIC_METER,
      },
    },
    hasPareVapeur: {
      restrictToCodeAdemes: ['BAR_EN_101', 'BAR_EN_103'],
      rules: [
        {
          check: ['==', ['get', 'self.hasPareVapeur'], true],
          label: 'Obligatoire (ou équivalent)',
        },
      ],
    },
    thickness: {
      restrictToCodeAdemes: ['BAR_EN_101', 'BAR_EN_102', 'BAR_EN_103'],
      typeInfo: {
        unit: ENumberUnit.MILIMITIERS,
      },
      rules: [
        {
          check: ['>', ['get', 'self.thickness'], 0],
          label: 'Epaisseur doit être > 0',
        },
      ],
    },
    atticInsulationType: {
      restrictToCodeAdemes: ['BAR_EN_101'],
      enumName: 'EAtticInsulationType',
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.atticInsulationType']],
          label: "Type d'isolation des combles requis",
        },
      ],
    },
    isHeatedAndConvertedAttic: {
      restrictToCodeAdemes: ['BAR_EN_101'],
      showOnlyIf: [
        '==',
        ['get', 'self.atticInsulationType'],
        EAtticInsulationType.RAMPANT_DE_TOITURE,
      ],
      rules: [
        {
          check: ['==', ['get', 'self.isHeatedAndConvertedAttic'], true],
          label:
            'Si rampant de toiture, les combles doivent être chauffés et aménagés',
        },
      ],
    },
    thermalResistance: {
      restrictToCodeAdemes: ['BAR_EN_101', 'BAR_EN_102', 'BAR_EN_103'],
      typeInfo: {
        unit: ENumberUnit.SQUARE_METER_KELVIN_PER_WATT,
      },
      rules: [
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_EN_101'],
          check: [
            'case',
            [
              [
                '==',
                ['get', 'self.atticInsulationType'],
                EAtticInsulationType.RAMPANT_DE_TOITURE,
              ],
              ['>=', ['get', 'self.thermalResistance'], 6],
            ],
            [
              [
                '==',
                ['get', 'self.atticInsulationType'],
                EAtticInsulationType.COMBLE_PERDU,
              ],
              ['>=', ['get', 'self.thermalResistance'], 7],
            ],
            false,
          ],
          label: 'Résistance thermique > 6 pour rampant, > 7 pour comble perdu',
        },
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_EN_102'],
          check: ['>=', ['get', 'self.thermalResistance'], 3.7],
          label: 'Résistance thermique > 3.7',
        },
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_EN_103'],
          check: ['>=', ['get', 'self.thermalResistance'], 3],
          label: 'Résistance thermique > 3',
        },
      ],
    },
    acermiCode: {
      restrictToCodeAdemes: ['BAR_EN_101', 'BAR_EN_102', 'BAR_EN_103'],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.acermiCode']],
          label: 'Code ACERMI Requis',
        },
      ],
    },
    coveredSurfaceM2: {
      restrictToCodeAdemes: [
        'BAR_EN_101',
        'BAR_EN_102',
        'BAR_EN_103',
        'BAR_TH_129',
        'BAR_TH_143',
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
      ],
      typeInfo: {
        precision: 0,
        min: 1,
        unit: ENumberUnit.METER_SQUARED,
      },
      rules: [
        {
          check: ['>', ['get', 'self.coveredSurfaceM2'], 0],
          label: 'Surface couverte > 0',
        },
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_143'],
          check: ['>=', ['get', 'self.coveredSurfaceM2'], 8],
          label: 'Surface couverte >= 8',
        },
      ],
    },
    heatedVolumeM3: {
      restrictToCodeAdemes: [
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
        'BAR_TH_113',
      ],
      typeInfo: {
        unit: ENumberUnit.METER_CUBED,
      },
    },
    dateDimensionnement: {
      restrictToCodeAdemes: [
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
        'BAR_TH_113',
      ],
      rules: [
        {
          minPhaseCondition: EProjectPhase.END_OF_PROJECT_DOSSIER_REVIEW,
          check: [
            '<',
            ['get', 'self.dateDimensionnement'],
            ['get', 'project.dateEndWork'],
          ],
          label:
            'Cette note est remise au bénéficiaire à l’achèvement des travaux. La date de dimensionnement de la PAC doit être avant la date de fin des travaux',
        },
      ],
    },
    coverageRatePrct: {
      restrictToCodeAdemes: [
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
        'BAR_TH_113',
      ],
      typeInfo: {
        unit: ENumberUnit.INT_PERC,
        min: 1,
        precision: 0,
      },
      rules: [
        {
          check: ['>', ['get', 'self.coverageRatePrct'], 0],
          label: 'Taux de couverture > 0',
        },
        {
          precondition: ['==', ['get', 'self.codeAdeme'], 'BAR_TH_159'],
          check: ['>=', ['get', 'self.coverageRatePrct'], 70],
          label: 'Taux de couverture >= 70',
        },
      ],
    },
    isDenominationMentionned: {
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['==', ['get', 'self.isDenominationMentionned'], true],
          // This could be improved but I am lazy
          label: `La dénomination doit être mentionnée et conformer à l'ADEME:

BAR-EN-101 : isolation thermique en comble perdu ou en rampant de toiture
BAR-EN-102 : isolation  sur mur(s) en façade ou en pignon
BAR-EN-103 : isolation sur/sous un plancher bas
BAR-EN-105 : Mise en place, en toiture terrasse, d’un procédé d’isolation extérieur.
BAR-TH-112 : Mise en place d’un appareil indépendant de chauffage au bois
BAR-TH-113 : Mise en place d’une chaudière biomasse ligneuse individuelle.
BAR-TH-129 : Mise en place d’une pompe à chaleur (PAC) de type air/air.
BAR-TH-143 : Mise en place d’un système solaire combiné (SSC) destiné au chauffage et à la production d’eau chaude sanitaire.
BAR-TH-148 : Mise en place d’un chauffe-eau thermodynamique individuel à accumulation.
BAR-TH-159 : Mise en place d’une pompe à chaleur air/eau individuelle comportant un dispositif d’appoint utilisant un combustible liquide ou gazeux et une régulation qui les pilote.
BAR-TH-171 : Mise en place d’une pompe à chaleur (PAC) de type air/eau.
BAR-TH-172 : Mise en place d’une pompe à chaleur géothermique (PAC) de type eau/eau ou sol/eau.`,
        },
      ],
    },
  },
  relatedFields: {
    subContractor: {
      label: 'Sous-traitant',
      validator: zSharedCompanyInstance(),
      kind: 'company',
      showOnlyIf: ['==', ['get', 'self.hasSubcontractor'], true],
    },
  },
} as const satisfies TModelInfo<typeof OperationSchema, 'operation'>;

export type TSharedOperation = z.infer<ReturnType<typeof zSharedOperation>>;
export const zSharedOperation = () =>
  getZodObjectFromInfo(OperationSchema, operationInfo);

export const projectInfo = {
  _table: 'project',
  fields: {
    name: { readOnly: true },
    phase: { readOnly: true, enumName: 'EProjectPhase' },
    kind: { readOnly: true, enumName: 'EProjectKind' },
    housingAge: {
      enumName: 'EHousingAge',
    },
    housingOwnerStatus: {
      enumName: 'EHousingOwnerStatus',
    },
    housingType: {
      enumName: 'EHousingType',
    },
    montantTtcCents: {
      typeInfo: {
        unit: ENumberUnit.CENTS_EUR,
        min: 1,
        precision: 0,
      },
    },
    dateStartWork: {
      rules: [
        {
          minPhaseCondition: EProjectPhase.END_OF_PROJECT_DOSSIER_CREATION,
          check: ['notNullOrEmpty', ['get', 'self.dateStartWork']],
          label: 'Date de début des travaux Requise',
        },
      ],
    },
    dateEndWork: {
      rules: [
        {
          minPhaseCondition: EProjectPhase.END_OF_PROJECT_DOSSIER_CREATION,
          check: [
            '>=',
            ['get', 'self.dateEndWork'],
            ['get', 'self.dateStartWork'],
          ],
          label: 'Date de fin des travaux doit être après la date de début',
        },
      ],
    },
    dateTechnicalVisit: {
      restrictToCodeAdemes: ['BAR_EN_101', 'BAR_EN_102', 'BAR_EN_103'],
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: [
            '<=',
            ['get', 'self.dateTechnicalVisit'],
            ['get', 'self.dateDevisIssued'],
          ],
          label:
            "Date du devis émis doit être après la date de visite technique pour les travaux d'isolation",
        },
      ],
    },
    dateDevisIssued: {
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.dateDevisIssued']],
          label: "Date d'emission du devis Requise",
        },
      ],
    },
    dateDevisSigned: {
      rules: [
        {
          // Signature of the devis is blocked anyway, so we enforce it the phase after
          minPhaseCondition:
            EProjectPhase.PREWORK_SUBMISSION_AND_PROJECT_IN_PROGRESS_ALL_IN_ONE,
          check: [
            '>=',
            ['get', 'self.dateDevisSigned'],
            ['get', 'self.dateDevisIssued'],
          ],
          label:
            "Date du devis signé doit être après la date d'émission du devis ou le jour même",
        },
        {
          // Signature of the devis is blocked anyway, so we enforce it the phase after
          minPhaseCondition:
            EProjectPhase.PREWORK_SUBMISSION_AND_PROJECT_IN_PROGRESS_ALL_IN_ONE,
          check: [
            '>=',
            ['get', 'self.dateDevisSigned'],
            ['get', 'self.dateRaiIssued'],
          ],
          label:
            "Date du devis signé doit être après la date d'émission du RAI ou le jour même",
        },
      ],
    },
    dateFactureIssued: {
      rules: [
        {
          minPhaseCondition: EProjectPhase.END_OF_PROJECT_DOSSIER_REVIEW,
          check: [
            '>=',
            ['get', 'self.dateFactureIssued'],
            ['get', 'self.dateEndWork'],
          ],
          label: 'Date de facture doit être après la date de fin des travaux',
        },
      ],
    },
    mprNumber: { readOnly: true },
    mprAccountCreated: { readOnly: true },
    mprIdentityCheck: { readOnly: true },
    mprIdentityCheckType: { readOnly: true, enumName: 'EMPRIdentityCheckType' },
    mprAbricoReceivedNominationEmail: { readOnly: true },
    mprCerfaUploaded: { readOnly: true },
    internalNote: {},
    isBeneficiaryOnboarded: {
      readOnly: true,
    },
    deperditionG: {
      restrictToCodeAdemes: [
        'BAR_TH_159',
        'BAR_TH_171',
        'BAR_TH_172',
        'BAR_TH_113',
      ],
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['>', ['get', 'self.deperditionG'], 0],
          label: 'Déperdition G > 0',
        },
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.deperditionG']],
          label: 'Doit être renseigné',
        },
      ],
    },
    temperatureBase: {
      restrictToCodeAdemes: [
        'BAR_TH_171',
        'BAR_TH_172',
        'BAR_TH_113',
        'BAR_TH_159',
      ],
      rules: [
        {
          check: ['notNullOrEmpty', ['get', 'self.temperatureBase']],
          label: 'Doit être renseigné',
        },
      ],
      typeInfo: {
        unit: ENumberUnit.CELSIUS,
      },
    },
    temperatureC: {
      restrictToCodeAdemes: ['BAR_TH_171', 'BAR_TH_172', 'BAR_TH_113'],
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.devisNumber']],
          label: 'Doit être renseigné',
        },
      ],
    },
    devisNumber: {
      rules: [
        {
          minPhaseCondition:
            EProjectPhase.REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER,
          check: ['notNullOrEmpty', ['get', 'self.devisNumber']],
          label: 'Doit être renseigné',
        },
      ],
    },
    factureNumber: {
      rules: [
        {
          minPhaseCondition: EProjectPhase.END_OF_PROJECT_DOSSIER_REVIEW,
          check: ['notNullOrEmpty', ['get', 'self.factureNumber']],
          label: 'Doit être renseigné',
        },
      ],
    },
    bankFinancementStatus: {
      enumName: 'EBankFinancementStatus',
      readOnly: true,
    },
    dossierReviewByOpsProgress_pre_work: {
      enumName: 'EDossierReviewByOpsProgress',
      readOnly: true,
    },
    dossierReviewByOpsProgress_fix1stDepotKe: {
      enumName: 'EDossierReviewByOpsProgress',
      readOnly: true,
    },
    dossierReviewByOpsProgress_fixLastDepotKe: {
      enumName: 'EDossierReviewByOpsProgress',
      readOnly: true,
    },
    dossierReviewByOpsProgress_fix1stDepotMPR: {
      enumName: 'EDossierReviewByOpsProgress',
      readOnly: true,
    },
    dossierReviewByOpsProgress_postWork: {
      enumName: 'EDossierReviewByOpsProgress',
      readOnly: true,
    },
    dossierReviewByOpsProgress_fixPostWorkKe: {
      enumName: 'EDossierReviewByOpsProgress',
      readOnly: true,
    },
    ke1stDepotStatus: {
      enumName: 'EDossierDepotStatus',
      readOnly: true,
    },
    keLastDepotStatus: {
      enumName: 'EDossierDepotStatus',
      readOnly: true,
    },
    mpr1stDepotStatus: {
      enumName: 'EDossierDepotStatus',
      readOnly: true,
    },
    readyToAskDossierEdits1stDepot: {
      readOnly: true,
    },
    readyToAskDossierEditsLastDepotKe: {
      readOnly: true,
    },
    hasMprOctroi1: {
      readOnly: true,
    },
    hasMprOctroi2: {
      readOnly: true,
    },
    nbKe1stDepot: {
      readOnly: true,
    },
    nbMpr1stDepot: {
      readOnly: true,
    },
    financingDepotStatus: {
      readOnly: true,
      enumName: 'EDossierDepotStatus',
    },
    firstDepositTransferred: {
      readOnly: true,
    },
    mprBenefReceivedConsentRequestEmail: {
      readOnly: true,
    },
    mprBenefConsentSent: {
      readOnly: true,
    },
    glideChantierRowId: {
      readOnly: true,
    },
    needsAttestationPropriete: {
      readOnly: true,
    },
    hasEndedSiteWork: {
      readOnly: true,
    },
  },
  relatedFields: {
    siteAddress: {
      label: 'Adresse du chantier',
      kind: 'address',
      validator: zSharedAddress(),
    },
    mprSpecialWorks: {
      label: 'Travaux spéciaux',
      kind: 'mprSpecialWorks',
      validator: zSharedMprSpecialWorks(),
    },
  },
} as const satisfies TModelInfo<typeof ProjectSchema, 'project'>;

export type TSharedProject = z.infer<ReturnType<typeof zSharedProject>>;
export const zSharedProject = () =>
  getZodObjectFromInfo(ProjectSchema, projectInfo);

export const metaInfo = {
  relatedFields: {
    project: {
      label: 'Projet',
      validator: zSharedProject(),
      kind: 'project',
    },
    benef: {
      label: 'Bénéficiaire',
      validator: zSharedBeneficiary(),
      kind: 'benef',
    },
    company: {
      label: 'Artisan',
      validator: zSharedCompanyInstance(),
      kind: 'company',
    },
    operations: {
      label: 'Opérations',
      validator: z.array(zSharedOperation()),
      kind: 'operation',
    },
    documents: {
      label: 'Documents',
      validator: z.array(zSharedDocument()),
      kind: 'document',
    },
    forms: {
      label: 'Forms',
      validator: z.array(zSharedFormInfo()),
      kind: 'form',
    },
  },
} as const satisfies TModelInfo<z.ZodObject<{}>>;

export type TSharedMetaInfo = z.infer<ReturnType<typeof zSharedMetaInfo>>;
export const zSharedMetaInfo = () =>
  getZodObjectFromInfo(z.object({}), metaInfo);

type META_OBJECTS = Omit<
  TSharedMetaInfo,
  'operations' | 'benef' | 'documents' | 'forms'
> & {
  id: 'For DeepKeys to work properly';
  op: TSharedOperation;
  benef: Omit<TSharedBeneficiary, 'taxAssessments'>;
  taxAssessment: TSharedTaxAssessment;
};
export type TMetaInfoKeys = DeepKeys<META_OBJECTS>;

export const META_KEY_MAPPER = {
  project: 'project',
  benef: 'beneficiary',
  company: 'companyInstance',
  op: 'operation',
  taxAssessment: 'taxAssessment',
  operation: 'operation',
  beneficiary: 'beneficiary',
  address: 'address',
  documentVersion: 'documentVersion',
  document: 'document',
  companyInstance: 'companyInstance',
  formTracker: 'formTracker',
  mprSpecialWorks: 'mprSpecialWorks',
} as const satisfies Record<Exclude<keyof META_OBJECTS, 'id'> | TTable, TTable>;

// This will raise a ts error if something is broken in the metaInfoKeys type system
export function __checkMetaKeysTsWillFailIfTypeIsBroken(): TMetaInfoKeys[] {
  return [
    'benef.cguAccepted',
    'op.scop',
    'benef.billingAddress.addressAddition',
  ];
}

export const META_CONFIG: Record<
  TTable,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TModelInfo<any, any>
> = {
  operation: operationInfo,
  beneficiary: beneficiaryInfo,
  companyInstance: companyInstanceInfo,
  project: projectInfo,
  address: addressInfo,
  document: documentInfo,
  documentVersion: documentVersionInfo,
  taxAssessment: taxAssessmentInfo,
  formTracker: formInfo,
  mprSpecialWorks: mprSpecialWorksInfo,
};

export type TMetaConfig = {
  operation: TSharedOperation;
  beneficiary: TSharedBeneficiary;
  project: TSharedProject;
  address: TSharedAddress;
  taxAssessment: TSharedTaxAssessment;
};

export const schemaByTable = {
  address: AddressSchema,
  documentVersion: DocumentVersionSchema,
  document: DocumentSchema,
  formTracker: FormTrackerSchema,
  companyInstance: CompanyInstanceSchema,
  taxAssessment: TaxAssessmentSchema,
  beneficiary: BeneficiarySchema,
  operation: OperationSchema,
  project: ProjectSchema,
  mprSpecialWorks: MprSpecialWorksSchema,
} as const satisfies Record<TTable, z.ZodObject<{}>>;

// @ts-expect-error bad inference
export const META_CONFIG_TYPES_INFO: {
  [k in keyof typeof META_CONFIG]: Record<
    keyof (typeof META_CONFIG)[k]['fields'],
    TZodFieldInfo
  >;
} = Object.fromEntries(
  Object.entries(META_CONFIG).map(([k, v]) => [
    k,
    Object.fromEntries(
      Object.keys(v.fields ?? {}).map((fieldName) => [
        fieldName,
        getTypeInfoFromConfig(
          // @ts-expect-error bad inference
          schemaByTable[v._table!]!.shape[fieldName],
          // @ts-expect-error bad inference
          META_CONFIG[k].fields![fieldName]!
        ),
      ])
    ),
  ])
);

export type TValidateError = z.infer<ReturnType<typeof zValidateError>>;
export const zValidateError = () =>
  z.object({
    ctx: z.string(),
    error: z.string(),
    metaEntryIds: z.array(z.string()).optional(),
  });

export type TValidateRes = z.infer<ReturnType<typeof zValidateRes>>;
export const zValidateRes = () =>
  z.discriminatedUnion('isValid', [
    z.object({
      isValid: z.literal(true),
      errors: z.array(zValidateError()).max(0),
    }),
    z.object({
      isValid: z.literal(false),
      errors: z.array(zValidateError()).min(1),
    }),
  ]);
