import { Search } from '@mui/icons-material';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MemoryIcon from '@mui/icons-material/Memory';
import ReplayIcon from '@mui/icons-material/Replay';
import { ToggleButtonGroup, Tooltip } from '@mui/joy';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { uniq } from 'lodash';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { invokeSplitterAndExtractorWorkflow } from '@/cloudFunctions/functions.ts';
import AddRgeBtn from '@/components/AttestationRGE/AddRgeBtn.tsx';
import DocEntities from '@/components/Entity/DocEntities.tsx';
import ReviewedEntitiesSummary from '@/components/Review/ReviewedEntitiesSummary.tsx';
import UserFeedbackModal from '@/components/UI/UserFeedbackModal.tsx';
import useWindowDimensions from '@/hooks/useWindowDimensions.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { usePersistedStore } from '@/stores/persistedStore.ts';
import { useUiState } from '@/stores/uiStore.ts';
import { AnnotStatus, TReviewInfo } from '@/types';
import { Dossier } from '@/utils/dossier.ts';
import { useKeyBoardNavigation } from '@/utils/entities.ts';
import { getReviewInfo } from '@/utils/review/helpers';
import { saveDocumentToCloud } from '@/utils/save.ts';

const EntitiesPanel = ({
  dossier,
  panelRef,
  entityGlobalAutoSelectRef,
}: {
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
  dossier?: Dossier | null;
  panelRef: RefObject<HTMLDivElement>;
}) => {
  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.focus();
    }
  }, [panelRef]);

  const { t } = useTranslation('common');
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const isWorkflowLoading = useUiState((state) => state.isWorkflowLoading);

  const instance = useAppState((state) => state.instance);
  const hideReviewedEntitiesSettings = usePersistedStore(
    (state) => state.hideReviewedEntitiesSettings
  );
  const setHideReviewedEntitiesSettings = usePersistedStore(
    (state) => state.setHideReviewedEntitiesSettings
  );

  const setIsWorkflowLoading = useUiState(
    (state) => state.setIsWorkflowLoading
  );

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const handleTriggerWorkflow = useCallback(async () => {
    const dossierId = useAppState.getState().getDossierId();
    setIsWorkflowLoading(true);
    try {
      useInteractionsState.getState().enableReadOnlyMode();
      await saveDocumentToCloud({
        notify: false,
        handleReadonlyMode: false,
      });
      console.time(`workflow processing file: `);
      await invokeSplitterAndExtractorWorkflow({
        dossierId,
      }).catch((err: any) => {
        console.error('Error in invoking workflow:', err);
      });
    } finally {
      console.timeEnd(`workflow processing file: `);
    }
  }, [setIsWorkflowLoading]);

  const { company } = useAppState.getState();

  const [showSearch, setShowSearch] = useState(false);
  const setSearchEntityValue = useInteractionsState(
    (state) => state.setSearchEntityValue
  );
  const searchEntityValue = useInteractionsState(
    (state) => state.searchEntityValue
  );
  useEffect(() => {
    setSearchEntityValue('');
  }, [setSearchEntityValue, showSearch]);

  const { preparedEntities, goNext, goPrevious, preparedEntitiesFlatten } =
    useKeyBoardNavigation(dossier, company?.featureFlags?.handleMissingFields);
  const sirets = useMemo(() => {
    return uniq(
      preparedEntitiesFlatten
        .filter(
          (el) =>
            el.type.toLowerCase().includes('siret') && el.kind === 'extracted'
        )
        // @ts-ignore
        .map((el: TEntityForDisplay) => el.transformedText.replace(/\s/g, ''))
    );
  }, [preparedEntitiesFlatten]);
  const codesAdeme = useInteractionsState(
    useShallow((state) => state.dossier?.codesAdeme ?? [])
  );

  const entitiesRejectionReasons = useInteractionsState(
    (state) => state.entitiesRejectionReasons
  );
  const entitiesStatuses = useInteractionsState(
    (state) => state.entitiesStatuses
  );

  const { height } = useWindowDimensions();

  const focusedEntityId = useUiState(
    useShallow((state) => state.focusedEntityId)
  );

  useEffect(() => {
    if (instance && focusedEntityId) {
      const { annotationManager } = instance.Core;
      const annot = annotationManager.getAnnotationById(focusedEntityId);
      // On chrome we cannot scroll 2 panels at the same time
      // Since we are focusing entities from the DPF too, we shouldn't always jump to it
      if (annot)
        annotationManager.jumpToAnnotation(annot, {
          isSmoothScroll: true,
        });
    }
  }, [focusedEntityId, instance]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent, currentId: string) => {
      if (
        !(
          e.key === 'ArrowDown' ||
          e.key === 'ArrowUp' ||
          e.key === 'Enter' ||
          e.key === 'ArrowLeft' ||
          e.key === 'ArrowRight'
        )
      ) {
        return;
      }
      e.preventDefault();
      if (e.key === 'ArrowDown') goNext();
      else if (e.key === 'ArrowUp') goPrevious();
      else if (e.key === 'Enter' || e.key === 'ArrowRight') {
        useInteractionsState
          .getState()
          .setEntityStatus(currentId, AnnotStatus.VALIDATED);
      } else if (e.key === 'ArrowLeft') {
        useInteractionsState
          .getState()
          .setEntityStatus(currentId, AnnotStatus.REJECTED);
      }
    },
    [goNext, goPrevious]
  );

  const [isExpanded, setIsExpanded] = useState(true);

  const reviewInfo = useMemo<TReviewInfo>(
    () =>
      getReviewInfo(
        preparedEntities,
        entitiesStatuses,
        entitiesRejectionReasons
      ),
    [entitiesRejectionReasons, entitiesStatuses, preparedEntities]
  );

  const callEntityGlobalAutoSelectRef = useCallback(() => {
    entityGlobalAutoSelectRef.current();
  }, [entityGlobalAutoSelectRef]);

  const extraEntityPanelProps = useMemo(() => {
    // when we are abrico, the behavior might be buggy due to the small review panel
    return company?.featureFlags.handleBackofficeSync
      ? {}
      : { onMouseMove: callEntityGlobalAutoSelectRef };
  }, [
    callEntityGlobalAutoSelectRef,
    company?.featureFlags.handleBackofficeSync,
  ]);

  const isAbrico = company?.featureFlags?.handleBackofficeSync === true;

  return (
    <Card
      {...extraEntityPanelProps}
      sx={{
        borderRadius: 12,
        borderWidth: 4,
        width: '100%',
        paddingTop: 0,
        pb: 1,
        maxHeight: 'fit-content',
        overflowY: isExpanded ? 'auto' : 'unset',
        flexGrow: isExpanded ? 1 : 0,
        flexBasis: 0,
      }}
    >
      <div
        tabIndex={0}
        onKeyDown={(e) => handleKeyDown(e, focusedEntityId)}
        ref={panelRef}
        style={{
          outline: 'none',
          top: 10,
          zIndex: 1000,
          backgroundColor: '#0b0d0e',
        }}
      >
        <Stack
          sx={{
            position: 'sticky',
            alignSelf: 'flex-start',
            top: 0,
            zIndex: 1000,
            paddingTop: 2,
            paddingBottom: 1,
            backgroundColor: '#0A0E0F',
          }}
          gap={1}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              width: '100%',
              cursor: isAbrico ? 'pointer' : 'unset',
              mb: isExpanded ? 1 : 0,
              '&:hover': { backgroundColor: isAbrico ? '#171a1c' : 'unset' },
            }}
            onClick={() => {
              if (isAbrico) setIsExpanded(!isExpanded);
            }}
          >
            <Typography
              level={'title-md'}
              sx={{ fontWeight: 'var(--joy-fontWeight-lg) !important' }}
            >
              {t('ui.tabs.entities')}
            </Typography>
            {!isAbrico && (
              <AddRgeBtn
                defaultSirets={sirets}
                defaultCodesAdeme={codesAdeme}
                variant={'add'}
              />
            )}

            <Stack direction={'row'} alignItems={'center'} gap={2}>
              {reviewInfo && (
                <ReviewedEntitiesSummary reviewInfo={reviewInfo} />
              )}
              {isAbrico && (
                <>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
              )}
            </Stack>
          </Stack>

          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ display: isExpanded ? 'flex' : 'none' }}
          >
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <ToggleButtonGroup
                size={'sm'}
                value={hideReviewedEntitiesSettings.toString()}
                onChange={(e, newValue) => {
                  e.stopPropagation();
                  if (newValue) {
                    setHideReviewedEntitiesSettings(newValue === 'true');
                  }
                }}
              >
                <Button value="true">{t('ui.button.pending')}</Button>
                <Button value="false">{t('ui.button.all')} </Button>
              </ToggleButtonGroup>
              <IconButton
                variant={showSearch ? 'solid' : 'soft'}
                onClick={() => setShowSearch((v) => !v)}
              >
                <Search />
              </IconButton>
            </Stack>
            <Stack direction={'row'} gap={1}>
              <IconButton>
                <Tooltip title={t('workflow.start')}>
                  <IconButton // @ts-ignore
                    color={'common.white'}
                    aria-label="commment"
                    size={'sm'}
                    variant={'plain'}
                    disabled={
                      isWorkflowLoading ||
                      isInReadOnlyMode ||
                      (dossier?.workflowExecutions ?? 0) > 2
                    }
                    onClick={handleTriggerWorkflow}
                  >
                    <MemoryIcon fontSize="large" />
                    <ReplayIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </IconButton>
              <Tooltip title={t('userFeedback.title')}>
                <IconButton // @ts-ignore
                  color={'white'}
                  aria-label="commment"
                  size={'sm'}
                  variant={'plain'}
                  onClick={() => setFeedbackModalOpen(true)}
                >
                  <AddCommentOutlinedIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          {showSearch && (
            <Input
              autoFocus={true}
              sx={{ display: isExpanded ? 'flex' : 'none' }}
              value={searchEntityValue}
              onChange={(e) => setSearchEntityValue(e.target.value)}
            />
          )}
        </Stack>
        <Stack sx={{ display: isExpanded ? 'unset' : 'none' }}>
          {isWorkflowLoading && (
            <Stack alignItems={'center'} sx={{ mt: 2 }} direction={'column'}>
              <Typography level={'body-lg'}>{t('workflow.loading')}</Typography>
            </Stack>
          )}
          {preparedEntities.map(({ entitiesForDoc, docType }) => (
            <Stack key={docType} direction="column" sx={{ width: '100%' }}>
              {!isWorkflowLoading &&
                entitiesForDoc.map(({ preparedSection, docKey }) => (
                  <DocEntities
                    key={docKey}
                    {...{
                      docType,
                      docKey,
                      preparedSection,
                      scrollOffset: height * 0.2,
                      entityGlobalAutoSelectRef,
                    }}
                    docExtraLabel={dossier!.docExtraLabelByDocKey[docKey]}
                  />
                ))}
            </Stack>
          ))}
        </Stack>
        {dossier && (
          <UserFeedbackModal
            open={isFeedbackModalOpen}
            onClose={() => setFeedbackModalOpen(false)}
            docTypes={Object.keys(dossier.remappedDocTypesByFileByPage)}
          />
        )}
      </div>
    </Card>
  );
};

export default EntitiesPanel;
