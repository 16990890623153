import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { uniqBy } from 'lodash';
import Rand from 'rand-seed';

import { infoByAnnotStatus } from '@/components/Entity/annotStatusConstants.tsx';
import { TEntityForDisplay } from '@/schemas/analysis.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { AnnotStatus } from '@/types';
import { ABRICO_ANNOTATION_PREFIX } from '@/utils/constants';

interface IAnnotInfo {
  X: number;
  Y: number;
  Width: number;
  Height: number;
  Id: string;
}

/**
 * Helper methods to draw human-like highlights (a bit random, going a bit to the left and right, etc.).
 * Contains some points transformation logic to make it work with rotation
 *
 * @param transformedText
 * @param annotInfo
 * @param rotation
 */
export const getHumanHighlightsPointsPathsAndThickness = ({
  transformedText,
  annotInfo,
  rotation,
}: {
  transformedText: string;
  annotInfo: IAnnotInfo;
  rotation: 0 | 1 | 2 | 3;
}): {
  paths: { start: { x: number; y: number }; end: { x: number; y: number } }[];
  thickness: number;
} => {
  // We want the annotations to look the same for the same entity
  const rand = new Rand(annotInfo.Id);
  const getRandomArbitrary = (min: number, max: number): number => {
    return rand.next() * (max - min) + min;
  };

  const lines = transformedText.split('\n');
  const numberOfLines = lines.length;
  const numberOfActualLines = lines.filter((line) => line.trim()).length;

  let [horizontalLength, verticalLength] =
    rotation === 0 || rotation === 2
      ? [annotInfo.Width, annotInfo.Height]
      : [annotInfo.Height, annotInfo.Width];

  // If the text is rotated, we hack around by swapping the horizontal and vertical lengths
  // and adding an extra rotation to the highlights
  // 1.2 is a magic number, we accept a bit of difference
  if (horizontalLength < 1.2 * (verticalLength / numberOfActualLines)) {
    rotation = ((rotation + 1) % 4) as 0 | 1 | 2 | 3;
    [horizontalLength, verticalLength] = [verticalLength, horizontalLength];
  }

  const out = [];
  for (let i = 0; i < numberOfLines; i++) {
    // We skip empty lines
    if (lines[i].trim().length === 0) continue;

    // highlight on the left side (most likely, extra on the left)
    const leftHorizontalOffset =
      getRandomArbitrary(-0.03, 0.04) * horizontalLength;
    // extra highlight on the right side (most likely extra on the right)
    const rightHorizontalOffset =
      getRandomArbitrary(-0.03, 0.07) * horizontalLength;
    // angle of the highlight, most likely: going a bit upward
    const verticalAngleOffset = getRandomArbitrary(-0.05, 0.1) * verticalLength;
    // Vertical center of the highlight, most likely: a bit higher
    const verticalOffset =
      numberOfLines > 1
        ? getRandomArbitrary(-0.01, 0.04) * verticalLength
        : getRandomArbitrary(-0.1, 0.4) * verticalLength;

    const isVerticalDirectionSwap = rotation === 2 || rotation === 3;
    // For multiline text, we want to highlight each line individually
    const verticalCoeff = ((i + 1) * 2 - 1) / (numberOfLines * 2);
    const globalVerticalOffset =
      (isVerticalDirectionSwap ? 1 - verticalCoeff : verticalCoeff) *
        verticalLength +
      (isVerticalDirectionSwap ? 1 : -1) * verticalOffset;
    const startVerticalOffset =
      globalVerticalOffset +
      (isVerticalDirectionSwap ? -1 : 1) * verticalAngleOffset;
    const endVerticalOffset =
      globalVerticalOffset +
      (isVerticalDirectionSwap ? 1 : -1) * verticalAngleOffset;

    switch (rotation) {
      /**
       * IMPORTANT
       *
       * Everything bellow has been hand crafter based on x/y axes changes with rotation, etc.
       * It can only be explained with drawings.
       * More info in https://www.notion.so/abrico/Apryze-Rotation-Coordinates-System-and-natural-hightlights-e2fab85f4f6f412b9bde690dcaf391bd
       */
      case 0: // No rotation
        out.push({
          start: {
            x: annotInfo.X - leftHorizontalOffset,
            y: annotInfo.Y + startVerticalOffset,
          },
          end: {
            x: annotInfo.X + horizontalLength + rightHorizontalOffset,
            y: annotInfo.Y + endVerticalOffset,
          },
        });
        break;
      case 1: // 90 degrees rotation
        out.push({
          start: {
            x: annotInfo.X + startVerticalOffset,
            y: annotInfo.Y + horizontalLength + leftHorizontalOffset,
          },
          end: {
            x: annotInfo.X + endVerticalOffset,
            y: annotInfo.Y - rightHorizontalOffset,
          },
        });
        break;
      case 2: // 180 degrees rotation
        out.push({
          start: {
            x: annotInfo.X + leftHorizontalOffset + horizontalLength,
            y: annotInfo.Y + startVerticalOffset,
          },
          end: {
            x: annotInfo.X - rightHorizontalOffset,
            y: annotInfo.Y + endVerticalOffset,
          },
        });
        break;
      case 3: // 270 degrees rotation
        out.push({
          start: {
            x: annotInfo.X + startVerticalOffset,
            y: annotInfo.Y - leftHorizontalOffset,
          },
          end: {
            x: annotInfo.X + endVerticalOffset,
            y: annotInfo.Y + horizontalLength + rightHorizontalOffset,
          },
        });
        break;
      default:
        throw new Error('Invalid rotation');
    }
  }

  return {
    paths: out,
    thickness:
      (verticalLength / numberOfActualLines) * getRandomArbitrary(1.14, 1.2),
  };
};

export const addBoundingBoxAsAnnotation = async (
  instance: WebViewerInstance,
  boundingBoxElements: TEntityForDisplay
) => {
  const { pageNumber, boundingBox } = boundingBoxElements;
  const { documentViewer, Annotations } = instance.Core;

  if (pageNumber > documentViewer.getDocument().getPageCount()) {
    console.warn(
      `Page ${pageNumber} doesn't exist in the document, skipping annotation creation`
    );
    return;
  }

  const pageWidth = documentViewer.getPageWidth(pageNumber);
  const pageHeight = documentViewer.getPageHeight(pageNumber);

  const annot = new Annotations.PolygonAnnotation({
    PageNumber: pageNumber,
    StrokeColor: new Annotations.Color(240, 33, 33, 0.8),
    StrokeThickness: 1,
    Style: 'dash',
    ReadOnly: true,
    // Listable: false, // if false, it can not be listed, nor selected, needs to be true to have the right cursor
    // In practrice we prevent the selection in JS on the event annotationSelected
    Id: boundingBoxElements.id,
    IsHoverable: true,
    // We need a fill color to have the hover cursor, we just do a very transparent one
    FillColor: new Annotations.Color(255, 255, 255, 0.01),
  });

  // Get the increase in dimensions
  const addedWidth = 3;
  const addedHeight = 3;
  const minX = Math.min(...boundingBox.map((point) => point.x));
  const minY = Math.min(...boundingBox.map((point) => point.y));

  // We need to close the polyline hence repeat of the first point
  [...boundingBox, boundingBox[0]].forEach((point) => {
    annot.addPathPoint(
      point.x * pageWidth + addedWidth * (point.x === minX ? -1 : 1),
      point.y * pageHeight + addedHeight * (point.y === minY ? -1 : 1)
    );
  });

  return annot;
};

export const drawHighlightAnnotation = (
  instance: WebViewerInstance,
  annotId: string,
  status: AnnotStatus,
  transformedText: string,
  addAndRedraw: boolean = true
): Core.Annotations.Annotation | undefined => {
  const { annotationManager, Annotations } = instance.Core;
  const selectedAnnot = annotationManager.getAnnotationById(annotId);
  if (!selectedAnnot) return;

  // to be backward compatible with the old annotations
  const { publicCode, highlightColor: color } = infoByAnnotStatus[status];
  const highlightColor = new Annotations.Color(...color);

  let annotHighLight;
  if (publicCode === 'highlight') {
    annotHighLight = new Annotations.RectangleAnnotation({
      PageNumber: selectedAnnot.PageNumber,
      X: selectedAnnot.X,
      Y: selectedAnnot.Y,
      Width: selectedAnnot.Width,
      Height: selectedAnnot.Height,
      StrokeColor: highlightColor,
      StrokeThickness: 2,
      Invisible: false,
      NoView: false,
      Opacity: 0.5,
      FillColor: highlightColor,
      Id: `${selectedAnnot.Id}-${publicCode}`,
      // User related
      NoMove: true,
      NoResize: true,
      // Page related
      NoRotate: false,
      NoZoom: false,
      Listable: false,
    });
  } else {
    annotHighLight = new Annotations.FreeHandAnnotation({
      PageNumber: selectedAnnot.PageNumber,
      StrokeColor: highlightColor,
      Opacity: 0.5,
      Id: `${selectedAnnot.Id}-${publicCode}`,
      Invisible: false,
      NoView: false,
      // User related
      NoMove: true,
      NoResize: true,
      // Page related
      NoRotate: false,
      NoZoom: false,
    });

    const rotation = instance.Core.documentViewer.getCompleteRotation(
      selectedAnnot.PageNumber
    );

    const { paths, thickness } = getHumanHighlightsPointsPathsAndThickness({
      transformedText,
      // @ts-ignore
      rotation,
      annotInfo: selectedAnnot,
    });
    paths.forEach((path, i) => {
      annotHighLight!.setPath(
        [
          new instance.Core.Math.Point(path.start.x, path.start.y),
          new instance.Core.Math.Point(path.end.x, path.end.y),
        ],
        i
      );
    });

    annotHighLight.StrokeThickness = thickness;
  }

  if (addAndRedraw) {
    annotationManager.addAnnotation(annotHighLight);
    annotationManager.redrawAnnotation(annotHighLight);
  }

  return annotHighLight;
};

export const reDrawBoundingBBoxAndAddMissingHighlights = async (
  newBoundingBoxList: TEntityForDisplay[],
  instance: WebViewerInstance
) => {
  const { Core } = instance;
  const { annotationManager } = Core;
  const annotations = annotationManager.getAnnotationsList();

  // A bit of logic to handle deletion of previously drawn bounding boxes if they have desappeared
  // note that we don't need to redraw the bounding boxes after a page reorder as they follow the page
  // FIXME: not perfect since the "id" might be stable while the boxes itself have changed
  // we would need to have real ids in the payload
  const oldIds: Set<string> = new Set(
    annotations
      .map((annot: any) => annot.Id)
      .filter((id: string) => id.startsWith(ABRICO_ANNOTATION_PREFIX))
  );
  const newIds = new Set(newBoundingBoxList.map((annot) => annot.id));

  const annotationIdsToDelete = new Set(
    [...oldIds].filter(
      (id) =>
        !newIds.has(id) &&
        !(id.endsWith('-validation') || id.endsWith('-rejection'))
    )
  );

  annotationManager.deleteAnnotations(
    annotations.filter((annot: any) => annotationIdsToDelete.has(annot.Id)),
    { force: true }
  );

  const boundingBoxToAdd = newBoundingBoxList.filter(
    (boundingBox) => !oldIds.has(boundingBox.id)
  );

  const annotsToAdd: Core.Annotations.Annotation[] = [];
  for (const boundingBox of boundingBoxToAdd) {
    try {
      const newAnnot = await addBoundingBoxAsAnnotation(instance, boundingBox);
      if (newAnnot) {
        annotsToAdd.push(newAnnot);
      }
    } catch (e) {
      console.error('Error while adding bounding box annotation', e);
      continue;
    }
    const status = useInteractionsState
      .getState()
      .entitiesStatuses.get(boundingBox.id);
    if (
      status &&
      status !== AnnotStatus.PENDING &&
      !oldIds.has(`${boundingBox.id}-${infoByAnnotStatus[status].publicCode}`)
    ) {
      const newAnnot = drawHighlightAnnotation(
        instance,
        boundingBox.id,
        status,
        boundingBox.transformedText,
        false
      );
      if (newAnnot) {
        annotsToAdd.push(newAnnot);
      }
    }
  }

  annotationManager.addAnnotations(annotsToAdd);
  // We call redrawAnnotation only once for each page for performance
  const uniqueAnnots = uniqBy(annotsToAdd, (annot) => annot.PageNumber);
  for (const annot of uniqueAnnots) {
    annotationManager.redrawAnnotation(annot);
  }
};

export const getEntitiesStatusFromAnnotations = (
  instance: WebViewerInstance
): Record<string, AnnotStatus> => {
  return Object.fromEntries(
    instance.Core.annotationManager
      .getAnnotationsList()
      .filter(
        (annot) =>
          annot.Id.startsWith(ABRICO_ANNOTATION_PREFIX) &&
          (annot.Id.endsWith('-validation') || annot.Id.endsWith('-rejection'))
      )
      .map((annot) => [
        annot.Id.replace(/-(validation|rejection)$/, ''),
        annot.Id.endsWith('-validation')
          ? AnnotStatus.VALIDATED
          : AnnotStatus.REJECTED,
      ])
  );
};

export const checkAnnotationShouldBeSaved = (
  annot: Core.Annotations.Annotation
): boolean => {
  if (annot.Id.startsWith(ABRICO_ANNOTATION_PREFIX)) {
    return annot.Id.endsWith('-validation') || annot.Id.endsWith('-rejection');
  }
  return true;
};
