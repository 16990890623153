import { z } from 'zod';

import { EnumByEnumName } from '@/abrico-lib-shared/generated/prisma-zod';

import i18n from './i18n';
import {
  ENumberUnit,
  InfoToZodObject,
  TFieldInfoEnum,
  TFieldInfoNumber,
  TFieldTypeInfo,
  TModelInfo,
} from './types';

export function getZodObjectFromInfo<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  U extends z.ZodObject<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TM extends TModelInfo<any, any>,
>(baseShape: U, info: TM): InfoToZodObject<U, TM> {
  const base = Object.fromEntries(
    Object.keys(info.fields ?? {}).map((k) => [k, baseShape.shape[k]])
  );
  const relatedFields = Object.fromEntries(
    Object.entries(info.relatedFields ?? {}).map(([k, v]) => [k, v.validator])
  );

  // @ts-expect-error not inferable
  return z.object({
    ...base,
    ...relatedFields,
    ...(info._table
      ? { id: z.string().cuid(), _table: z.literal(info._table).optional() }
      : {}),
  });
}

export type TZodFieldInfo = {
  isNullable: boolean;
} & (
  | {
      type: 'boolean';
    }
  | {
      type: 'enum';
      enumName: keyof typeof EnumByEnumName;
      options: { value: string; label: string }[];
    }
  | {
      type: 'number';
      unit: ENumberUnit | null;
      min: number | null;
      max: number | null;
      precision: number | null;
    }
  | {
      type: 'text';
      format: 'text' | 'phone' | 'mobile-phone' | 'email' | 'siret' | 'date';
    }
);

export function getTypeInfoFromConfig(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  el: z.ZodType<any, any>,
  extraInfo: TFieldTypeInfo | undefined,
  isNullable: boolean = false
): TZodFieldInfo {
  if (el instanceof z.ZodNullable) {
    return getTypeInfoFromConfig(el._def.innerType, extraInfo, true);
  }
  if (el instanceof z.ZodString) {
    return { type: 'text', isNullable, format: 'text' };
  } else if (el instanceof z.ZodNumber) {
    const typeInfo = (extraInfo as TFieldInfoNumber).typeInfo ?? {};
    const out = {
      type: 'number' as const,
      isNullable,
      unit: typeInfo.unit ?? null,
      min: typeInfo.min ?? null,
      max: typeInfo.max ?? null,
      precision: typeInfo.precision ?? null,
    };

    if (typeInfo.unit === ENumberUnit.CENTS_EUR) {
      out.precision = 2;
      out.min = 1;
    }
    return out;
  } else if (el instanceof z.ZodEnum) {
    const enumName = (extraInfo as TFieldInfoEnum).enumName!;
    return {
      type: 'enum',
      isNullable,
      enumName,
      options: Object.keys(EnumByEnumName[enumName]).map((value) => ({
        value,
        // @ts-ignore not possible to infer
        label: i18n.t(`shared:enums.${enumName}.${value}`),
      })),
    };
  } else if (el instanceof z.ZodBoolean) {
    return { type: 'boolean', isNullable };
  } else if (el instanceof z.ZodDate) {
    return { type: 'text', isNullable, format: 'date' };
  }
  throw new Error(`Unknown type, ${el.constructor.name}`);
}
