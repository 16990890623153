import { z } from 'zod';

/////////////////////////////////////////
// HELPER FUNCTIONS
/////////////////////////////////////////

// Deleted by helper
/////////////////////////////////////////

export const TransactionIsolationLevelSchema = z.enum([
  'ReadUncommitted',
  'ReadCommitted',
  'RepeatableRead',
  'Serializable',
]);

export const OAuthAccountScalarFieldEnumSchema = z.enum([
  'provider',
  'providerUserId',
  'userId',
]);

export const RelationLoadStrategySchema = z.enum(['query', 'join']);

export const SessionScalarFieldEnumSchema = z.enum([
  'id',
  'userId',
  'expiresAt',
]);

export const VerificationTokenScalarFieldEnumSchema = z.enum([
  'userId',
  'code',
  'token',
  'expires',
  'lastAttemptAt',
  'attempts',
  'email',
]);

export const SimulationScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'projectId',
  'isFromGlide',
  'glideChantierRowId',
  'isPartial',
  'ceeCents',
  'mprCents',
  'ceeMinCents',
  'ceeMaxCents',
  'mprMinCents',
  'mprMaxCents',
  'kwhC',
  'kwhCMin',
  'kwhCMax',
  'referenceCeeNbPersonInHousehold',
  'referenceCeeIncome',
  'referenceCeeIncomeYear',
  'categoryCeeIncome',
  'hasCoupDePoucePrecaire',
  'hasCoupDePouce',
  'missingParams',
  'abricoCeeMarginCents',
  'abricoCeeMarginMinCents',
  'abricoCeeMarginMaxCents',
]);

export const SimulationOpResScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'simulationId',
  'operationId',
  'isFromGlide',
  'glideOpRowId',
  'skip',
  'isPartial',
  'ceeCents',
  'mprCents',
  'ceeMinCents',
  'ceeMaxCents',
  'mprMinCents',
  'mprMaxCents',
  'kwhC',
  'kwhCMin',
  'kwhCMax',
  'isCeePrecaire',
  'hasCoupDePouce',
  'missingParams',
  'abricoCeeMarginCents',
  'abricoCeeMarginMinCents',
  'abricoCeeMarginMaxCents',
  'inputCeeJson',
]);

export const ProjectScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'mprSpecialWorksId',
  'beneficiaryId',
  'siteAddressId',
  'companyInstanceId',
  'latestSimuId',
  'bankFinancementId',
  'name',
  'description',
  'kind',
  'housingAge',
  'housingOwnerStatus',
  'housingType',
  'montantTtcCents',
  'internalNote',
  'mprAbricoPricing',
  'deperditionG',
  'temperatureC',
  'temperatureBase',
  'factureNumber',
  'devisNumber',
  'phase',
  'liveStatus',
  'hasEndedSiteWork',
  'isBeneficiaryOnboarded',
  'tmpSameBillingAddress',
  'subsidiesSubscriptionOption',
  'mprAccountCreated',
  'mprIdentityCheck',
  'mprIdentityCheckType',
  'mprNumber',
  'mprBenefReceivedConsentRequestEmail',
  'mprBenefConsentSent',
  'mprCerfaUploaded',
  'mprAbricoReceivedNominationEmail',
  'bankFinancementStatus',
  'emailToSignAhSentToBenef',
  'dossierReviewByOpsProgress_pre_work',
  'dossierReviewByOpsProgress_fix1stDepotKe',
  'dossierReviewByOpsProgress_fixLastDepotKe',
  'dossierReviewByOpsProgress_fix1stDepotMPR',
  'dossierReviewByOpsProgress_postWork',
  'dossierReviewByOpsProgress_fixPostWorkKe',
  'lastWorkflowRunTS',
  'nbKe1stDepot',
  'nbMpr1stDepot',
  'financingDepotStatus',
  'ke1stDepotStatus',
  'mpr1stDepotStatus',
  'financingDepotLastStatus',
  'keLastDepotStatus',
  'mprLastDepotStatus',
  'readyToAskDossierEdits1stDepot',
  'readyToAskDossierEditsLastDepotKe',
  'hasMprOctroi1',
  'hasMprOctroi2',
  'firstDepositTransferred',
  'dateTechnicalVisit',
  'dateDevisIssued',
  'dateDevisSigned',
  'dateRaiIssued',
  'dateStartWork',
  'dateEndWork',
  'dateFactureIssued',
  'needsAttestationPropriete',
  'isFromGlide',
  'glideChantierRowId',
  'glideMigrationIsOk',
]);

export const BankFinancementScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'borrowedCents',
  'numberOfMensualities',
  'interestRateArtisan',
  'interestRateBeneficiary',
  'hasDeposit',
  'depositRate',
  'hasDelayToPay',
  'delayToPayMonths',
  'beneficiaryInvalidationReason',
]);

export const MprSpecialWorksScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'hasDeposeCuveFioul',
  'montantTtcCentsDeposeCuveFioul',
  'hasAuditEnergetique',
  'montantTtcCentsAuditEnergetique',
  'hasVentilationDoubleFlux',
  'montantTtcCentsVentilationDoubleFlux',
]);

export const BeneficiaryScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'isFromGlide',
  'glideChantierRowId',
  'billingAddressId',
  'performInviteToAbrico',
  'invitedToAbrico',
  'connectedAtLeastOnce',
  'cguAccepted',
  'cgvAccepted',
  'isLegalEntity',
  'legalEntitySiret',
  'legalEntityRepFunction',
  'legalEntityName',
  'civility',
  'phone',
  'email',
  'firstName',
  'lastName',
]);

export const FormTrackerScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'projectId',
  'form',
  'lastOpenedAt',
  'lastSavedAt',
  'isCompleted',
  'stepsDetails',
]);

export const ArtisanScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'userId',
  'companyId',
  'isRepresentantLegal',
  'legalRepFunction',
  'phone',
  'civility',
  'firstName',
  'lastName',
  'isFromGlide',
  'glideCompanyRowId',
  'glideUserRowId',
]);

export const AddressScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'housenumber',
  'street',
  'addressAddition',
  'postcode',
  'city',
  'lat',
  'long',
  'country',
  'isFromGlide',
  'glideChantierRowId_siteAdress',
  'glideChantierRowId_billingAdress',
  'glideChantierRowId_companyInstance',
  'glideOpRowId_subContractorAdress',
  'glideCompanyRowId',
]);

export const CompanyScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'addressId',
  'cgvAccepted',
  'isDemo',
  'name',
  'siret',
  'vatNumber',
  'phone',
  'email',
  'description',
  'yearCreated',
  'employeeNumbers',
  'hasSecretariat',
  'hasBankFinancingManagement',
  'hasAccountActivated',
  'hasSubsidiesManagement',
  'isFromGlide',
  'glideCompanyRowId',
]);

export const CompanyBeneficiaryScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'companyId',
  'civility',
  'firstName',
  'lastName',
  'function',
]);

export const CompanyInstanceScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'originalCompanyId',
  'addressId',
  'name',
  'siret',
  'vatNumber',
  'phone',
  'email',
  'firstNameRep',
  'lastNameRep',
  'functionRep',
  'civilityRep',
  'isFromGlide',
  'glideChantierRowId_company',
  'glideOpRowId_subcontractor',
]);

export const AttestationRgeScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'documentId',
  'companyId',
  'workDomain',
  'siret',
  'validityStartDate',
  'validityEndDate',
]);

export const OperationScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'projectId',
  'subContractorCompanyInstanceId',
  'keOperationId',
  'lastKeOperationUpdateId',
  'isFromGlide',
  'glideOpRowId',
  'codeAdeme',
  'name',
  'hasSubcontractor',
  'dimensionnementByAbrico',
  'montantTtcCents',
  'isDenominationMentionned',
  'previousEquipment',
  'applicationType',
  'productionOption',
  'etas',
  'scop',
  'cop',
  'combustible',
  'woodHeatingType',
  'alimentationType',
  'insulationType',
  'insulationLocation',
  'coveredSurfaceM2',
  'mainEquipmentBrand',
  'mainEquipmentReference',
  'otherEquipmentsBrands',
  'otherEquipmentsReferences',
  'nominalPower',
  'waterHeatingOption',
  'profile',
  'hasAuxiliaryLiquidOrGasHeating',
  'siloVolume',
  'hasBufferTank',
  'productivity',
  'solarCertification',
  'isCoupledWithLowTemperatureCentralHeating',
  'regulatorClass',
  'regulatorBrand',
  'regulatorReference',
  'is7GreenStar',
  'fineParticles',
  'emissionNox',
  'emissionCo',
  'emissionCog',
  'thickness',
  'thermalResistance',
  'acermiCode',
  'hasPareVapeur',
  'atticInsulationType',
  'isHeatedAndConvertedAttic',
  'coverageRatePrct',
  'heatedVolumeM3',
  'dateDimensionnement',
  'hasChangedEquipmentSinceDevis',
]);

export const DocumentScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'latestVersionId',
  'projectId',
  'companyId',
  'operationId',
  'companyInstanceId',
  'beneficiaryId',
  'companyBeneficiaryId',
  'signatureSourceDocId',
  'docType',
  'codeAdeme',
  'photoType',
]);

export const DocumentVersionScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'isFromGlide',
  'glideDocRowId',
  'uploadedByUserId',
  'documentId',
  'signedVersionId',
  'duplicatedFromDocVersionId',
  'fileName',
  'mimeType',
  'bucketPath',
  'bucketName',
  'isLatest',
  'isValid',
  'reviewComment',
  'rejectedByKE',
  'stationDossierId',
  'generatedBy',
  'generationData',
  'missingFields',
  'photoDeviceInfo',
  'photoTS',
  'lat',
  'long',
]);

export const SignatureRequestScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'docVersionToSignId',
  'status',
  'yousignRequestId',
  'yousignDocumentId',
  'expireAt',
]);

export const SignatureRequestSignerScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'signatureRequestId',
  'signedBy',
  'status',
  'declineReason',
  'signatureLink',
  'signatureLinkExpireAt',
  'yousignSignerId',
]);

export const KEOperationUpdateScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'isLatest',
  'deletedAt',
  'operationId',
  'keStatus',
  'keSubStatus',
  'comment',
  'isFromGlide',
]);

export const TaxAssessmentScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isDeleted',
  'deletedAt',
  'documentId',
  'beneficiaryId',
  'firstDeclarantName',
  'incomeYear',
  'income',
  'nbPersonInHouseHold',
  'isFromGlide',
  'glideChantierRowId_n',
]);

export const TaskScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'isDeleted',
  'projectId',
  'nodeId',
  'spawnAt',
  'completedAt',
  'closedAt',
  'owners',
  'kind',
  'form',
  'comment',
]);

export const WorkflowRunScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'isDeleted',
  'projectId',
  'initialPhase',
  'previousCompletedNodeIds',
  'previousActiveNodeIds',
  'afterCompletedNodeIds',
  'afterActiveNodeIds',
  'actionsRun',
]);

export const UserScalarFieldEnumSchema = z.enum([
  'id',
  'createdAt',
  'updatedAt',
  'isFromGlide',
  'glideUserRowId',
  'name',
  'email',
  'isEmailVerified',
  'cguAccepted',
  'accountStatus',
  'image',
  'authorizations',
  'language',
  'lastLoginAt',
  'isDemo',
]);

export const SortOrderSchema = z.enum(['asc', 'desc']);

export const QueryModeSchema = z.enum(['default', 'insensitive']);

export const NullsOrderSchema = z.enum(['first', 'last']);

export const CategoryCeeIncomeSchema = z.enum([
  'REVENUS_TRES_MODESTES',
  'REVENUS_MODESTES',
  'AUTRES_MENAGES',
  'NOT_APPLICABLE',
]);

export type CategoryCeeIncomeType =
  `${z.infer<typeof CategoryCeeIncomeSchema>}`;

export const EDossierReviewByOpsProgressSchema = z.enum([
  'NOT_STARTED',
  'IN_PROGRESS',
  'DONE',
]);

export type EDossierReviewByOpsProgressType =
  `${z.infer<typeof EDossierReviewByOpsProgressSchema>}`;

export const EDossierDepotStatusSchema = z.enum([
  'NOT_DONE',
  'DONE_WAITING_FOR_RESPONSE',
  'CHANGES_REQUESTED',
  'SUCCESS_APPROVED',
  'CRITICAL_FAILURE',
]);

export type EDossierDepotStatusType =
  `${z.infer<typeof EDossierDepotStatusSchema>}`;

export const EProjectLiveStatusSchema = z.enum([
  'ACTIVE',
  'PAUSED',
  'CANCELLED',
]);

export type EProjectLiveStatusType =
  `${z.infer<typeof EProjectLiveStatusSchema>}`;

export const EBankFinancementStatusSchema = z.enum([
  'NOT_APPLICABLE',
  'WIP_ARTISAN',
  'VALIDATED_BY_ARTISAN',
  'VALIDATED_BY_BENEFICIARY',
  'INVALIDATED_BY_BENEFICIARY',
]);

export type EBankFinancementStatusType =
  `${z.infer<typeof EBankFinancementStatusSchema>}`;

export const EProjectKindSchema = z.enum([
  'REAL_PROJECT',
  'SIMULATION',
  'NOTE_DE_DIM',
]);

export type EProjectKindType = `${z.infer<typeof EProjectKindSchema>}`;

export const EHousingAgeSchema = z.enum([
  'LESS_THAN_2',
  'BETWEEN_2_AND_15',
  'MORE_THAN_15',
]);

export type EHousingAgeType = `${z.infer<typeof EHousingAgeSchema>}`;

export const EHousingOwnerStatusSchema = z.enum([
  'OWNER_PRIMARY_RESIDENCE',
  'OWNER_SECONDARY_RESIDENCE',
  'RENTAL_SHORT_TERM',
  'RENTAL_AS_PRIMARY_RESIDENCE',
]);

export type EHousingOwnerStatusType =
  `${z.infer<typeof EHousingOwnerStatusSchema>}`;

export const EProjectPhaseSchema = z.enum([
  'COMMERCIAL_INIT',
  'INIT_PROJECT_PRE_WORK_DOSSIER',
  'REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER',
  'SIGN_BEFORE_WORK_START',
  'PREWORK_SUBMISSION_AND_PROJECT_IN_PROGRESS_ALL_IN_ONE',
  'END_OF_PROJECT_DOSSIER_CREATION',
  'END_OF_PROJECT_DOSSIER_REVIEW',
  'SIGN_AFTER_WORK_END',
  'END_OF_PROJECT_ALL_IN_ONE',
  'PROJECT_COMPLETELY_DONE',
]);

export type EProjectPhaseType = `${z.infer<typeof EProjectPhaseSchema>}`;

export const EPhaseMPRSchema = z.enum(['START_PROJECT']);

export type EPhaseMPRType = `${z.infer<typeof EPhaseMPRSchema>}`;

export const EMPRIdentityCheckTypeSchema = z.enum([
  'FRENCH_CONNECT',
  'POSTAL_DELIVERY',
]);

export type EMPRIdentityCheckTypeType =
  `${z.infer<typeof EMPRIdentityCheckTypeSchema>}`;

export const ESubsidiesSubscriptionOptionSchema = z.enum([
  'CEE_ONLY',
  'CEE_MPR',
  'NO_SUBSIDIES',
]);

export type ESubsidiesSubscriptionOptionType =
  `${z.infer<typeof ESubsidiesSubscriptionOptionSchema>}`;

export const EMPRAbricoPricingSchema = z.enum(['PERCENT_8', 'MIN_89_MAX_249']);

export type EMPRAbricoPricingType =
  `${z.infer<typeof EMPRAbricoPricingSchema>}`;

export const EFormSchema = z.enum([
  'FORM_ONBOARD_BENEF_PROJECT',
  'FORM_CREATE_PROJECT_ARTISAN',
  'FORM_SIGN_DEVIS_RAI',
  'FORM_END_PROJECT_ARTISAN',
  'FORM_FIX_REVIEW_ARTISAN',
  'FORM_FIX_REVIEW_BENEF',
  'FORM_SIGN_AH',
  'FORM_MPR_REDO_BENEF',
]);

export type EFormType = `${z.infer<typeof EFormSchema>}`;

export const ECivilitySchema = z.enum(['MR', 'MME']);

export type ECivilityType = `${z.infer<typeof ECivilitySchema>}`;

export const EWorkDomainSchema = z.enum([
  'EXTERIOR_WALL_INSULATION',
  'INTERIOR_WALL_AND_ROOF_SLOPE_INSULATION',
  'LOST_ATTIC_INSULATION',
  'INSULATION_OF_GROUND_FLOORS',
  'WOOD_FIRED_BOILER',
  'WOOD_BURNING_STOVE_OR_INSERT',
  'THERMODYNAMIC_WATER_HEATER',
  'HEAT_PUMP_ONLY_AIR_HEATING',
]);

export type EWorkDomainType = `${z.infer<typeof EWorkDomainSchema>}`;

export const ESignatureStatusSchema = z.enum([
  'DRAFT',
  'ONGOING',
  'DONE',
  'DELETED',
  'EXPIRED',
  'CANCELED',
  'APPROVAL',
  'REJECTED',
  'DECLINED',
]);

export type ESignatureStatusType = `${z.infer<typeof ESignatureStatusSchema>}`;

export const ESignerStatusSchema = z.enum([
  'INITIATED',
  'DECLINED',
  'NOTIFIED',
  'VERIFIED',
  'PROCESSING',
  'CONSENT_GIVEN',
  'SIGNED',
  'ABORTED',
  'ERROR',
]);

export type ESignerStatusType = `${z.infer<typeof ESignerStatusSchema>}`;

export const ECountrySchema = z.enum(['France']);

export type ECountryType = `${z.infer<typeof ECountrySchema>}`;

export const EDocTypeSchema = z.enum([
  'ACCORD_ARTISAN',
  'ATTESTATION_CHANGEMENT_MATERIEL',
  'ATTESTATION_CONSENTEMENT_NOT_SIGNED',
  'ATTESTATION_PROPRIETE',
  'ATTESTATION_REGULARITE_FISCALE',
  'ATTESTATION_RGE',
  'ATTESTATION_SUR_HONNEUR_NOT_SIGNED',
  'ATTESTATION_SUR_HONNEUR_SIGNED',
  'ATTESTION_REGULARITE_URSSAF',
  'AVIS_IMPOSITION',
  'CADRE_CONTRIBUTION_NOT_SIGNED',
  'CADRE_CONTRIBUTION_SIGNED',
  'CERTIFICAT_ACERMI',
  'CERTIFICAT_CADRE_CONTRIBUTION',
  'CERTIFICAT_CAPTEUR_SOLAIRE',
  'CNI',
  'DEVIS_NOT_SIGNED',
  'RAI_DEVIS_NOT_SIGNED',
  'DEVIS_SIGNED',
  'FACTURE',
  'FICHE_TECHNIQUE',
  'KBIS',
  'MANDAT_ANAH_NOT_SIGNED',
  'MANDAT_ANAH_SIGNED',
  'NOTE_DIMENSIONNEMENT',
  'OTHER',
  'PHOTO',
  'RIB',
  'COMPANY_STATUS',
  'DECENNIAL_INSURANCE',
  'BALANCE_SHEET',
]);

export type EDocTypeType = `${z.infer<typeof EDocTypeSchema>}`;

export const EPhotoTypeSchema = z.enum([
  'HEAT_PUMP_EXTERIR_UNIT',
  'HEAT_PUMP_INDOOR_UNIT',
  'INSULATION_OUTDOOR_WALL',
  'SOLAR_PANELS',
  'GENERAL',
  'SILO',
  'CIRCUIT_BREAKERS',
  'LAGGING',
]);

export type EPhotoTypeType = `${z.infer<typeof EPhotoTypeSchema>}`;

export const ECodeAdemeSchema = z.enum([
  'BAR_EN_101',
  'BAR_EN_102',
  'BAR_EN_103',
  'BAR_TH_112',
  'BAR_TH_113',
  'BAR_TH_129',
  'BAR_TH_143',
  'BAR_TH_148',
  'BAR_TH_159',
  'BAR_TH_171',
  'BAR_TH_172',
]);

export type ECodeAdemeType = `${z.infer<typeof ECodeAdemeSchema>}`;

export const ETaskKindSchema = z.enum([
  'FORM_TO_FILL',
  'WAIT_EXTERNAL',
  'OPS_REVIEW',
  'OPS_MANUAL_ACTION',
]);

export type ETaskKindType = `${z.infer<typeof ETaskKindSchema>}`;

export const EStakeholderSchema = z.enum([
  'BENEFICIARY',
  'ARTISAN',
  'OPS_ABRICO',
]);

export type EStakeholderType = `${z.infer<typeof EStakeholderSchema>}`;

export const EPreviousEquipmentSchema = z.enum([
  'ELECTRIC_HEATING',
  'OIL_BOILER',
  'GAS_BOILER',
  'WOOD_BOILER',
  'HEAT_PUMP',
  'COAL_BOILER',
  'GAS_CONDENSING_BOILER',
  'OTHER',
]);

export type EPreviousEquipmentType =
  `${z.infer<typeof EPreviousEquipmentSchema>}`;

export const EApplicationTypeSchema = z.enum([
  'MEDIUM_OR_HIGH_TEMPERATURE',
  'LOW_TEMPERATURE',
]);

export type EApplicationTypeType = `${z.infer<typeof EApplicationTypeSchema>}`;

export const EProductionOptionSchema = z.enum([
  'AIR_HEATING_AND_HOT_WATER',
  'AIR_HEATING_ONLY',
]);

export type EProductionOptionType =
  `${z.infer<typeof EProductionOptionSchema>}`;

export const EHousingTypeSchema = z.enum(['HOUSE', 'FLAT']);

export type EHousingTypeType = `${z.infer<typeof EHousingTypeSchema>}`;

export const EWaterHeatingOptionSchema = z.enum(['EXTRACTED_AIR', 'OTHER']);

export type EWaterHeatingOptionType =
  `${z.infer<typeof EWaterHeatingOptionSchema>}`;

export const ECombustibleSchema = z.enum(['GRANULES', 'LOGS', 'OTHER']);

export type ECombustibleType = `${z.infer<typeof ECombustibleSchema>}`;

export const EWoodHeatingTypeSchema = z.enum([
  'WOOD_BURNER',
  'CLOSED_FIREPLACE',
  'FIREPLACE_INSERT',
  'COOKER',
]);

export type EWoodHeatingTypeType = `${z.infer<typeof EWoodHeatingTypeSchema>}`;

export const EInsulationTypeSchema = z.enum(['INDOOR', 'OUTDOOR']);

export type EInsulationTypeType = `${z.infer<typeof EInsulationTypeSchema>}`;

export const EInsulationLocationSchema = z.enum(['ATTIC', 'ROOF']);

export type EInsulationLocationType =
  `${z.infer<typeof EInsulationLocationSchema>}`;

export const EAtticInsulationTypeSchema = z.enum([
  'RAMPANT_DE_TOITURE',
  'COMBLE_PERDU',
]);

export type EAtticInsulationTypeType =
  `${z.infer<typeof EAtticInsulationTypeSchema>}`;

export const EAlimentationTypeSchema = z.enum(['AUTOMATED', 'MANUAL']);

export type EAlimentationTypeType =
  `${z.infer<typeof EAlimentationTypeSchema>}`;

export const EProfileSchema = z.enum(['M', 'L', 'XL', 'XXL']);

export type EProfileType = `${z.infer<typeof EProfileSchema>}`;

export const ERegulatorClassSchema = z.enum([
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
]);

export type ERegulatorClassType = `${z.infer<typeof ERegulatorClassSchema>}`;

export const ESolarCertificationSchema = z.enum([
  'CSTBAT',
  'SLOARKEYMARK',
  'EQUIVALENT',
  'OTHER',
]);

export type ESolarCertificationType =
  `${z.infer<typeof ESolarCertificationSchema>}`;

export const EKEStatusSchema = z.enum([
  'PRE_OPERATION',
  'TO_BE_VERIFIED',
  'PENDING_REGULARIZATION',
  'TO_BE_INSPECTED',
  'UNDER_INSPECTION',
  'CUSTOMER_SERVICE',
  'CORRECTIVE_ACTIONS',
  'TELEPHONE_VERIFICATION_PENDING',
  'TELEPHONE_VERIFICATION_IN_PROGRESS',
  'ELIGIBLE',
  'NOT_ELIGIBLE',
  'EXPIRED',
  'ABANDONED',
]);

export type EKEStatusType = `${z.infer<typeof EKEStatusSchema>}`;

export const EKESubStatusSchema = z.enum([
  'SIGNED_QUOTATION_TO_BE_VERIFIED',
  'SIGNED_QUOTATION_PENDING_REGULARIZATION',
  'SIGNED_QUOTATION_VALIDATED',
]);

export type EKESubStatusType = `${z.infer<typeof EKESubStatusSchema>}`;

export const AccountStatusSchema = z.enum([
  'ENABLED',
  'DISABLED',
  'NOT_VERIFIED',
]);

export type AccountStatusType = `${z.infer<typeof AccountStatusSchema>}`;

export const UserRoleSchema = z.enum(['APP', 'SIMULATIONS_ONLY', 'ADMIN']);

export type UserRoleType = `${z.infer<typeof UserRoleSchema>}`;

/////////////////////////////////////////
// MODELS
/////////////////////////////////////////

/////////////////////////////////////////
// O AUTH ACCOUNT SCHEMA
/////////////////////////////////////////

export const OAuthAccountSchema = z.object({
  provider: z.string(),
  providerUserId: z.string(),
  userId: z.string(),
});

export type OAuthAccount = z.infer<typeof OAuthAccountSchema>;

/////////////////////////////////////////
// SESSION SCHEMA
/////////////////////////////////////////

export const SessionSchema = z.object({
  id: z.string(),
  userId: z.string(),
  expiresAt: z.coerce.date(),
});

export type Session = z.infer<typeof SessionSchema>;

/////////////////////////////////////////
// VERIFICATION TOKEN SCHEMA
/////////////////////////////////////////

export const VerificationTokenSchema = z.object({
  userId: z.string(),
  code: z.string(),
  token: z.string(),
  expires: z.coerce.date(),
  lastAttemptAt: z.coerce.date(),
  attempts: z.number().int(),
  email: z.string().nullable(),
});

export type VerificationToken = z.infer<typeof VerificationTokenSchema>;

/////////////////////////////////////////
// SIMULATION SCHEMA
/////////////////////////////////////////

export const SimulationSchema = z.object({
  categoryCeeIncome: CategoryCeeIncomeSchema.nullable(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  projectId: z.string(),
  isFromGlide: z.boolean(),
  glideChantierRowId: z.string().nullable(),
  isPartial: z.boolean(),
  ceeCents: z.number().int().nullable(),
  mprCents: z.number().int().nullable(),
  ceeMinCents: z.number().int().nullable(),
  ceeMaxCents: z.number().int().nullable(),
  mprMinCents: z.number().int().nullable(),
  mprMaxCents: z.number().int().nullable(),
  kwhC: z.number().int().nullable(),
  kwhCMin: z.number().int().nullable(),
  kwhCMax: z.number().int().nullable(),
  referenceCeeNbPersonInHousehold: z.number().int().nullable(),
  referenceCeeIncome: z.number().int().nullable(),
  referenceCeeIncomeYear: z.number().int().nullable(),
  hasCoupDePoucePrecaire: z.boolean().nullable(),
  hasCoupDePouce: z.boolean().nullable(),
  missingParams: z.string().array(),
  abricoCeeMarginCents: z.number().int().nullable(),
  abricoCeeMarginMinCents: z.number().int().nullable(),
  abricoCeeMarginMaxCents: z.number().int().nullable(),
});

export type Simulation = z.infer<typeof SimulationSchema>;

/////////////////////////////////////////
// SIMULATION OP RES SCHEMA
/////////////////////////////////////////

export const SimulationOpResSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  simulationId: z.string(),
  operationId: z.string(),
  isFromGlide: z.boolean(),
  glideOpRowId: z.string().nullable(),
  skip: z.boolean(),
  isPartial: z.boolean(),
  ceeCents: z.number().int().nullable(),
  mprCents: z.number().int().nullable(),
  ceeMinCents: z.number().int().nullable(),
  ceeMaxCents: z.number().int().nullable(),
  mprMinCents: z.number().int().nullable(),
  mprMaxCents: z.number().int().nullable(),
  kwhC: z.number().int().nullable(),
  kwhCMin: z.number().int().nullable(),
  kwhCMax: z.number().int().nullable(),
  isCeePrecaire: z.boolean().nullable(),
  hasCoupDePouce: z.boolean().nullable(),
  missingParams: z.string().array(),
  abricoCeeMarginCents: z.number().int().nullable(),
  abricoCeeMarginMinCents: z.number().int().nullable(),
  abricoCeeMarginMaxCents: z.number().int().nullable(),
  inputCeeJson: z.any(),
});

export type SimulationOpRes = z.infer<typeof SimulationOpResSchema>;

/////////////////////////////////////////
// PROJECT SCHEMA
/////////////////////////////////////////

export const ProjectSchema = z.object({
  kind: EProjectKindSchema,
  housingAge: EHousingAgeSchema.nullable(),
  housingOwnerStatus: EHousingOwnerStatusSchema.nullable(),
  housingType: EHousingTypeSchema.nullable(),
  mprAbricoPricing: EMPRAbricoPricingSchema,
  phase: EProjectPhaseSchema,
  liveStatus: EProjectLiveStatusSchema,
  subsidiesSubscriptionOption: ESubsidiesSubscriptionOptionSchema.nullable(),
  mprIdentityCheckType: EMPRIdentityCheckTypeSchema.nullable(),
  bankFinancementStatus: EBankFinancementStatusSchema.nullable(),
  dossierReviewByOpsProgress_pre_work: EDossierReviewByOpsProgressSchema,
  dossierReviewByOpsProgress_fix1stDepotKe: EDossierReviewByOpsProgressSchema,
  dossierReviewByOpsProgress_fixLastDepotKe: EDossierReviewByOpsProgressSchema,
  dossierReviewByOpsProgress_fix1stDepotMPR: EDossierReviewByOpsProgressSchema,
  dossierReviewByOpsProgress_postWork: EDossierReviewByOpsProgressSchema,
  dossierReviewByOpsProgress_fixPostWorkKe: EDossierReviewByOpsProgressSchema,
  financingDepotStatus: EDossierDepotStatusSchema,
  ke1stDepotStatus: EDossierDepotStatusSchema,
  mpr1stDepotStatus: EDossierDepotStatusSchema,
  financingDepotLastStatus: EDossierDepotStatusSchema,
  keLastDepotStatus: EDossierDepotStatusSchema,
  mprLastDepotStatus: EDossierDepotStatusSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  mprSpecialWorksId: z.string(),
  beneficiaryId: z.string(),
  siteAddressId: z.string(),
  companyInstanceId: z.string(),
  latestSimuId: z.string().nullable(),
  bankFinancementId: z.string().nullable(),
  name: z.string(),
  description: z.string(),
  montantTtcCents: z.number().int().nullable(),
  internalNote: z.string(),
  deperditionG: z.number().nullable(),
  temperatureC: z.number().nullable(),
  temperatureBase: z.number().nullable(),
  factureNumber: z.string().nullable(),
  devisNumber: z.string().nullable(),
  hasEndedSiteWork: z.boolean(),
  isBeneficiaryOnboarded: z.boolean(),
  tmpSameBillingAddress: z.boolean(),
  mprAccountCreated: z.boolean().nullable(),
  mprIdentityCheck: z.boolean().nullable(),
  mprNumber: z.string().nullable(),
  mprBenefReceivedConsentRequestEmail: z.boolean(),
  mprBenefConsentSent: z.boolean(),
  mprCerfaUploaded: z.boolean().nullable(),
  mprAbricoReceivedNominationEmail: z.boolean(),
  emailToSignAhSentToBenef: z.boolean(),
  lastWorkflowRunTS: z.coerce.date().nullable(),
  nbKe1stDepot: z.number().int(),
  nbMpr1stDepot: z.number().int(),
  readyToAskDossierEdits1stDepot: z.boolean(),
  readyToAskDossierEditsLastDepotKe: z.boolean(),
  hasMprOctroi1: z.boolean(),
  hasMprOctroi2: z.boolean(),
  firstDepositTransferred: z.boolean(),
  dateTechnicalVisit: z.coerce.date().nullable(),
  dateDevisIssued: z.coerce.date().nullable(),
  dateDevisSigned: z.coerce.date().nullable(),
  dateRaiIssued: z.coerce.date().nullable(),
  dateStartWork: z.coerce.date().nullable(),
  dateEndWork: z.coerce.date().nullable(),
  dateFactureIssued: z.coerce.date().nullable(),
  needsAttestationPropriete: z.boolean(),
  isFromGlide: z.boolean(),
  glideChantierRowId: z.string().nullable(),
  glideMigrationIsOk: z.boolean(),
});

export type Project = z.infer<typeof ProjectSchema>;

/////////////////////////////////////////
// BANK FINANCEMENT SCHEMA
/////////////////////////////////////////

export const BankFinancementSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  borrowedCents: z.number().int().nullable(),
  numberOfMensualities: z.number().int().nullable(),
  interestRateArtisan: z.number().nullable(),
  interestRateBeneficiary: z.number().nullable(),
  hasDeposit: z.boolean().nullable(),
  depositRate: z.number().nullable(),
  hasDelayToPay: z.boolean().nullable(),
  delayToPayMonths: z.number().int().nullable(),
  beneficiaryInvalidationReason: z.string().nullable(),
});

export type BankFinancement = z.infer<typeof BankFinancementSchema>;

/////////////////////////////////////////
// MPR SPECIAL WORKS SCHEMA
/////////////////////////////////////////

export const MprSpecialWorksSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  hasDeposeCuveFioul: z.boolean(),
  montantTtcCentsDeposeCuveFioul: z.number().int().nullable(),
  hasAuditEnergetique: z.boolean(),
  montantTtcCentsAuditEnergetique: z.number().int().nullable(),
  hasVentilationDoubleFlux: z.boolean(),
  montantTtcCentsVentilationDoubleFlux: z.number().int().nullable(),
});

export type MprSpecialWorks = z.infer<typeof MprSpecialWorksSchema>;

/////////////////////////////////////////
// BENEFICIARY SCHEMA
/////////////////////////////////////////

export const BeneficiarySchema = z.object({
  civility: ECivilitySchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  isFromGlide: z.boolean(),
  glideChantierRowId: z.string().nullable(),
  billingAddressId: z.string(),
  performInviteToAbrico: z.boolean(),
  invitedToAbrico: z.boolean(),
  connectedAtLeastOnce: z.boolean(),
  cguAccepted: z.boolean().nullable(),
  cgvAccepted: z.boolean().nullable(),
  isLegalEntity: z.boolean(),
  legalEntitySiret: z.string().nullable(),
  legalEntityRepFunction: z.string().nullable(),
  legalEntityName: z.string().nullable(),
  phone: z.string().nullable(),
  email: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
});

export type Beneficiary = z.infer<typeof BeneficiarySchema>;

/////////////////////////////////////////
// FORM TRACKER SCHEMA
/////////////////////////////////////////

export const FormTrackerSchema = z.object({
  form: EFormSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  projectId: z.string(),
  lastOpenedAt: z.coerce.date().nullable(),
  lastSavedAt: z.coerce.date().nullable(),
  isCompleted: z.boolean().nullable(),
  stepsDetails: z.any(),
});

export type FormTracker = z.infer<typeof FormTrackerSchema>;

/////////////////////////////////////////
// ARTISAN SCHEMA
/////////////////////////////////////////

export const ArtisanSchema = z.object({
  civility: ECivilitySchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  userId: z.string(),
  companyId: z.string(),
  isRepresentantLegal: z.boolean(),
  legalRepFunction: z.string().nullable(),
  phone: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isFromGlide: z.boolean(),
  glideCompanyRowId: z.string().nullable(),
  glideUserRowId: z.string().nullable(),
});

export type Artisan = z.infer<typeof ArtisanSchema>;

/////////////////////////////////////////
// ADDRESS SCHEMA
/////////////////////////////////////////

export const AddressSchema = z.object({
  country: ECountrySchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  housenumber: z.string().nullable(),
  street: z.string().nullable(),
  addressAddition: z.string().nullable(),
  postcode: z.string().nullable(),
  city: z.string().nullable(),
  lat: z.number().nullable(),
  long: z.number().nullable(),
  isFromGlide: z.boolean(),
  glideChantierRowId_siteAdress: z.string().nullable(),
  glideChantierRowId_billingAdress: z.string().nullable(),
  glideChantierRowId_companyInstance: z.string().nullable(),
  glideOpRowId_subContractorAdress: z.string().nullable(),
  glideCompanyRowId: z.string().nullable(),
});

export type Address = z.infer<typeof AddressSchema>;

/////////////////////////////////////////
// COMPANY SCHEMA
/////////////////////////////////////////

export const CompanySchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  addressId: z.string(),
  cgvAccepted: z.boolean(),
  isDemo: z.boolean(),
  name: z.string(),
  siret: z.string(),
  vatNumber: z.string().nullable(),
  phone: z.string().nullable(),
  email: z.string().nullable(),
  description: z.string().nullable(),
  yearCreated: z.number().int().nullable(),
  employeeNumbers: z.number().int().nullable(),
  hasSecretariat: z.boolean(),
  hasBankFinancingManagement: z.boolean().nullable(),
  hasAccountActivated: z.boolean(),
  hasSubsidiesManagement: z.boolean().nullable(),
  isFromGlide: z.boolean(),
  glideCompanyRowId: z.string().nullable(),
});

export type Company = z.infer<typeof CompanySchema>;

/////////////////////////////////////////
// COMPANY BENEFICIARY SCHEMA
/////////////////////////////////////////

export const CompanyBeneficiarySchema = z.object({
  civility: ECivilitySchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  companyId: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  function: z.string().nullable(),
});

export type CompanyBeneficiary = z.infer<typeof CompanyBeneficiarySchema>;

/////////////////////////////////////////
// COMPANY INSTANCE SCHEMA
/////////////////////////////////////////

export const CompanyInstanceSchema = z.object({
  civilityRep: ECivilitySchema.nullable(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  originalCompanyId: z.string().nullable(),
  addressId: z.string(),
  name: z.string().nullable(),
  siret: z.string().nullable(),
  vatNumber: z.string().nullable(),
  phone: z.string().nullable(),
  email: z.string().nullable(),
  firstNameRep: z.string().nullable(),
  lastNameRep: z.string().nullable(),
  functionRep: z.string().nullable(),
  isFromGlide: z.boolean(),
  glideChantierRowId_company: z.string().nullable(),
  glideOpRowId_subcontractor: z.string().nullable(),
});

export type CompanyInstance = z.infer<typeof CompanyInstanceSchema>;

/////////////////////////////////////////
// ATTESTATION RGE SCHEMA
/////////////////////////////////////////

export const AttestationRgeSchema = z.object({
  workDomain: EWorkDomainSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  documentId: z.string(),
  companyId: z.string(),
  siret: z.string(),
  validityStartDate: z.coerce.date(),
  validityEndDate: z.coerce.date(),
});

export type AttestationRge = z.infer<typeof AttestationRgeSchema>;

/////////////////////////////////////////
// OPERATION SCHEMA
/////////////////////////////////////////

export const OperationSchema = z.object({
  codeAdeme: ECodeAdemeSchema,
  previousEquipment: EPreviousEquipmentSchema.nullable(),
  applicationType: EApplicationTypeSchema.nullable(),
  productionOption: EProductionOptionSchema.nullable(),
  combustible: ECombustibleSchema.nullable(),
  woodHeatingType: EWoodHeatingTypeSchema.nullable(),
  alimentationType: EAlimentationTypeSchema.nullable(),
  insulationType: EInsulationTypeSchema.nullable(),
  insulationLocation: EInsulationLocationSchema.nullable(),
  waterHeatingOption: EWaterHeatingOptionSchema.nullable(),
  profile: EProfileSchema.nullable(),
  solarCertification: ESolarCertificationSchema.nullable(),
  regulatorClass: ERegulatorClassSchema.nullable(),
  atticInsulationType: EAtticInsulationTypeSchema.nullable(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  projectId: z.string(),
  subContractorCompanyInstanceId: z.string(),
  keOperationId: z.string().nullable(),
  lastKeOperationUpdateId: z.string().nullable(),
  isFromGlide: z.boolean(),
  glideOpRowId: z.string().nullable(),
  name: z.string(),
  hasSubcontractor: z.boolean(),
  dimensionnementByAbrico: z.boolean().nullable(),
  montantTtcCents: z.number().int().nullable(),
  isDenominationMentionned: z.boolean().nullable(),
  etas: z.number().int().nullable(),
  scop: z.number().nullable(),
  cop: z.number().nullable(),
  coveredSurfaceM2: z.number().int().nullable(),
  mainEquipmentBrand: z.string().nullable(),
  mainEquipmentReference: z.string().nullable(),
  otherEquipmentsBrands: z.string().nullable(),
  otherEquipmentsReferences: z.string().nullable(),
  nominalPower: z.number().nullable(),
  hasAuxiliaryLiquidOrGasHeating: z.boolean().nullable(),
  siloVolume: z.number().int().nullable(),
  hasBufferTank: z.boolean().nullable(),
  productivity: z.number().int().nullable(),
  isCoupledWithLowTemperatureCentralHeating: z.boolean().nullable(),
  regulatorBrand: z.string().nullable(),
  regulatorReference: z.string().nullable(),
  is7GreenStar: z.boolean().nullable(),
  fineParticles: z.number().nullable(),
  emissionNox: z.number().nullable(),
  emissionCo: z.number().nullable(),
  emissionCog: z.number().nullable(),
  thickness: z.number().int().nullable(),
  thermalResistance: z.number().nullable(),
  acermiCode: z.string().nullable(),
  hasPareVapeur: z.boolean().nullable(),
  isHeatedAndConvertedAttic: z.boolean().nullable(),
  coverageRatePrct: z.number().int().nullable(),
  heatedVolumeM3: z.number().int(),
  dateDimensionnement: z.coerce.date().nullable(),
  hasChangedEquipmentSinceDevis: z.boolean(),
});

export type Operation = z.infer<typeof OperationSchema>;

/////////////////////////////////////////
// DOCUMENT SCHEMA
/////////////////////////////////////////

export const DocumentSchema = z.object({
  docType: EDocTypeSchema,
  codeAdeme: ECodeAdemeSchema.nullable(),
  photoType: EPhotoTypeSchema.nullable(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  latestVersionId: z.string().nullable(),
  projectId: z.string().nullable(),
  companyId: z.string().nullable(),
  operationId: z.string().nullable(),
  companyInstanceId: z.string().nullable(),
  beneficiaryId: z.string().nullable(),
  companyBeneficiaryId: z.string().nullable(),
  signatureSourceDocId: z.string().nullable(),
});

export type Document = z.infer<typeof DocumentSchema>;

/////////////////////////////////////////
// DOCUMENT VERSION SCHEMA
/////////////////////////////////////////

export const DocumentVersionSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  isFromGlide: z.boolean(),
  glideDocRowId: z.string().nullable(),
  uploadedByUserId: z.string().nullable(),
  documentId: z.string(),
  signedVersionId: z.string().nullable(),
  duplicatedFromDocVersionId: z.string().nullable(),
  fileName: z.string(),
  mimeType: z.string(),
  bucketPath: z.string(),
  bucketName: z.string(),
  isLatest: z.boolean(),
  isValid: z.boolean().nullable(),
  reviewComment: z.string().nullable(),
  rejectedByKE: z.boolean(),
  stationDossierId: z.string(),
  generatedBy: z.string().nullable(),
  generationData: z.any(),
  missingFields: z.string().array(),
  photoDeviceInfo: z.string().nullable(),
  photoTS: z.coerce.date().nullable(),
  lat: z.number().nullable(),
  long: z.number().nullable(),
});

export type DocumentVersion = z.infer<typeof DocumentVersionSchema>;

/////////////////////////////////////////
// SIGNATURE REQUEST SCHEMA
/////////////////////////////////////////

export const SignatureRequestSchema = z.object({
  status: ESignatureStatusSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  docVersionToSignId: z.string(),
  yousignRequestId: z.string(),
  yousignDocumentId: z.string(),
  expireAt: z.coerce.date(),
});

export type SignatureRequest = z.infer<typeof SignatureRequestSchema>;

/////////////////////////////////////////
// SIGNATURE REQUEST SIGNER SCHEMA
/////////////////////////////////////////

export const SignatureRequestSignerSchema = z.object({
  signedBy: EStakeholderSchema,
  status: ESignerStatusSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  signatureRequestId: z.string(),
  declineReason: z.string().nullable(),
  signatureLink: z.string(),
  signatureLinkExpireAt: z.coerce.date(),
  yousignSignerId: z.string(),
});

export type SignatureRequestSigner = z.infer<
  typeof SignatureRequestSignerSchema
>;

/////////////////////////////////////////
// KE OPERATION UPDATE SCHEMA
/////////////////////////////////////////

export const KEOperationUpdateSchema = z.object({
  keStatus: EKEStatusSchema.nullable(),
  keSubStatus: EKESubStatusSchema.nullable(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  isLatest: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  operationId: z.string(),
  comment: z.string().nullable(),
  isFromGlide: z.boolean(),
});

export type KEOperationUpdate = z.infer<typeof KEOperationUpdateSchema>;

/////////////////////////////////////////
// TAX ASSESSMENT SCHEMA
/////////////////////////////////////////

export const TaxAssessmentSchema = z.object({
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isDeleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  documentId: z.string(),
  beneficiaryId: z.string(),
  firstDeclarantName: z.string(),
  incomeYear: z.number().int(),
  income: z.number().int().nullable(),
  nbPersonInHouseHold: z.number().int().nullable(),
  isFromGlide: z.boolean(),
  glideChantierRowId_n: z.string().nullable(),
});

export type TaxAssessment = z.infer<typeof TaxAssessmentSchema>;

/////////////////////////////////////////
// TASK SCHEMA
/////////////////////////////////////////

export const TaskSchema = z.object({
  owners: EStakeholderSchema.array(),
  kind: ETaskKindSchema.nullable(),
  form: EFormSchema.nullable(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  isDeleted: z.boolean(),
  projectId: z.string(),
  nodeId: z.string(),
  spawnAt: z.coerce.date(),
  completedAt: z.coerce.date().nullable(),
  closedAt: z.coerce.date().nullable(),
  comment: z.string().nullable(),
});

export type Task = z.infer<typeof TaskSchema>;

/////////////////////////////////////////
// WORKFLOW RUN SCHEMA
/////////////////////////////////////////

export const WorkflowRunSchema = z.object({
  initialPhase: EProjectPhaseSchema,
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
  isDeleted: z.boolean(),
  projectId: z.string(),
  previousCompletedNodeIds: z.string().array(),
  previousActiveNodeIds: z.string().array(),
  afterCompletedNodeIds: z.string().array(),
  afterActiveNodeIds: z.string().array(),
  actionsRun: z.string().array(),
});

export type WorkflowRun = z.infer<typeof WorkflowRunSchema>;

/////////////////////////////////////////
// USER SCHEMA
/////////////////////////////////////////

export const UserSchema = z.object({
  accountStatus: AccountStatusSchema,
  authorizations: UserRoleSchema.array(),
  id: z.string().cuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  isFromGlide: z.boolean(),
  glideUserRowId: z.string().nullable(),
  name: z.string().nullable(),
  email: z.string(),
  isEmailVerified: z.boolean(),
  cguAccepted: z.boolean(),
  image: z.string().nullable(),
  language: z.string(),
  lastLoginAt: z.coerce.date().nullable(),
  isDemo: z.boolean(),
});

export type User = z.infer<typeof UserSchema>;
export enum CategoryCeeIncome {
  REVENUS_TRES_MODESTES = 'REVENUS_TRES_MODESTES',
  REVENUS_MODESTES = 'REVENUS_MODESTES',
  AUTRES_MENAGES = 'AUTRES_MENAGES',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum EDossierReviewByOpsProgress {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum EDossierDepotStatus {
  NOT_DONE = 'NOT_DONE',
  DONE_WAITING_FOR_RESPONSE = 'DONE_WAITING_FOR_RESPONSE',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  SUCCESS_APPROVED = 'SUCCESS_APPROVED',
  CRITICAL_FAILURE = 'CRITICAL_FAILURE',
}

export enum EProjectLiveStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
}

export enum EBankFinancementStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  WIP_ARTISAN = 'WIP_ARTISAN',
  VALIDATED_BY_ARTISAN = 'VALIDATED_BY_ARTISAN',
  VALIDATED_BY_BENEFICIARY = 'VALIDATED_BY_BENEFICIARY',
  INVALIDATED_BY_BENEFICIARY = 'INVALIDATED_BY_BENEFICIARY',
}

export enum EProjectKind {
  REAL_PROJECT = 'REAL_PROJECT',
  SIMULATION = 'SIMULATION',
  NOTE_DE_DIM = 'NOTE_DE_DIM',
}

export enum EHousingAge {
  LESS_THAN_2 = 'LESS_THAN_2',
  BETWEEN_2_AND_15 = 'BETWEEN_2_AND_15',
  MORE_THAN_15 = 'MORE_THAN_15',
}

export enum EHousingOwnerStatus {
  OWNER_PRIMARY_RESIDENCE = 'OWNER_PRIMARY_RESIDENCE',
  OWNER_SECONDARY_RESIDENCE = 'OWNER_SECONDARY_RESIDENCE',
  RENTAL_SHORT_TERM = 'RENTAL_SHORT_TERM',
  RENTAL_AS_PRIMARY_RESIDENCE = 'RENTAL_AS_PRIMARY_RESIDENCE',
}

export enum EProjectPhase {
  COMMERCIAL_INIT = 'COMMERCIAL_INIT',
  INIT_PROJECT_PRE_WORK_DOSSIER = 'INIT_PROJECT_PRE_WORK_DOSSIER',
  REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER = 'REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER',
  SIGN_BEFORE_WORK_START = 'SIGN_BEFORE_WORK_START',
  PREWORK_SUBMISSION_AND_PROJECT_IN_PROGRESS_ALL_IN_ONE = 'PREWORK_SUBMISSION_AND_PROJECT_IN_PROGRESS_ALL_IN_ONE',
  END_OF_PROJECT_DOSSIER_CREATION = 'END_OF_PROJECT_DOSSIER_CREATION',
  END_OF_PROJECT_DOSSIER_REVIEW = 'END_OF_PROJECT_DOSSIER_REVIEW',
  SIGN_AFTER_WORK_END = 'SIGN_AFTER_WORK_END',
  END_OF_PROJECT_ALL_IN_ONE = 'END_OF_PROJECT_ALL_IN_ONE',
  PROJECT_COMPLETELY_DONE = 'PROJECT_COMPLETELY_DONE',
}

export enum EPhaseMPR {
  START_PROJECT = 'START_PROJECT',
}

export enum EMPRIdentityCheckType {
  FRENCH_CONNECT = 'FRENCH_CONNECT',
  POSTAL_DELIVERY = 'POSTAL_DELIVERY',
}

export enum ESubsidiesSubscriptionOption {
  CEE_ONLY = 'CEE_ONLY',
  CEE_MPR = 'CEE_MPR',
  NO_SUBSIDIES = 'NO_SUBSIDIES',
}

export enum EMPRAbricoPricing {
  PERCENT_8 = 'PERCENT_8',
  MIN_89_MAX_249 = 'MIN_89_MAX_249',
}

export enum EForm {
  FORM_ONBOARD_BENEF_PROJECT = 'FORM_ONBOARD_BENEF_PROJECT',
  FORM_CREATE_PROJECT_ARTISAN = 'FORM_CREATE_PROJECT_ARTISAN',
  FORM_SIGN_DEVIS_RAI = 'FORM_SIGN_DEVIS_RAI',
  FORM_END_PROJECT_ARTISAN = 'FORM_END_PROJECT_ARTISAN',
  FORM_FIX_REVIEW_ARTISAN = 'FORM_FIX_REVIEW_ARTISAN',
  FORM_FIX_REVIEW_BENEF = 'FORM_FIX_REVIEW_BENEF',
  FORM_SIGN_AH = 'FORM_SIGN_AH',
  FORM_MPR_REDO_BENEF = 'FORM_MPR_REDO_BENEF',
}

export enum ECivility {
  MR = 'MR',
  MME = 'MME',
}

export enum EWorkDomain {
  EXTERIOR_WALL_INSULATION = 'EXTERIOR_WALL_INSULATION',
  INTERIOR_WALL_AND_ROOF_SLOPE_INSULATION = 'INTERIOR_WALL_AND_ROOF_SLOPE_INSULATION',
  LOST_ATTIC_INSULATION = 'LOST_ATTIC_INSULATION',
  INSULATION_OF_GROUND_FLOORS = 'INSULATION_OF_GROUND_FLOORS',
  WOOD_FIRED_BOILER = 'WOOD_FIRED_BOILER',
  WOOD_BURNING_STOVE_OR_INSERT = 'WOOD_BURNING_STOVE_OR_INSERT',
  THERMODYNAMIC_WATER_HEATER = 'THERMODYNAMIC_WATER_HEATER',
  HEAT_PUMP_ONLY_AIR_HEATING = 'HEAT_PUMP_ONLY_AIR_HEATING',
}

export enum ESignatureStatus {
  DRAFT = 'DRAFT',
  ONGOING = 'ONGOING',
  DONE = 'DONE',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  APPROVAL = 'APPROVAL',
  REJECTED = 'REJECTED',
  DECLINED = 'DECLINED',
}

export enum ESignerStatus {
  INITIATED = 'INITIATED',
  DECLINED = 'DECLINED',
  NOTIFIED = 'NOTIFIED',
  VERIFIED = 'VERIFIED',
  PROCESSING = 'PROCESSING',
  CONSENT_GIVEN = 'CONSENT_GIVEN',
  SIGNED = 'SIGNED',
  ABORTED = 'ABORTED',
  ERROR = 'ERROR',
}

export enum ECountry {
  France = 'France',
}

export enum EDocType {
  ACCORD_ARTISAN = 'ACCORD_ARTISAN',
  ATTESTATION_CHANGEMENT_MATERIEL = 'ATTESTATION_CHANGEMENT_MATERIEL',
  ATTESTATION_CONSENTEMENT_NOT_SIGNED = 'ATTESTATION_CONSENTEMENT_NOT_SIGNED',
  ATTESTATION_PROPRIETE = 'ATTESTATION_PROPRIETE',
  ATTESTATION_REGULARITE_FISCALE = 'ATTESTATION_REGULARITE_FISCALE',
  ATTESTATION_RGE = 'ATTESTATION_RGE',
  ATTESTATION_SUR_HONNEUR_NOT_SIGNED = 'ATTESTATION_SUR_HONNEUR_NOT_SIGNED',
  ATTESTATION_SUR_HONNEUR_SIGNED = 'ATTESTATION_SUR_HONNEUR_SIGNED',
  ATTESTION_REGULARITE_URSSAF = 'ATTESTION_REGULARITE_URSSAF',
  AVIS_IMPOSITION = 'AVIS_IMPOSITION',
  CADRE_CONTRIBUTION_NOT_SIGNED = 'CADRE_CONTRIBUTION_NOT_SIGNED',
  CADRE_CONTRIBUTION_SIGNED = 'CADRE_CONTRIBUTION_SIGNED',
  CERTIFICAT_ACERMI = 'CERTIFICAT_ACERMI',
  CERTIFICAT_CADRE_CONTRIBUTION = 'CERTIFICAT_CADRE_CONTRIBUTION',
  CERTIFICAT_CAPTEUR_SOLAIRE = 'CERTIFICAT_CAPTEUR_SOLAIRE',
  CNI = 'CNI',
  DEVIS_NOT_SIGNED = 'DEVIS_NOT_SIGNED',
  RAI_DEVIS_NOT_SIGNED = 'RAI_DEVIS_NOT_SIGNED',
  DEVIS_SIGNED = 'DEVIS_SIGNED',
  FACTURE = 'FACTURE',
  FICHE_TECHNIQUE = 'FICHE_TECHNIQUE',
  KBIS = 'KBIS',
  MANDAT_ANAH_NOT_SIGNED = 'MANDAT_ANAH_NOT_SIGNED',
  MANDAT_ANAH_SIGNED = 'MANDAT_ANAH_SIGNED',
  NOTE_DIMENSIONNEMENT = 'NOTE_DIMENSIONNEMENT',
  OTHER = 'OTHER',
  PHOTO = 'PHOTO',
  RIB = 'RIB',
  COMPANY_STATUS = 'COMPANY_STATUS',
  DECENNIAL_INSURANCE = 'DECENNIAL_INSURANCE',
  BALANCE_SHEET = 'BALANCE_SHEET',
}

export enum EPhotoType {
  HEAT_PUMP_EXTERIR_UNIT = 'HEAT_PUMP_EXTERIR_UNIT',
  HEAT_PUMP_INDOOR_UNIT = 'HEAT_PUMP_INDOOR_UNIT',
  INSULATION_OUTDOOR_WALL = 'INSULATION_OUTDOOR_WALL',
  SOLAR_PANELS = 'SOLAR_PANELS',
  GENERAL = 'GENERAL',
  SILO = 'SILO',
  CIRCUIT_BREAKERS = 'CIRCUIT_BREAKERS',
  LAGGING = 'LAGGING',
}

export enum ECodeAdeme {
  BAR_EN_101 = 'BAR_EN_101',
  BAR_EN_102 = 'BAR_EN_102',
  BAR_EN_103 = 'BAR_EN_103',
  BAR_TH_112 = 'BAR_TH_112',
  BAR_TH_113 = 'BAR_TH_113',
  BAR_TH_129 = 'BAR_TH_129',
  BAR_TH_143 = 'BAR_TH_143',
  BAR_TH_148 = 'BAR_TH_148',
  BAR_TH_159 = 'BAR_TH_159',
  BAR_TH_171 = 'BAR_TH_171',
  BAR_TH_172 = 'BAR_TH_172',
}

export enum ETaskKind {
  FORM_TO_FILL = 'FORM_TO_FILL',
  WAIT_EXTERNAL = 'WAIT_EXTERNAL',
  OPS_REVIEW = 'OPS_REVIEW',
  OPS_MANUAL_ACTION = 'OPS_MANUAL_ACTION',
}

export enum EStakeholder {
  BENEFICIARY = 'BENEFICIARY',
  ARTISAN = 'ARTISAN',
  OPS_ABRICO = 'OPS_ABRICO',
}

export enum EPreviousEquipment {
  ELECTRIC_HEATING = 'ELECTRIC_HEATING',
  OIL_BOILER = 'OIL_BOILER',
  GAS_BOILER = 'GAS_BOILER',
  WOOD_BOILER = 'WOOD_BOILER',
  HEAT_PUMP = 'HEAT_PUMP',
  COAL_BOILER = 'COAL_BOILER',
  GAS_CONDENSING_BOILER = 'GAS_CONDENSING_BOILER',
  OTHER = 'OTHER',
}

export enum EApplicationType {
  MEDIUM_OR_HIGH_TEMPERATURE = 'MEDIUM_OR_HIGH_TEMPERATURE',
  LOW_TEMPERATURE = 'LOW_TEMPERATURE',
}

export enum EProductionOption {
  AIR_HEATING_AND_HOT_WATER = 'AIR_HEATING_AND_HOT_WATER',
  AIR_HEATING_ONLY = 'AIR_HEATING_ONLY',
}

export enum EHousingType {
  HOUSE = 'HOUSE',
  FLAT = 'FLAT',
}

export enum EWaterHeatingOption {
  EXTRACTED_AIR = 'EXTRACTED_AIR',
  OTHER = 'OTHER',
}

export enum ECombustible {
  GRANULES = 'GRANULES',
  LOGS = 'LOGS',
  OTHER = 'OTHER',
}

export enum EWoodHeatingType {
  WOOD_BURNER = 'WOOD_BURNER',
  CLOSED_FIREPLACE = 'CLOSED_FIREPLACE',
  FIREPLACE_INSERT = 'FIREPLACE_INSERT',
  COOKER = 'COOKER',
}

export enum EInsulationType {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
}

export enum EInsulationLocation {
  ATTIC = 'ATTIC',
  ROOF = 'ROOF',
}

export enum EAtticInsulationType {
  RAMPANT_DE_TOITURE = 'RAMPANT_DE_TOITURE',
  COMBLE_PERDU = 'COMBLE_PERDU',
}

export enum EAlimentationType {
  AUTOMATED = 'AUTOMATED',
  MANUAL = 'MANUAL',
}

export enum EProfile {
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export enum ERegulatorClass {
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV',
  V = 'V',
  VI = 'VI',
  VII = 'VII',
  VIII = 'VIII',
}

export enum ESolarCertification {
  CSTBAT = 'CSTBAT',
  SLOARKEYMARK = 'SLOARKEYMARK',
  EQUIVALENT = 'EQUIVALENT',
  OTHER = 'OTHER',
}

export enum EKEStatus {
  PRE_OPERATION = 'PRE_OPERATION',
  TO_BE_VERIFIED = 'TO_BE_VERIFIED',
  PENDING_REGULARIZATION = 'PENDING_REGULARIZATION',
  TO_BE_INSPECTED = 'TO_BE_INSPECTED',
  UNDER_INSPECTION = 'UNDER_INSPECTION',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  CORRECTIVE_ACTIONS = 'CORRECTIVE_ACTIONS',
  TELEPHONE_VERIFICATION_PENDING = 'TELEPHONE_VERIFICATION_PENDING',
  TELEPHONE_VERIFICATION_IN_PROGRESS = 'TELEPHONE_VERIFICATION_IN_PROGRESS',
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  EXPIRED = 'EXPIRED',
  ABANDONED = 'ABANDONED',
}

export enum EKESubStatus {
  SIGNED_QUOTATION_TO_BE_VERIFIED = 'SIGNED_QUOTATION_TO_BE_VERIFIED',
  SIGNED_QUOTATION_PENDING_REGULARIZATION = 'SIGNED_QUOTATION_PENDING_REGULARIZATION',
  SIGNED_QUOTATION_VALIDATED = 'SIGNED_QUOTATION_VALIDATED',
}

export enum AccountStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  NOT_VERIFIED = 'NOT_VERIFIED',
}

export enum UserRole {
  APP = 'APP',
  SIMULATIONS_ONLY = 'SIMULATIONS_ONLY',
  ADMIN = 'ADMIN',
}

export const EnumByEnumName = {
  CategoryCeeIncome,
  EDossierReviewByOpsProgress,
  EDossierDepotStatus,
  EProjectLiveStatus,
  EBankFinancementStatus,
  EProjectKind,
  EHousingAge,
  EHousingOwnerStatus,
  EProjectPhase,
  EPhaseMPR,
  EMPRIdentityCheckType,
  ESubsidiesSubscriptionOption,
  EMPRAbricoPricing,
  EForm,
  ECivility,
  EWorkDomain,
  ESignatureStatus,
  ESignerStatus,
  ECountry,
  EDocType,
  EPhotoType,
  ECodeAdeme,
  ETaskKind,
  EStakeholder,
  EPreviousEquipment,
  EApplicationType,
  EProductionOption,
  EHousingType,
  EWaterHeatingOption,
  ECombustible,
  EWoodHeatingType,
  EInsulationType,
  EInsulationLocation,
  EAtticInsulationType,
  EAlimentationType,
  EProfile,
  ERegulatorClass,
  ESolarCertification,
  EKEStatus,
  EKESubStatus,
  AccountStatus,
  UserRole,
};
