import { sha256Digest } from './crypto';

export const routesPath = {
  root: '/',
  login: 'login',
  passwordReset: 'passwordReset',
  dossiers: '/company/:companyId/dossiers',
  dossiersByTag: '/company/:companyId/dossiers/tag/:tagId',
  dossier: '/company/:companyId/dossier/:dossierId',
  chantierHome: '/company/:companyId/chantier/:chantierId',
  chantierEmail: '/company/:companyId/chantier/:chantierId/email',
  chantierDocReview: '/company/:companyId/chantier/:chantierId/doc/:dossierId',
  chantierDocReviewEmail:
    '/company/:companyId/chantier/:chantierId/doc/:dossierId/email',
  attestationsRGE: '/attestations-rge',
};

export const docTypeOrderInOutline = {
  attestation_sur_honneur: 0,
  attestation_simplifiee: 1,
  cadre_contribution: 2,
  facture: 3,
  devis_ns: 4,
  devis: 5,
  avis_imposition: 6,
  note_dimensionnement: 7,
  fiche_technique: 7,
  certificat_acermi: 9,
  attestation_rge: 10,
  autre: 11,
  CNI: 12,
  mandat: 13,
  rib: 14,
};

export const ELEMENTS_TO_DISABLE = [
  'toolbarGroup-View',
  'toolbarGroup-Shapes',
  'toolbarGroup-Edit',
  'toolbarGroup-Insert',
  'toolbarGroup-FillAndSign',
  'toolbarGroup-Forms',
  'dropdown-item-toolbarGroup-Shapes',
  'dropdown-item-toolbarGroup-Insert',
  'dropdown-item-toolbarGroup-Edit',
  'dropdown-item-toolbarGroup-FillAndSign',
  'dropdown-item-toolbarGroup-Forms',
  'stickyToolGroupButton',
  'toggleNotesButton',
  // 'searchButton',
  // 'searchPanel',
  'printButton',
  'settingsButton',
  'underlineToolGroupButton',
  'shapeToolGroupButton',
  //"freeHandToolGroupButton",
  //'freeTextToolGroupButton',
  'squigglyToolGroupButton',
  'markInsertTextGroupButton',
  'markReplaceTextGroupButton',
  'strikeoutToolGroupButton',
  'panToolButton',
  'selectToolButton',
  'bookmarksPanelButton',
  'signaturePanelButton',
  'addNewOutlineButton', // bouton qui permet d'ajouter un élément dans l'outline,
  'extractPage',
  'menuButton',
  // To avoid change link btn on bbox (shouldn't be used in our usecase anyway)
  'linkButton',
];

export const TOGGLEABLE_PAGE_MANIPULATION_ELEMENTS = [
  'deletePage',
  'insertPage',
  'replacePage',
  'thumbDelete',
  'pageManipulationOverlayButton',
  'pageAdditionalControlsHeader',
  'moveToTop',
  'moveToBottom',
  'pageManipulationHeader',
  'thumbnailControl',
  'saveUpdatedDocumentButton',
];

export const ABRICO_ORANGE_MAIN = '#FF6315';

// We digest it to hide it a bit better
export const ABRICO_ANNOTATION_PREFIX = sha256Digest(
  '__INTERNAL__ANNOTATION__'
);

export const CODE_ADEME: readonly {
  readonly code: string;
  readonly name: string;
  readonly status?: string;
  readonly statusLabel?: string;
}[] = [
  {
    code: 'AGRI-EQ-101',
    name: '',
  },
  {
    code: 'AGRI-EQ-102',
    name: '',
  },
  {
    code: 'AGRI-EQ-104',
    name: '',
  },
  {
    code: 'AGRI-EQ-105',
    name: '',
  },
  {
    code: 'AGRI-EQ-106',
    name: '',
  },
  {
    code: 'AGRI-EQ-107',
    name: '',
  },
  {
    code: 'AGRI-EQ-108',
    name: '',
  },
  {
    code: 'AGRI-EQ-109',
    name: '',
  },
  {
    code: 'AGRI-EQ-110',
    name: '',
  },
  {
    code: 'AGRI-SE-101',
    name: '',
  },
  {
    code: 'AGRI-TH-101',
    name: '',
  },
  {
    code: 'AGRI-TH-102',
    name: '',
  },
  {
    code: 'AGRI-TH-103',
    name: '',
  },
  {
    code: 'AGRI-TH-104',
    name: '',
  },
  {
    code: 'AGRI-TH-105',
    name: '',
  },
  {
    code: 'AGRI-TH-108',
    name: '',
  },
  {
    code: 'AGRI-TH-109',
    name: '',
  },
  {
    code: 'AGRI-TH-110',
    name: '',
  },
  {
    code: 'AGRI-TH-113',
    name: '',
  },
  {
    code: 'AGRI-TH-116',
    name: '',
  },
  {
    code: 'AGRI-TH-117',
    name: '',
  },
  {
    code: 'AGRI-TH-118',
    name: '',
  },
  {
    code: 'AGRI-TH-119',
    name: '',
  },
  {
    code: 'AGRI-UT-101',
    name: '',
  },
  {
    code: 'AGRI-UT-102',
    name: '',
  },
  {
    code: 'AGRI-UT-103',
    name: '',
  },
  {
    code: 'AGRI-UT-104',
    name: '',
  },
  {
    code: 'BAR-EN-101',
    name: 'Isolation de combles ou de toitures',
  },
  {
    code: 'BAR-EN-102',
    name: 'Isolation des murs',
  },
  {
    code: 'BAR-EN-103',
    name: "Isolation d'un plancher",
  },
  {
    code: 'BAR-EN-104',
    name: 'Fenêtre ou porte-fenêtre complète avec vitrage isolant',
  },
  {
    code: 'BAR-EN-105',
    name: 'Isolation des toitures terrasses',
  },
  {
    code: 'BAR-EN-106',
    name: 'Isolation de combles ou de toitures (France d’outre-mer)',
  },
  {
    code: 'BAR-EN-107',
    name: 'Isolation des murs (France d’outre-mer)',
  },
  {
    code: 'BAR-EN-108',
    name: 'Fermeture isolante',
  },
  {
    code: 'BAR-EN-109',
    name: 'Réduction des apports solaires par la toiture (France d’outre-mer)',
  },
  {
    code: 'BAR-EN-110',
    name: 'Fenêtre ou porte-fenêtre complète avec vitrage pariétodynamique',
  },
  {
    code: 'BAR-EQ-102',
    name: '',
  },
  {
    code: 'BAR-EQ-103',
    name: '',
  },
  {
    code: 'BAR-EQ-110',
    name: 'Luminaire à modules LED avec dispositif de contrôle pour les parties communes',
  },
  {
    code: 'BAR-EQ-111',
    name: '',
  },
  {
    code: 'BAR-EQ-115',
    name: 'Dispositif d’affichage et d’interprétation des consommations d’énergie',
  },
  {
    code: 'BAR-SE-104',
    name: 'Réglage des organes d’équilibrage d’une installation de chauffage à eau chaude',
  },
  {
    code: 'BAR-SE-105',
    name: 'Contrat de Performance Énergétique Services (CPE Services)',
  },
  {
    code: 'BAR-SE-106',
    name: "Service de suivi des consommations d'énergie",
  },
  {
    code: 'BAR-SE-107',
    name: 'Abaissement de la température de retour vers un réseau de chaleur',
  },
  {
    code: 'BAR-SE-108',
    name: 'Désembouage d’un réseau hydraulique individuel de chauffage en France métropolitaine',
  },
  {
    code: 'BAR-SE-109',
    name: 'Désembouage d’un réseau hydraulique de chauffage collectif',
  },
  {
    code: 'BAR-TH-101',
    name: 'Chauffe-eau solaire individuel (France métropolitaine)',
  },
  {
    code: 'BAR-TH-102',
    name: 'Chauffe-eau solaire collectif (France métropolitaine)',
  },
  {
    code: 'BAR-TH-104',
    name: 'Pompe à chaleur de type air/eau ou eau/eau',
    status: 'cancelled',
    statusLabel: 'abrogée par l’arrêté du 4 octobre 2023',
  },
  {
    code: 'BAR-TH-106',
    name: 'Chaudière individuelle à haute performance énergétique',
    status: 'cancelled',
    statusLabel: 'abrogée par l’arrêté du 4 octobre 2023',
  },
  {
    code: 'BAR-TH-107',
    name: 'Chaudière collective à haute performance énergétique',
  },
  {
    code: 'BAR-TH-107-SE',
    name: "Chaudière collective à haute performance énergétique avec contrat assurant la conduite de l'installation",
  },
  {
    code: 'BAR-TH-110',
    name: 'Radiateur basse température pour un chauffage central',
  },
  {
    code: 'BAR-TH-111',
    name: 'Régulation par sonde de température extérieure',
  },
  {
    code: 'BAR-TH-112',
    name: 'Appareil indépendant de chauffage au bois',
  },
  {
    code: 'BAR-TH-113',
    name: 'Chaudière biomasse individuelle',
  },
  {
    code: 'BAR-TH-116',
    name: 'Plancher chauffant hydraulique à basse température',
  },
  {
    code: 'BAR-TH-117',
    name: 'Robinet thermostatique',
  },
  {
    code: 'BAR-TH-118',
    name: 'Système de régulation par programmation d intermittence',
    status: 'cancelled',
    statusLabel: 'abrogée par l’arrêté du 22 novembre 2023',
  },
  {
    code: 'BAR-TH-121',
    name: '',
  },
  {
    code: 'BAR-TH-122',
    name: 'Récupérateur de chaleur à condensation',
  },
  {
    code: 'BAR-TH-123',
    name: 'Optimiseur de relance en chauffage collectif',
  },
  {
    code: 'BAR-TH-124',
    name: "Chauffe-eau solaire individuel (France d'outre-mer)",
  },
  {
    code: 'BAR-TH-125',
    name: 'Système de ventilation double flux autoréglable ou modulé à haute performance',
  },
  {
    code: 'BAR-TH-127',
    name: 'Ventilation Mécanique Contrôlée simple flux hygroréglable',
  },
  {
    code: 'BAR-TH-129',
    name: 'Pompe à chaleur de type air/air',
  },
  {
    code: 'BAR-TH-130',
    name: 'Surperformance énergétique pour un bâtiment neuf (France métropolitaine)',
  },
  {
    code: 'BAR-TH-135',
    name: 'Chauffe-eau solaire collectif (France d’outre-mer)',
  },
  {
    code: 'BAR-TH-137',
    name: 'Raccordement d’un bâtiment résidentiel à un réseau de chaleur',
  },
  {
    code: 'BAR-TH-139',
    name: 'Système de variation électronique de vitesse sur une pompe',
  },
  {
    code: 'BAR-TH-141',
    name: "Climatiseur performant (France d'outre-mer)",
  },
  {
    code: 'BAR-TH-143',
    name: 'Système solaire combiné',
  },
  {
    code: 'BAR-TH-145',
    name: 'Rénovation globale d’un bâtiment résidentiel collectif',
  },
  {
    code: 'BAR-TH-148',
    name: 'Chauffe-eau thermodynamique à accumulation',
  },
  {
    code: 'BAR-TH-150',
    name: 'Pompe à chaleur collective à absorption de type air/eau ou eau/eau',
  },
  {
    code: 'BAR-TH-155',
    name: 'Ventilation hybride hygroréglable',
  },
  {
    code: 'BAR-TH-158',
    name: 'Émetteur électrique à régulation électronique à fonctions avancées',
  },
  {
    code: 'BAR-TH-159',
    name: 'Pompe à chaleur hybride individuelle',
  },
  {
    code: 'BAR-TH-160',
    name: 'Isolation d’un réseau hydraulique de chauffage ou d’eau chaude sanitaire',
  },
  {
    code: 'BAR-TH-161',
    name: "Isolation de points singuliers d'un réseau",
  },
  {
    code: 'BAR-TH-162',
    name: "Système énergétique comportant des capteurs solaires photovoltaïques et thermiques à circulation d'eau (France métropolitaine)",
  },
  {
    code: 'BAR-TH-163',
    name: 'Conduit d’évacuation des produits de combustion',
  },
  {
    code: 'BAR-TH-164',
    name: '',
  },
  {
    code: 'BAR-TH-165',
    name: 'Chaudière biomasse collective',
  },
  {
    code: 'BAR-TH-166',
    name: 'Pompe à chaleur collective de type air/eau ou eau/eau',
  },
  {
    code: 'BAR-TH-167',
    name: 'Chauffe-bain individuel à haut rendement ou à condensation',
  },
  {
    code: 'BAR-TH-168',
    name: 'Dispositif solaire thermique',
  },
  {
    code: 'BAR-TH-169',
    name: 'Pompe à chaleur collective de type air/eau ou eau/eau pour l’eau chaude sanitaire',
  },
  {
    code: 'BAR-TH-170',
    name: 'Récupération de chaleur fatale issue de serveurs informatiques pour l’eau chaude sanitaire collective',
  },
  {
    code: 'BAR-TH-171',
    name: 'Pompe à chaleur de type air/eau',
  },
  {
    code: 'BAR-TH-172',
    name: 'Pompe à chaleur de type eau/eau ou sol/eau',
  },
  {
    code: 'BAR-TH-173',
    name: 'Système de régulation par programmation horaire pièce par pièce',
  },
  {
    code: 'BAR-TH-174',
    name: 'Rénovation d’ampleur d’une maison individuelle',
  },
  {
    code: 'BAR-TH-175',
    name: 'Rénovation d’ampleur d’un appartement',
  },
  {
    code: 'BAR-TH-176',
    name: 'Système de régulation de la consommation d’un chauffe-eau électrique à effet Joule',
  },
  {
    code: 'BAT-EN-101',
    name: '',
  },
  {
    code: 'BAT-EN-102',
    name: '',
  },
  {
    code: 'BAT-EN-103',
    name: '',
  },
  {
    code: 'BAT-EN-104',
    name: '',
  },
  {
    code: 'BAT-EN-106',
    name: '',
  },
  {
    code: 'BAT-EN-107',
    name: '',
  },
  {
    code: 'BAT-EN-108',
    name: '',
  },
  {
    code: 'BAT-EN-109',
    name: '',
  },
  {
    code: 'BAT-EN-110',
    name: '',
  },
  {
    code: 'BAT-EN-111',
    name: '',
  },
  {
    code: 'BAT-EN-112',
    name: '',
  },
  {
    code: 'BAT-EN-113',
    name: '',
  },
  {
    code: 'BAT-EQ-117',
    name: '',
  },
  {
    code: 'BAT-EQ-123',
    name: '',
  },
  {
    code: 'BAT-EQ-124',
    name: '',
  },
  {
    code: 'BAT-EQ-125',
    name: '',
  },
  {
    code: 'BAT-EQ-127',
    name: '',
  },
  {
    code: 'BAT-EQ-129',
    name: '',
  },
  {
    code: 'BAT-EQ-130',
    name: '',
  },
  {
    code: 'BAT-EQ-131',
    name: '',
  },
  {
    code: 'BAT-EQ-133',
    name: '',
  },
  {
    code: 'BAT-EQ-134',
    name: '',
  },
  {
    code: 'BAT-SE-103',
    name: '',
  },
  {
    code: 'BAT-SE-104',
    name: '',
  },
  {
    code: 'BAT-SE-105',
    name: '',
  },
  {
    code: 'BAT-TH-102',
    name: '',
  },
  {
    code: 'BAT-TH-103',
    name: '',
  },
  {
    code: 'BAT-TH-104',
    name: '',
  },
  {
    code: 'BAT-TH-105',
    name: '',
  },
  {
    code: 'BAT-TH-108',
    name: '',
  },
  {
    code: 'BAT-TH-109',
    name: '',
  },
  {
    code: 'BAT-TH-110',
    name: '',
  },
  {
    code: 'BAT-TH-111',
    name: '',
  },
  {
    code: 'BAT-TH-112',
    name: '',
  },
  {
    code: 'BAT-TH-113',
    name: '',
  },
  {
    code: 'BAT-TH-115',
    name: '',
  },
  {
    code: 'BAT-TH-116',
    name: '',
  },
  {
    code: 'BAT-TH-121',
    name: '',
  },
  {
    code: 'BAT-TH-122',
    name: '',
  },
  {
    code: 'BAT-TH-125',
    name: '',
  },
  {
    code: 'BAT-TH-126',
    name: '',
  },
  {
    code: 'BAT-TH-127',
    name: '',
  },
  {
    code: 'BAT-TH-134',
    name: '',
  },
  {
    code: 'BAT-TH-135',
    name: '',
  },
  {
    code: 'BAT-TH-139',
    name: '',
  },
  {
    code: 'BAT-TH-140',
    name: '',
  },
  {
    code: 'BAT-TH-141',
    name: '',
  },
  {
    code: 'BAT-TH-142',
    name: '',
  },
  {
    code: 'BAT-TH-143',
    name: '',
  },
  {
    code: 'BAT-TH-145',
    name: '',
  },
  {
    code: 'BAT-TH-146',
    name: '',
  },
  {
    code: 'BAT-TH-153',
    name: '',
  },
  {
    code: 'BAT-TH-154',
    name: '',
  },
  {
    code: 'BAT-TH-155',
    name: '',
  },
  {
    code: 'BAT-TH-156',
    name: '',
  },
  {
    code: 'BAT-TH-157',
    name: '',
  },
  {
    code: 'BAT-TH-158',
    name: '',
  },
  {
    code: 'BAT-TH-159',
    name: '',
  },
  {
    code: 'BAT-TH-160',
    name: '',
  },
  {
    code: 'IND-BA-110',
    name: '',
  },
  {
    code: 'IND-BA-112',
    name: '',
  },
  {
    code: 'IND-BA-113',
    name: '',
  },
  {
    code: 'IND-BA-114',
    name: '',
  },
  {
    code: 'IND-BA-115',
    name: '',
  },
  {
    code: 'IND-BA-116',
    name: '',
  },
  {
    code: 'IND-BA-117',
    name: '',
  },
  {
    code: 'IND-EN-101',
    name: '',
  },
  {
    code: 'IND-EN-102',
    name: '',
  },
  {
    code: 'IND-UT-102',
    name: '',
  },
  {
    code: 'IND-UT-103',
    name: '',
  },
  {
    code: 'IND-UT-104',
    name: '',
  },
  {
    code: 'IND-UT-105',
    name: '',
  },
  {
    code: 'IND-UT-112',
    name: '',
  },
  {
    code: 'IND-UT-113',
    name: '',
  },
  {
    code: 'IND-UT-114',
    name: '',
  },
  {
    code: 'IND-UT-115',
    name: '',
  },
  {
    code: 'IND-UT-116',
    name: '',
  },
  {
    code: 'IND-UT-117',
    name: '',
  },
  {
    code: 'IND-UT-118',
    name: '',
  },
  {
    code: 'IND-UT-120',
    name: '',
  },
  {
    code: 'IND-UT-121',
    name: '',
  },
  {
    code: 'IND-UT-122',
    name: '',
  },
  {
    code: 'IND-UT-123',
    name: '',
  },
  {
    code: 'IND-UT-124',
    name: '',
  },
  {
    code: 'IND-UT-125',
    name: '',
  },
  {
    code: 'IND-UT-127',
    name: '',
  },
  {
    code: 'IND-UT-129',
    name: '',
  },
  {
    code: 'IND-UT-130',
    name: '',
  },
  {
    code: 'IND-UT-131',
    name: '',
  },
  {
    code: 'IND-UT-132',
    name: '',
  },
  {
    code: 'IND-UT-133',
    name: '',
  },
  {
    code: 'IND-UT-134',
    name: '',
  },
  {
    code: 'IND-UT-135',
    name: '',
  },
  {
    code: 'IND-UT-136',
    name: '',
  },
  {
    code: 'RES-CH-101',
    name: '',
  },
  {
    code: 'RES-CH-103',
    name: '',
  },
  {
    code: 'RES-CH-104',
    name: '',
  },
  {
    code: 'RES-CH-105',
    name: '',
  },
  {
    code: 'RES-CH-106',
    name: '',
  },
  {
    code: 'RES-CH-107',
    name: '',
  },
  {
    code: 'RES-CH-108',
    name: '',
  },
  {
    code: 'RES-EC-101',
    name: '',
  },
  {
    code: 'RES-EC-102',
    name: '',
  },
  {
    code: 'RES-EC-103',
    name: '',
  },
  {
    code: 'RES-EC-104',
    name: '',
  },
  {
    code: 'RES-EC-107',
    name: '',
  },
  {
    code: 'TRA-EQ-101',
    name: '',
  },
  {
    code: 'TRA-EQ-103',
    name: '',
  },
  {
    code: 'TRA-EQ-104',
    name: '',
  },
  {
    code: 'TRA-EQ-106',
    name: '',
  },
  {
    code: 'TRA-EQ-107',
    name: '',
  },
  {
    code: 'TRA-EQ-108',
    name: '',
  },
  {
    code: 'TRA-EQ-109',
    name: '',
  },
  {
    code: 'TRA-EQ-110',
    name: '',
  },
  {
    code: 'TRA-EQ-111',
    name: '',
  },
  {
    code: 'TRA-EQ-113',
    name: '',
  },
  {
    code: 'TRA-EQ-114',
    name: '',
  },
  {
    code: 'TRA-EQ-115',
    name: '',
  },
  {
    code: 'TRA-EQ-117',
    name: '',
  },
  {
    code: 'TRA-EQ-118',
    name: '',
  },
  {
    code: 'TRA-EQ-119',
    name: '',
  },
  {
    code: 'TRA-EQ-120',
    name: '',
  },
  {
    code: 'TRA-EQ-121',
    name: '',
  },
  {
    code: 'TRA-EQ-122',
    name: '',
  },
  {
    code: 'TRA-EQ-123',
    name: '',
  },
  {
    code: 'TRA-EQ-124',
    name: '',
  },
  {
    code: 'TRA-EQ-125',
    name: '',
  },
  {
    code: 'TRA-EQ-126',
    name: '',
  },
  {
    code: 'TRA-EQ-127',
    name: '',
  },
  {
    code: 'TRA-EQ-128',
    name: '',
  },
  {
    code: 'TRA-SE-101',
    name: '',
  },
  {
    code: 'TRA-SE-102',
    name: '',
  },
  {
    code: 'TRA-SE-104',
    name: '',
  },
  {
    code: 'TRA-SE-105',
    name: '',
  },
  {
    code: 'TRA-SE-106',
    name: '',
  },
  {
    code: 'TRA-SE-107',
    name: '',
  },
  {
    code: 'TRA-SE-108',
    name: '',
  },
  {
    code: 'TRA-SE-109',
    name: '',
  },
  {
    code: 'TRA-SE-110',
    name: '',
  },
  {
    code: 'TRA-SE-111',
    name: '',
  },
  {
    code: 'TRA-SE-112',
    name: '',
  },
  {
    code: 'TRA-SE-113',
    name: '',
  },
  {
    code: 'TRA-SE-114',
    name: '',
  },
  {
    code: 'TRA-SE-115',
    name: '',
  },
  {
    code: 'TRA-SE-116',
    name: '',
  },
];
