import i18n, { InitOptions } from 'i18next';

import sharedLabelsFR from '@/abrico-lib-shared/data-exchange/labels-fr.json';

const i18nConfig: InitOptions = {
  defaultNS: 'fr',
  ns: 'fr',
  resources: { fr: { shared: sharedLabelsFR } },
  fallbackLng: 'fr',
  supportedLngs: ['fr'],
  preload: ['fr'],

  // Fix issue with i18next types
  // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
  returnNull: false,
  initAsync: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};
const exported = i18n.createInstance(i18nConfig);
exported.init();

export default exported;
