import { Accordion, AccordionDetails, AccordionSummary } from '@mui/joy';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CircularProgress from '@mui/joy/CircularProgress';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MarkdownEditor } from '@/components/Editor/MarkdownEditor.tsx';
import IconByStatus from '@/components/Review/IconStatus';
import { ReviewerInfo } from '@/components/Review/ReviewerInfo.tsx';
import { ReviewSummary } from '@/components/Review/ReviewSummary.tsx';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { TReviewInfo } from '@/types';
import { useKeyBoardNavigation } from '@/utils/entities.ts';
import { generateAiReviewFeedback } from '@/utils/review/ai';
import { getReviewInfo } from '@/utils/review/helpers';

export function ReviewPanel() {
  const { t } = useTranslation();

  const isInReadOnlyMode = useInteractionsState(
    (state) => state.isInReadOnlyMode
  );
  const dossier = useInteractionsState((state) => state.dossier);
  const metaDossierInfo = useInteractionsState(
    (state) => state.metaDossierInfo
  );
  const metaDossierInfoIsLoading = useInteractionsState(
    (state) => state.metaDossierInfoIsLoading
  );

  const entitiesRejectionReasons = useInteractionsState(
    (state) => state.entitiesRejectionReasons
  );
  const entitiesStatuses = useInteractionsState(
    (state) => state.entitiesStatuses
  );
  const { preparedEntities } = useKeyBoardNavigation(
    dossier,
    useAppState.getState().company?.featureFlags?.handleMissingFields
  );

  const reviewInfo = useMemo<TReviewInfo>(
    () =>
      getReviewInfo(
        preparedEntities,
        entitiesStatuses,
        entitiesRejectionReasons
      ),
    [entitiesRejectionReasons, entitiesStatuses, preparedEntities]
  );

  const reviewFeedbackMarkdown = useInteractionsState(
    (state) => state.reviewFeedbackMarkdown
  );
  const user = useAppState((state) => state.user);
  const reviewStatus = useInteractionsState((state) => state.reviewStatus);

  const [expanded, setExpanded] = useState(false);
  if (!metaDossierInfo) {
    if (metaDossierInfoIsLoading) {
      return <CircularProgress />;
    } else {
      return <Typography>{t('sync.noMetaDossier')}</Typography>;
    }
  }

  if (!user?.displayName) {
    return <ReviewerInfo></ReviewerInfo>;
  }

  return (
    <Card
      sx={{
        borderRadius: 12,
        borderWidth: 4,
        p: 2,
        pb: 1,
        pt: 0,
        overflowY: expanded ? 'auto' : 'unset',
        flexGrow: expanded ? 1 : 0,
        flexBasis: 0,
        maxHeight: 'fit-content',
      }}
    >
      <Accordion
        expanded={expanded}
        onChange={(_, newExpanded) => setExpanded(newExpanded)}
      >
        <AccordionSummary
          sx={{
            width: '100%',
            backgroundColor: '#0b0d0e',
            position: 'sticky',
            alignSelf: 'flex-start',
            top: 0,
            zIndex: 1000,
            pb: 1,
            pt: 2,
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ width: '100%' }}
          >
            <Typography
              level={'title-md'}
              sx={{ fontWeight: 'var(--joy-fontWeight-lg) !important' }}
            >
              {t('ui.tabs.review')}
            </Typography>
            <>{IconByStatus[reviewStatus!]}</>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction={'column'} gap={2}>
            <ReviewSummary reviewInfo={reviewInfo} />
            <ReviewerInfo></ReviewerInfo>
            {reviewStatus === 'invalid' && (
              <Box sx={{ mt: 1, mb: 1 }}>
                <MarkdownEditor
                  value={reviewFeedbackMarkdown}
                  onChange={(value) =>
                    useInteractionsState
                      .getState()
                      .setReviewFeedbackMarkdown(value)
                  }
                  readOnly={isInReadOnlyMode}
                  placeholder={t('review.editorPlaceholder')}
                  generateAi={async () =>
                    await generateAiReviewFeedback(reviewInfo)
                  }
                ></MarkdownEditor>
              </Box>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
