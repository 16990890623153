import {
  KeyboardBackspaceRounded,
  WarningAmberRounded,
} from '@mui/icons-material';
import { Alert } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { NavLink } from 'react-router-dom';

import FullScreenLoader from './FullScreenLoader.tsx';

import { getAnalysis } from '@/cloudFunctions/functions';
import ChantierDocsBreadcrumbs from '@/components/Chantier/ChantierDocsBreadcrumbs.tsx';
import EntitiesPanel from '@/components/Entity/EntitiesPanel.tsx';
import { InternalReviewNote } from '@/components/Review/InternalReviewNote.tsx';
import { ReviewPanel } from '@/components/Review/ReviewPanel.tsx';
import { SyncPanel } from '@/components/Sync/SyncPanel.tsx';
import { MasterNavigation } from '@/components/UI/MasterNavigation.tsx';
import ReadOnlyModal from '@/components/UI/ReadOnlyModal.tsx';
import { TrackTiming } from '@/components/Utils/TrackTiming.tsx';
import WebViewerWrapper from '@/components/WebViewer/WebViewerWrapper.tsx';
import { getDossierById, getFSDossierInteractions } from '@/queries/dossiers';
import { TAnalysisResponse } from '@/schemas/analysis.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { useUiState } from '@/stores/uiStore.ts';
import { WorkflowStatus } from '@/types';
import { ABRICO_ORANGE_MAIN } from '@/utils/constants.ts';
import { Dossier } from '@/utils/dossier.ts';
import { startPageNumberAtOne } from '@/utils/processProcessorsOutput';

const dossierIdWithDataQuery = () => {
  const queryFunction = async () => {
    let dossierId: string;
    try {
      dossierId = useAppState.getState().getDossierId();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return null;
    }

    const dossierData = await getDossierById(dossierId);
    if (!dossierData) {
      return null;
    }

    // We don't make this await to avoid blocking IO, but things should load in time
    getFSDossierInteractions(dossierData.id).then((interactions) =>
      useInteractionsState.getState().mergeData(interactions)
    );
    let analysis: TAnalysisResponse = {
      sections: {},
      extractedEntities: [],
      missingFields: {},
      postProcessedEntities: [],
    };
    if (dossierData.workflowStatus === WorkflowStatus.COMPLETED) {
      try {
        const { data } = await getAnalysis({ dossierId: dossierData.id });
        analysis = data;
      } catch (e) {
        console.error('Failed to load analysis: ', e);
      }
    } else {
      useUiState.getState().setIsWorkflowLoading(true);
    }

    const docTypesByFileByPage =
      dossierData?.docTypesByFileByPage &&
      dossierData.workflowStatus === WorkflowStatus.COMPLETED
        ? startPageNumberAtOne(dossierData?.docTypesByFileByPage)
        : {};

    const dossier = new Dossier({
      ...dossierData,
      workflowExecutions: dossierData?.workflowExecutions ?? 0,
      docTypesByFileByPage,
      analysis,
    });

    useInteractionsState.getState().setDossier(dossier);
    return dossier;
  };

  return queryOptions({
    queryKey: ['dossier', 'main'],
    queryFn: queryFunction,
    staleTime: Infinity,
  });
};

export default function Station({ invisible }: { invisible: boolean }) {
  const rootElem: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const company = useAppState((state) => state.company);
  const urlParams = useAppState((state) => state.urlParams);
  const entityGlobalAutoSelectRef = useRef(() => {});
  const viewerIsReady = useUiState((state) => state.viewerIsReady);

  const { data: dossier, isFetching } = useQuery(dossierIdWithDataQuery());

  useEffect(() => {
    if (!dossier) return;

    useAppState
      .getState()
      .getInstance()
      .then((instance) => {
        dossier.refreshUI(instance);
      });
  }, [dossier]);

  return (
    <>
      <ReadOnlyModal></ReadOnlyModal>
      {!invisible && dossier === null && !isFetching && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <Typography
              sx={{ mt: 4, padding: 2, borderRadius: 8 }}
              variant={'soft'}
              level={'title-lg'}
              whiteSpace={'pre-wrap'}
              textAlign={'center'}
            >
              {t('dossier.notFound')}
            </Typography>
            <NavLink to={`/`} style={{ textDecoration: 'none' }}>
              <Button sx={{ mt: 2 }}>{t('home.goHome')}</Button>
            </NavLink>
          </Box>
        </>
      )}
      {!invisible && !viewerIsReady && <FullScreenLoader></FullScreenLoader>}
      <Stack
        sx={{
          height: '100vh',
          width: '100vw',
          p: 0.5,
          pr: 0.1,
          backgroundColor: '0A0E0F',
        }}
      >
        {urlParams.chantierId && <ChantierDocsBreadcrumbs />}
        <PanelGroup
          direction="horizontal"
          autoSaveId="persistence"
          style={{ width: '100%', backgroundColor: '#0A0E0F' }}
        >
          <Panel>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                marginTop: 0,
                paddingBottom: 0,
              }}
            >
              <Card
                sx={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 12,
                  borderWidth: 4,
                  p: 0.5,
                }}
              >
                <WebViewerWrapper />
              </Card>
            </Box>
          </Panel>

          <PanelResizeHandle style={{ width: '6px' }} />

          <Panel
            defaultSize={30}
            minSize={5}
            style={{ containerType: 'inline-size' }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                '@container (max-width: 330px)': {
                  borderLeft: 'none',
                  overflow: 'hidden',
                  animation: 'glow .8s infinite alternate',
                  '@keyframes glow': {
                    from: {
                      boxShadow: `inset 8px 0 ${ABRICO_ORANGE_MAIN}`,
                    },
                    to: {
                      boxShadow: `inset 4px 0 #ffae95`,
                    },
                  },
                },
              }}
            >
              {!invisible && (
                <>
                  <Stack
                    ref={rootElem}
                    direction="column"
                    alignItems="center"
                    sx={{
                      height: '100%',
                      width: '100%',
                      '@container (max-width: 330px)': {
                        display: 'none',
                      },
                    }}
                    gap={1}
                    justifyContent="space-between"
                  >
                    <Stack
                      sx={{
                        width: '100%',
                        overflowY: 'hidden',
                        flexGrow: 1,
                        flexBasis: 0,
                      }}
                      gap={1}
                    >
                      {(dossier?.workflowStatus ?? null) ===
                        WorkflowStatus.FAILED && (
                        <Alert
                          startDecorator={
                            <WarningAmberRounded fontSize="inherit" />
                          }
                          variant={'solid'}
                          color={'neutral'}
                          sx={{
                            margin: 4,
                            whiteSpace: 'pre-wrap',
                            paddingTop: 2,
                          }}
                        >
                          {t('workflow.failed')}
                        </Alert>
                      )}

                      <EntitiesPanel
                        dossier={dossier}
                        panelRef={panelRef}
                        entityGlobalAutoSelectRef={entityGlobalAutoSelectRef}
                      ></EntitiesPanel>

                      {company?.featureFlags.handleBackofficeSync &&
                        urlParams.dossierId && (
                          <>
                            <ReviewPanel />
                            <SyncPanel />
                            <Card
                              sx={{ borderRadius: 12, borderWidth: 4, p: 1 }}
                            >
                              <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{ width: '100%' }}
                                gap={1}
                              >
                                <InternalReviewNote />
                              </Stack>
                            </Card>
                          </>
                        )}
                    </Stack>
                    {company?.featureFlags.handleBackofficeSync &&
                      urlParams.dossierId && (
                        <Box sx={{ width: '100%' }}>
                          <MasterNavigation />
                        </Box>
                      )}
                  </Stack>

                  <Stack
                    direction="column"
                    alignContent={'center'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      height: '100%',
                      '@container (min-width: 500px)': {
                        display: 'none',
                      },
                      margin: 1,
                    }}
                  >
                    <Typography>{t('ui.enlargePanelMessage')}</Typography>
                    <div style={{ position: 'relative' }}>
                      <KeyboardBackspaceRounded
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          animation: 'glow2 .8s infinite alternate',
                          '@keyframes glow2': {
                            from: {
                              color: ABRICO_ORANGE_MAIN,
                              fontSize: '45px',
                            },
                            to: {
                              color: `#ffae95`,
                              fontSize: '40px',
                            },
                          },
                        }}
                      />
                    </div>
                  </Stack>
                </>
              )}
            </Box>
          </Panel>
        </PanelGroup>
      </Stack>

      <TrackTiming name={'Station Loaded'}></TrackTiming>
    </>
  );
}
