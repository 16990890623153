import { serverTimestamp } from 'firebase/firestore';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import i18n from '@/i18n.ts';
import { queryClient } from '@/queries';
import { updateDossier } from '@/queries/dossiers.ts';
import { saveFileDataWithMetadata } from '@/queries/storage.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { getFileData } from '@/utils/document.ts';
import { removeApryseWatermark } from '@/utils/pdf.ts';

/**
 * Helper method to save the raw document to the cloud (with all the proper reodering done)
 * And updated the dossier in Firestore
 */
export const saveDocumentToCloud = async ({
  notify,
  handleReadonlyMode,
}: {
  notify?: boolean;
  handleReadonlyMode?: boolean;
}): Promise<number | undefined> => {
  handleReadonlyMode = handleReadonlyMode ?? true;
  const instance = useAppState.getState().instance;
  const dossier = useInteractionsState.getState().dossier;
  const user = useAppState.getState().user;

  if (!instance || !dossier || !user) {
    return;
  }

  let savingNotification;
  if (notify) {
    savingNotification = enqueueSnackbar(
      i18n.t('file.save.notificationSaveInProgress'),
      {
        variant: 'info',
        autoHideDuration: null,
      }
    );
  }
  try {
    if (handleReadonlyMode) {
      useInteractionsState.getState().enableReadOnlyMode();
    }
    console.time('Saving PDF file: ');
    const fileData = await getFileData(instance);

    const newPdfFileVersion = dossier.pdfFileVersion
      ? dossier.pdfFileVersion + 1
      : 1;

    await saveFileDataWithMetadata(
      dossier.gcsInputFolderPath,
      dossier.getGcsFileName(newPdfFileVersion),
      await removeApryseWatermark(fileData),
      user.email!
    );

    await updateDossier(
      dossier.id,
      {
        updatedAt: serverTimestamp(),
        disableAutoReorder: true,
        pdfFileVersion: newPdfFileVersion,
        displayedPageNumberByWorkflowPageNumber:
          dossier.updatedDisplayedPageNumberByWorkflowPageNumber,
        metadataIsFromWorkflow: false,
      },
      useInteractionsState.getState().getDataForFs()
    ).then(() =>
      queryClient.invalidateQueries({
        queryKey: ['dossier', 'main'],
      })
    );
    useAppState.getState().setHasEntitiesRelatedChangesToSave(false);

    if (notify) {
      enqueueSnackbar(i18n.t('file.save.notificationSaveSucceeded'), {
        variant: 'success',
        autoHideDuration: 3000,
      });

      closeSnackbar(savingNotification);
    }

    return newPdfFileVersion;
  } catch (err) {
    console.error('Error in saving updated file:', err);

    enqueueSnackbar(i18n.t('file.save.notificationSaveFailed'), {
      variant: 'error',
    });
  } finally {
    console.timeEnd('Saving PDF file: ');
    if (handleReadonlyMode) {
      useInteractionsState.getState().disableReadOnlyMode();
    }
  }
};
