import { EProjectPhase } from '@/abrico-lib-shared/generated/prisma-zod';

export enum EReviewKind {
  pre_work = 'pre_work',
  fix1stDepotKe = 'fix1stDepotKe',
  fixLastDepotKe = 'fixLastDepotKe',
  fix1stDepotMPR = 'fix1stDepotMPR',
  postWork = 'postWork',
  fixPostWorkKe = 'fixPostWorkKe',
}

export const availableReviewKindsByProjectPhase: Record<
  EProjectPhase,
  EReviewKind[]
> = {
  COMMERCIAL_INIT: [],
  INIT_PROJECT_PRE_WORK_DOSSIER: [],
  REVIEW_AND_FIX_PROJECT_PRE_WORK_DOSSIER: [EReviewKind.pre_work],
  SIGN_BEFORE_WORK_START: [],
  PREWORK_SUBMISSION_AND_PROJECT_IN_PROGRESS_ALL_IN_ONE: [
    EReviewKind.fix1stDepotKe,
    EReviewKind.fix1stDepotMPR,
  ],
  END_OF_PROJECT_DOSSIER_CREATION: [],
  END_OF_PROJECT_DOSSIER_REVIEW: [EReviewKind.postWork],
  SIGN_AFTER_WORK_END: [],
  END_OF_PROJECT_ALL_IN_ONE: [
    EReviewKind.fixLastDepotKe,
    EReviewKind.fixPostWorkKe,
  ],
  PROJECT_COMPLETELY_DONE: [],
};
