import { get } from 'lodash';

import {
  TBoolExpression,
  TCaseExpression,
  TDynValue,
  TExpression,
} from './schemas';

// eslint-disable-next-line
type TDynContext = Record<string, any>;

function evaluateCase(value: TCaseExpression, context: TDynContext): TRes {
  for (let i = 1; i < value.length - 1; i += 1) {
    // @ts-expect-error hard to infer
    if (evaluateValue(value[i][0] as TDynValue, context)) {
      // @ts-expect-error hard to infer
      return evaluateValue(value[i][1] as TDynValue, context);
    }
  }
  return evaluateValue(value[value.length - 1] as TDynValue, context);
}

type TRes = string | number | boolean | null | string[] | number[];

function evaluateValue(value: TDynValue, context: TDynContext): TRes {
  if (Array.isArray(value)) {
    if (value[0] === 'literal') {
      if (value.length !== 2) {
        throw new Error('Invalid literal');
      }
      return value[1];
    }

    return evaluateExpression(value, context);
  } else {
    return value;
  }
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

function checkComparable(left: TRes, right: TRes): void {
  if (left === null || right === null) {
    return;
  }

  if (typeof left !== typeof right) {
    throw new Error('Cannot compare different types');
  }

  if (!['string', 'number', 'boolean'].includes(typeof left)) {
    throw new Error(
      `Cannot compare non-string, non-number types, encountered: ${left}`
    );
  }
}

export function evaluateBooleanExpression(
  expr: TBoolExpression,
  context: TDynContext
): boolean {
  const [operator, ...args] = expr;
  if (operator === '==') {
    const [left, right] = args;
    const leftValue = evaluateValue(left as TDynValue, context);
    const rightValue = evaluateValue(right as TDynValue, context);
    checkComparable(leftValue, rightValue);
    return leftValue === rightValue;
  } else if (operator === '!=') {
    const [left, right] = args;
    const leftValue = evaluateValue(left as TDynValue, context);
    const rightValue = evaluateValue(right as TDynValue, context);
    checkComparable(leftValue, rightValue);
    return leftValue !== rightValue;
  } else if (operator === '<') {
    const [left, right] = args;
    const leftValue = evaluateValue(left as TDynValue, context);
    const rightValue = evaluateValue(right as TDynValue, context);
    checkComparable(leftValue, rightValue);
    return (leftValue ?? +Infinity) < (rightValue ?? -Infinity);
  } else if (operator === '>') {
    const [left, right] = args;
    const leftValue = evaluateValue(left as TDynValue, context);
    const rightValue = evaluateValue(right as TDynValue, context);
    checkComparable(leftValue, rightValue);
    return (leftValue ?? -Infinity) > (rightValue ?? +Infinity);
  } else if (operator === '<=') {
    const [left, right] = args;
    const leftValue = evaluateValue(left as TDynValue, context);
    const rightValue = evaluateValue(right as TDynValue, context);
    checkComparable(leftValue, rightValue);
    return (leftValue ?? +Infinity) <= (rightValue ?? -Infinity);
  } else if (operator === '>=') {
    const [left, right] = args;
    const leftValue = evaluateValue(left as TDynValue, context);
    const rightValue = evaluateValue(right as TDynValue, context);
    checkComparable(leftValue, rightValue);
    return (leftValue ?? -Infinity) >= (rightValue ?? +Infinity);
  } else if (operator === 'or') {
    return args.some(
      (arg) => evaluateValue(arg as TDynValue, context) === true
    );
  } else if (operator === 'and') {
    return args.every(
      (arg) => evaluateValue(arg as TDynValue, context) === true
    );
  } else if (operator === '!') {
    return !evaluateValue(args[0] as TDynValue, context);
  } else if (operator === 'in') {
    const [left, right] = args;
    const leftValue = evaluateValue(left as TDynValue, context);
    const rightValue = evaluateValue(right as TDynValue, context);
    if (!Array.isArray(rightValue)) {
      throw new Error('Expected an array');
    }
    // @ts-ignore
    return rightValue.includes(leftValue);
  } else if (operator === 'regexValid') {
    const value = evaluateValue(args[0] as TDynValue, context) as string;
    const regex = new RegExp(
      evaluateValue(args[1] as TDynValue, context) as string
    );
    return regex.test(value);
  } else if (operator === 'nullOrEmpty') {
    const value = evaluateValue(args[0] as TDynValue, context);
    return value === '' || value === null || value === undefined;
  } else if (operator === 'notNullOrEmpty') {
    const value = evaluateValue(args[0] as TDynValue, context);
    return value !== '' && value !== null && value !== undefined;
  } else {
    return assertUnreachable(operator);
  }
}

export function evaluateExpression(
  expr: TExpression,
  context: TDynContext
): TRes {
  const [operator, ...args] = expr;
  if (operator === 'get') {
    const value = get(context, args[0] as string);
    if (typeof value === 'undefined') {
      throw new Error(`Variable ${args[0]} not found, cannot be undefined`);
    }
    if (value instanceof Date) {
      return value.getTime();
    }
    return value;
  }
  if (operator === 'case') {
    return evaluateCase(expr as TCaseExpression, context);
  } else {
    return evaluateBooleanExpression(expr as TBoolExpression, context);
  }
}
