import { TSharedAddress } from '@/abrico-lib-shared/data-exchange/schemas';

export function addressesAreEqual(
  address1: Omit<TSharedAddress, '_table'>,
  address2: Omit<TSharedAddress, '_table'>
): boolean {
  return (
    address1.housenumber === address2.housenumber &&
    address1.street === address2.street &&
    address1.addressAddition === address2.addressAddition &&
    address1.postcode === address2.postcode &&
    address1.city === address2.city
  );
}

export function addressIsNotEmpty(
  address: Omit<TSharedAddress, '_table'>
): boolean {
  // FIXME might want to improve this
  return !!(address.postcode && address.city);
}
