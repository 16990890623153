import {
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { db } from '@/config/firebaseInit';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { IDossierLock } from '@/types';

const viewId = uuidv4();

export const takeRWLockOwnership = async (
  dossierId: string,
  user: { email: string | null; uid: string }
) => {
  const docRef = doc(db, 'dossierRWLocks', dossierId);

  await setDoc(
    docRef,
    {
      dossierId,
      owner: { email: user.email, uid: user.uid },
      createdAt: serverTimestamp(),
      viewId,
    } as IDossierLock,
    { merge: true }
  );
};

let newLockUnsubscribe: (() => void) | null = null;

export const forceReleaseRWLockOwnershipAndReload = async (
  dossierId: string
) => {
  const docRef = doc(db, 'dossierRWLocks', dossierId);
  const docSnap = await getDoc(docRef);
  const kickHistory = docSnap.data()?.kickHistory || [];
  const previousOwner = docSnap.data()?.owner || null;
  const user = useAppState.getState().user;

  // We make sure to stop listening to the lock
  if (newLockUnsubscribe) {
    newLockUnsubscribe();
  }

  await updateDoc(docRef, {
    owner: deleteField(),
    createdAt: deleteField(),
    // we do not delete the viewId, to be be able to differentiate between a full close and a kick out
    // viewId: deleteField(),
    kickHistory: [
      {
        email: user?.email || 'unknown for some reason',
        uid: user?.uid || 'unknown for some reason',
        // Cannot use serverTimestamp() here as it would be the same for all entries
        rwOwnershipStartedAt: new Date(),
        previousOwner,
      },
      ...kickHistory,
    ],
  });

  window.location.reload();
};

export const tryAcquireRWLockAndMonitor = async (
  dossierId: string,
  user: { email: string | null; uid: string }
) => {
  const docRef = doc(db, 'dossierRWLocks', dossierId);
  const docSnap = await getDoc(docRef);

  let openedInRw = false;
  // If no tracking doc exist or tracking is empty, we acquire the lock
  if (!docSnap.exists() || !docSnap.data()?.owner) {
    await takeRWLockOwnership(dossierId, user);
    openedInRw = true;
    useInteractionsState.getState().disableReadOnlyMode();
  } else {
    // Otherwise we open in read-only mode
    useInteractionsState.getState().enableReadOnlyMode(true);
    useInteractionsState.getState().setLockInfo(docSnap.data() as IDossierLock);
  }

  useInteractionsState.getState().setOpenedInRWMode(openedInRw);
  newLockUnsubscribe = onSnapshot(docRef, async (doc) => {
    if (dossierId !== useAppState.getState().urlParams.dossierId) {
      if (newLockUnsubscribe) newLockUnsubscribe();
      return;
    }

    // In all cases we update the lock data for later display
    useInteractionsState.getState().setLockInfo(doc.data() as IDossierLock);

    const data = doc.data()!;
    if (
      !data.owner &&
      // If the session had been fully closed viewId will be unset, so we retake the lock
      // if someone else has taken the lock, then viewId will stay set
      !data.viewId &&
      !useInteractionsState.getState().isPermanentReadOnlyMode
    ) {
      console.log('Ownership retaken');
      // when before unload event, we might want to retake the lock / session
      await takeRWLockOwnership(dossierId, user);
    } else {
      const fsViewId = doc.data()!.viewId;
      // If the saved viewId doesn't correspond we make sure to switch to read-only mode
      // This can happen if two 2 tabs load at almost the same time
      if (fsViewId !== viewId) {
        // We auto show the modal only if the we are not in ro mode already
        if (!useInteractionsState.getState().isPermanentReadOnlyMode) {
          useInteractionsState.getState().setRoModalOpen(true);
          useInteractionsState.getState().enableReadOnlyMode(true);
        }
      }
    }
  });
};
