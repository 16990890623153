import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { queryClient } from '@/queries';
import { getDossierById, onDossiersUpdated } from '@/queries/dossiers.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useInteractionsState } from '@/stores/interactionsStore.ts';
import { WorkflowStatus } from '@/types';

interface UiStateValues {
  isWorkflowLoading: boolean;
  autoFocus: boolean;
  viewerIsReady: boolean;
  documentFullyLoaded: boolean;
  focusedEntityId: string;
  metaFieldBeingEdited: string | null;
  forcedShownSections: Set<string>;
}

interface UiState extends UiStateValues {
  setIsWorkflowLoading: (isLoading: boolean) => void;
  setFocusedEntityId: (id: string, autoFocus?: boolean) => void;
  setViewerIsReady: (isReady: boolean) => void;
  setDocumentIsFullyLoaded: () => void;
  setMetaFieldBeingEdited: (field: string | null) => void;
  toggleForcedShownSection: (section: string) => void;
}

const getDefaultStateValues = (): UiStateValues => ({
  isWorkflowLoading: false as boolean,
  viewerIsReady: false as boolean,
  documentFullyLoaded: false as boolean,
  autoFocus: false as boolean,
  focusedEntityId: '',
  metaFieldBeingEdited: '' as string | null,
  forcedShownSections: new Set<string>(),
});

export const useUiState = create<UiState>()(
  devtools(
    (set) => ({
      ...getDefaultStateValues(),
      setIsWorkflowLoading: (isLoading: boolean) => {
        set(() => ({ isWorkflowLoading: isLoading }));
      },
      setViewerIsReady: (isReady: boolean) => {
        set(() => ({ viewerIsReady: isReady }));
      },
      setDocumentIsFullyLoaded: () => {
        set(() => ({ documentFullyLoaded: true }));

        useAppState.getState().logDurationInFs({
          durationMs:
            performance.now() - useAppState.getState().refDossierStartMs,
          name: 'Viewer Ready',
        });
      },
      setFocusedEntityId: (id: string, autoFocus: boolean = true) => {
        set(() => ({ focusedEntityId: id, autoFocus }));
      },
      setMetaFieldBeingEdited: (field: string | null) => {
        set(() => ({ metaFieldBeingEdited: field }));
      },

      toggleForcedShownSection: (section: string) => {
        set((state) => {
          const forcedShownSections = new Set(state.forcedShownSections);
          if (forcedShownSections.has(section)) {
            forcedShownSections.delete(section);
          } else {
            forcedShownSections.add(section);
          }
          return { forcedShownSections };
        });
      },
    }),
    {
      name: 'ui-store',
    }
  )
);

let unsubscribeDossierWatcher: (() => void) | null = null;
useAppState.subscribe(async (state, prevState) => {
  if (state.urlParams?.dossierId !== prevState.urlParams?.dossierId) {
    useUiState.setState(getDefaultStateValues());

    const dossierId = state.urlParams?.dossierId;
    if (dossierId) {
      unsubscribeDossierWatcher?.();
      const dossier = await getDossierById(dossierId);
      if (!dossier) {
        return;
      }
      let lastWorkflowStatus = dossier.workflowStatus;
      unsubscribeDossierWatcher = onDossiersUpdated(
        [dossierId],
        async (snaps) => {
          for (const snap of snaps) {
            if (snap.workflowStatus === WorkflowStatus.STARTED) {
              useUiState.getState().setIsWorkflowLoading(true);
              useInteractionsState.getState().enableReadOnlyMode();
            } else {
              if (
                snap.workflowStatus !== lastWorkflowStatus &&
                useUiState.getState().isWorkflowLoading
              ) {
                await queryClient.invalidateQueries({
                  queryKey: ['dossier', 'main'],
                });
                useUiState.getState().setIsWorkflowLoading(false);
                useInteractionsState.getState().disableReadOnlyMode();
              }
            }
            lastWorkflowStatus = snap.workflowStatus;
          }
        }
      );
    }
  }
});
