import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { MutableRefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DocSectionEntities from '@/components/Entity/DocSectionEntities.tsx';
import { EStationDocType } from '@/schemas/types.ts';
import { useAppState } from '@/stores/appStore.ts';
import { IPreparedDocSection } from '@/types';

type Props = {
  preparedSection: IPreparedDocSection[];
  docType: EStationDocType;
  docKey: string;
  scrollOffset: number;
  docExtraLabel: string;
  entityGlobalAutoSelectRef: MutableRefObject<() => void>;
};

const DocEntities = ({
  preparedSection,
  docType,
  docKey,
  scrollOffset,
  docExtraLabel,
  entityGlobalAutoSelectRef,
}: Props) => {
  const { t } = useTranslation('common');
  const company = useAppState((state) => state.company);
  const sections = useMemo(() => {
    return preparedSection.filter(
      ({ groups }) =>
        company?.featureFlags.handleMissingFields ||
        groups.filter((group) => group.entities.length > 0).length > 0
    );
  }, [preparedSection, company?.featureFlags.handleMissingFields]);

  return (
    <Card sx={{ mb: 1 }}>
      {!company?.featureFlags.handleBackofficeSync && (
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
          sx={{
            position: 'sticky',
            top: 90,
            alignSelf: 'flex-start',
            width: '100%',
            zIndex: 150000,
            backgroundColor: '#0b0d0e',
            pb: 1,
          }}
        >
          <Typography
            level="title-md"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {`${t(`docTypes.${docType}.name`)} ${docExtraLabel}`}
          </Typography>
        </Stack>
      )}

      <Stack>
        {sections.length === 0 ? (
          <Typography level={'body-sm'}>{t('noEntitiesExtracted')}</Typography>
        ) : (
          sections.map(({ sectionName, groups, missingEntities }) => (
            <DocSectionEntities
              key={sectionName}
              {...{
                sectionName,
                groups,
                missingEntities,
                docKey,
                scrollOffset,
                entityGlobalAutoSelectRef,
              }}
            />
          ))
        )}
      </Stack>
    </Card>
  );
};

export default DocEntities;
