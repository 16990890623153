import { extendTheme } from '@mui/joy/styles';

import { ABRICO_ORANGE_MAIN } from '@/utils/constants';
export const theme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          50: '#fff1ed',
          100: '#FFDCCE',
          200: '#ffc9b8',
          300: '#ffae95',
          400: '#ff9372',
          500: ABRICO_ORANGE_MAIN,
          600: '#cc603f',
          700: '#99482f',
          800: '#66301f',
          900: '#33180f',
        },
        background: {
          body: '#0A0E0F',
        },
        common: {
          white: '#FFF',
          black: '#0A0E0F',
        },
      },
    },
  },
});
