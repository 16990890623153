import { FieldValue } from '@firebase/firestore';
import { z } from 'zod';

import { TZodFieldInfo } from '@/abrico-lib-shared/data-exchange/helpers.ts';
import {
  TAnalysisResponse,
  TEntityForDisplay,
  TPostProcessedEntity,
} from '@/schemas/analysis';
import { EStationDocType } from '@/schemas/types';

export enum WorkflowStatus {
  COMPLETED = 'completed',
  STARTED = 'started',
  FAILED = 'failed',
}

export type Order = 'asc' | 'desc';

export type TranslationT = (
  a: string | string[],
  b?: Record<string, unknown>
) => string;

export interface IMissingEntity {
  id: string;
  comparisonId: string;
  type: string;
  docType: EStationDocType;
  docKey: string;
  label: string;
  kind: 'missing';
}

export type TFakeMissingEntityGroup = {
  // For a smooth data handling, we put missing fields as a separate 'fake group'
  fakeGroup: true;
  entities: [];
  missingEntities: IMissingEntity[];
};

export type TFakeEntityGroup = {
  // For a smooth data handling, we put simple entities as a separate 'fake group'
  fakeGroup: true;
  entities: [TEntityForDisplay | TPostProcessedEntity];
  missingEntities: [];
};

export type TEntityGroup = {
  // This is a real group of entities
  fakeGroup: false;
  label: string;
  entities: (TEntityForDisplay | TPostProcessedEntity)[];
  missingEntities: IMissingEntity[];
};

export interface IPreparedDocSection {
  sectionName: string;
  missingEntities: IMissingEntity[];
  groups: (TFakeEntityGroup | TEntityGroup)[];
}

export interface IPreparedDocEntities {
  docKey: string;
  docType: EStationDocType;
  preparedSection: IPreparedDocSection[];
}

export type IPreparedEntities = {
  docType: EStationDocType;
  entitiesForDoc: IPreparedDocEntities[];
}[];

export interface IDossier extends DossierDataAtCreation {
  id: string;
  docTypesByFileByPage?: EStationDocTypesByFileByPage;
  size?: number;
  updatedAt?: FieldValue;
  workflowStatus?: WorkflowStatus;
  workflowExecutions: number;
  disableAutoReorder?: boolean;
  displayedPageNumberByWorkflowPageNumber?: Record<string, number>;
  pdfFileVersion?: number;
  metadataIsFromWorkflow?: boolean;
  archived?: boolean | null;
  archivedAt?: FieldValue | null;
  archivedBy?: string | null;
  codesAdeme: string[];
  glideMetaData?: {
    extraData?: {
      chantierId: string;
    };
  };
}

export interface IDossierWithAnalysis extends IDossier {
  analysis: TAnalysisResponse;
}

export enum DossierStatus {
  NEW = 'new',
  IN_PROGRESS = 'inProgress',
  VALID = 'valid',
  REJECTED = 'rejected',
  INVALID = 'invalid',
  INCOMPLETE = 'incomplete',
}

export interface DossierDataAtCreation {
  name: string;
  companyId: string;
  createdBy: string;
  createdAt: FieldValue;
  isUniqueDevis: boolean;
  tagId: string;
  status: DossierStatus;
  codesAdeme: string[];
}

export interface IDossierLock {
  dossierId: string;
  kickHistory?: {
    email: string;
    uid: string;
    rwOwnershipStartedAt: FieldValue;
  }[];
  owner?: { email: string; uid: string };
  viewId?: string;
  createdAt?: FieldValue;
}

export interface IFileInfo {
  name: string;
  url: string;
  storageUri: string;
  size: number;
  timeCreated: string;
  updated?: string;
  uploadedBy: string;
}

export type EStationDocTypesByFileByPage = {
  [K in EStationDocType]?: {
    [fileName: string]: number[];
  };
};

export const CompanyZod = z.object({
  id: z.string(),
  name: z.string(),
  members: z.array(z.string()).default([]),
  admin: z.array(z.string()).default([]),
  featureFlags: z
    .object({
      handleMissingFields: z.boolean().default(false),
      handleFieldRules: z.boolean().default(false),
      handleBackofficeSync: z.boolean().default(false),
    })
    .default({}),
  logo: z
    .object({
      url: z.string(),
      storageUri: z.string().optional(),
    })
    .optional(),
});

export type ICompany = z.infer<typeof CompanyZod>;

export enum TagRole {
  DEFAULT = 'default',
}

export interface ITag {
  id: string;
  name: string;
  companyId: string;
  role?: TagRole;
  hideForUser?: string[];
}

export type ISeverity = 'success' | 'error' | 'info' | 'warning';

export type IError = {
  severity: ISeverity;
  code: string;
};

export type IVerificationSignatureResult = {
  signed: boolean;
  signer: string;
  signerName: string;
  signTime: any;
  verificationStatus: number;
  documentStatus: number;
  digestStatus: number;
  trustStatus: number;
  permissionStatus: number;
  disallowedChanges: { objnum: number; type: string }[];
  trustVerificationResultBoolean: boolean;
  trustVerificationResultString: string;
  timeOfTrustVerificationEnum: number;
  trustVerificationTime: any;
  id: number;
  badgeIcon: string;
  validSignerIdentity: boolean;
  digestAlgorithm: string;
  documentPermission: number;
  isCertification: boolean;
  contactInfo: string;
  location: string;
  reason: string;
  issuerField: string;
  subjectField: string;
  validAtTimeOfSigning: boolean;
  formatedSignTime: Date;
};

export enum AnnotStatus {
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export type TEntityVersionInfo =
  | {
      kind: 'extracted' | 'postProcessed';
      transformedText: string;
      normalizedTransformedText: string;
      status: AnnotStatus;
      rejectedReason?: string;
    }
  | {
      kind: 'missing';
      status: AnnotStatus;
      rejectedReason?: string;
    };

export type TInteractionsFS = {
  dossierId?: string;
  entitiesStatuses: Record<string, AnnotStatus>;
  entitiesRejectionReasons?: Record<string, string>;
  prevVersion?: {
    dossierId: string;
    // string is the comparisonId here
    data: Record<string, TEntityVersionInfo>;
  };
};

export type TSyncValue = string | number | boolean | null;

export type TWipMetaEl = {
  metaEntryId: string;
  hasBeenEdited: boolean;
  typeInfo: TZodFieldInfo;
  value: TSyncValue;
  label: string;
  remoteValue: TSyncValue;
  errors: string[];
  isReadOnlyStation: boolean;
};

export type TMetaDossierWipElByMetaEntryId = Map<string, TWipMetaEl>;

export type TReviewInfo = {
  stats: { [k in AnnotStatus]: number };
  rejectedEntities: {
    id: string;
    label: string;
    status: AnnotStatus;
    rejectionReason: string;
    kind: 'postProcessed' | 'missing' | 'extracted';
  }[];
};

export const ZGenerateReviewFeedbackBody = z.object({
  dossierId: z.string(),
  rejections: z.array(
    z.object({
      label: z.string(),
      rejectionReason: z.string(),
      isMissing: z.boolean().default(false),
    })
  ),
});

export type TAttestation = {
  id: number;
  siret: string;
  domaine: string;
  url_qualification: string;
  content_type: string | null;
  gcs_uri: string | null;
  gcs_bucket: string;
};

export type TAttestationResponse = {
  found: boolean;
  company_name: string | null;
  matched: TAttestation[];
  others: TAttestation[];
};
