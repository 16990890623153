import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import FullScreenLoader from '@/components/UI/FullScreenLoader.tsx';
import { getChantierInfo } from '@/queries/chantier.ts';
import { useAppState } from '@/stores/appStore.ts';
import { useChantierState } from '@/stores/chantierStore.ts';

export default function HomeChantier() {
  const { t } = useTranslation();
  const urlParams = useAppState((state) => state.urlParams);
  useQuery(getChantierInfo());

  const metaDossierForChantier = useChantierState(
    (state) => state.metaDossierForChantier
  );
  const metaDossierForChantierIsLoading = useChantierState(
    (state) => state.metaDossierForChantierIsLoading
  );

  const { phaseDocs, otherPhaseDocs } = useChantierState(
    (state) => state.computedChantierDocs
  );

  const remoteCurrentPhaseMissingDocTypes = useChantierState(
    (state) => state.remoteCurrentPhaseMissingDocTypes
  );

  if (metaDossierForChantierIsLoading) {
    return <FullScreenLoader />;
  }

  if (!metaDossierForChantier) {
    return <>Erreur</>;
  }

  return (
    <Stack
      width={'100%'}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={1}
    >
      <Stack direction={'row'} gap={2} alignItems={'start'}>
        <Typography level={'h1'}>{metaDossierForChantier.name}</Typography>
        <Typography>
          {metaDossierForChantier.isDemo ? '(Demo)' : '(Abrico)'}
        </Typography>
      </Stack>
      <Stack direction={'row'} gap={2}>
        <Typography level={'h2'}>
          Phase :{' '}
          {t(`shared:enums.EProjectPhase.${metaDossierForChantier.phase}`)}
        </Typography>
      </Stack>
      <Stack direction={'row'} gap={2} sx={{ mt: 2 }}>
        <Card variant={'soft'}>
          <Typography level={'title-lg'}>Document hors-phase</Typography>

          {otherPhaseDocs.length === 0 && (
            <Typography>Il n&apos;y a pas d&apos;autres documents</Typography>
          )}
          {otherPhaseDocs.map((doc) => (
            <Link
              key={doc.id}
              underline={'none'}
              onClick={(e) => {
                e.preventDefault();
                useAppState
                  .getState()
                  .navigate(
                    `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}/doc/${doc.dossierId}`
                  );
              }}
            >
              <Typography>
                {doc.label} (
                {doc.isValid === true
                  ? '✔'
                  : doc.isValid === false
                    ? '🗙'
                    : 'non revu'}
                )
              </Typography>
            </Link>
          ))}
        </Card>
        <Card variant={'soft'}>
          <Typography level={'title-lg'}>Documents à reviewer</Typography>

          {phaseDocs.length === 0 && (
            <Typography level={'body-xs'}>
              Il n&apos;y a pas de document à reviewer
            </Typography>
          )}
          {phaseDocs.map((doc) => (
            <Typography key={doc.id}>{doc.label}</Typography>
          ))}
          {remoteCurrentPhaseMissingDocTypes &&
            remoteCurrentPhaseMissingDocTypes.length > 0 &&
            remoteCurrentPhaseMissingDocTypes && (
              <>
                <Typography level={'title-lg'}>Manquant :</Typography>
                {remoteCurrentPhaseMissingDocTypes?.map((docType, idx) => (
                  <Typography key={idx}>
                    {t(`shared:enums.EDocType.${docType}`)}
                  </Typography>
                ))}
              </>
            )}

          <Button
            sx={{ mt: 3 }}
            size={'lg'}
            disabled={phaseDocs.length === 0}
            onClick={() => {
              useAppState
                .getState()
                .navigate(
                  `/company/${urlParams.companyId}/chantier/${urlParams.chantierId}/doc/${phaseDocs[0].dossierId}`
                );
            }}
          >
            C&apos;est parti pour la review
          </Button>
        </Card>
      </Stack>
      {/*<AddRgeBtn
        defaultSirets={metaDossierForChantier?.artisansSirets ?? []}
        defaultCodesAdeme={metaDossierForChantier?.codesAdeme ?? []}
        variant={'search'}
      />*/}
    </Stack>
  );
}
