import { Tooltip } from '@mui/joy';
import Chip from '@mui/joy/Chip';
import { useTranslation } from 'react-i18next';

import { useChantierState } from '@/stores/chantierStore.ts';

export default function DossierStatusChip() {
  const { t } = useTranslation();
  const remoteCurrentPhaseMissingDocTypes = useChantierState(
    (state) => state.remoteCurrentPhaseMissingDocTypes
  );

  if (
    remoteCurrentPhaseMissingDocTypes &&
    remoteCurrentPhaseMissingDocTypes.length > 0
  ) {
    return (
      <Tooltip
        title={`Docs manquants : ${remoteCurrentPhaseMissingDocTypes.map((el) => t(`shared:enums.EDocType.${el}`)).join(', ')}`}
      >
        <Chip color="danger" variant="solid">
          Incomplet
        </Chip>
      </Tooltip>
    );
  }
  return <></>;
}
