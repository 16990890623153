import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';

import { db } from '@/config/firebaseInit';
import { ITag, TagRole } from '@/types';

export const getCompanyTags = async (companyId: string) => {
  const dossiersRef = collection(db, 'tags');

  try {
    // Filter the query based on companyId
    const q = query(dossiersRef, where('companyId', '==', companyId));
    const querySnapshot = await getDocs(q);

    // Map the documents to conform to the IDossier interface
    return querySnapshot.docs.map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        }) as ITag
    );
  } catch (error) {
    console.error('Error fetching tags list:', error);
    return [];
  }
};

export const createNewTag = async (
  companyId: string,
  name: string
): Promise<ITag> => {
  const newTagRef = await addDoc(collection(db, 'tags'), {
    companyId,
    name,
  });
  return {
    id: newTagRef.id,
    companyId,
    name,
  };
};

export const getCompanyDefaultTag = async (companyId: string) => {
  const dossiersRef = collection(db, 'tags');
  try {
    // Filter the query based on companyId
    const q = query(
      dossiersRef,
      where('companyId', '==', companyId),
      where('role', '==', TagRole.DEFAULT)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return [];
    }

    // Map the documents to conform to the IDossier interface
    return querySnapshot.docs.map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        }) as ITag
    );
  } catch (error) {
    console.error('Error fetching tags list:', error);
    return [];
  }
};
